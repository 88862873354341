import { buildCandidate } from '@shared/builders/candidate.helper';
import { phoneFormat } from '@shared/builders/phone-formart.helper';
import { candidatesStatus } from '@shared/helpers/candidate.helper';
import {
  APIDate,
  APIEmail,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IApplication } from '@shared/interfaces/application.interface';
import { IBackgroundChecking } from '@shared/interfaces/background-checking.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { IEmailActivity } from '@shared/interfaces/email-activity.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IJob } from '@shared/interfaces/job.interface';
import { IMetadatum } from '@shared/interfaces/metadatum.interface';
import { IPreScreening } from '@shared/interfaces/pre-screening.interface';
import { IProfile } from '@shared/interfaces/profile.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReferenceContact } from '@shared/interfaces/reference-contact.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ITextActivity } from '@shared/interfaces/text-activity.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { IEmailValidity } from '@shared/interfaces/validities/email-validity.interface';
import { IPhoneNumberlValidity } from '@shared/interfaces/validities/phone-number-validity.interface';
import { IReferenceRequestsStatus } from '@shared/interfaces/validities/reference-requests-status.interface';
import { IReferencesStatus } from '@shared/interfaces/validities/references-status.interface';
import { IVerificationRequest } from '@shared/interfaces/verification-request.interface';
import { Recruiter } from '@shared/models/recruiter.model';
import { PhoneNumber } from 'libphonenumber-js';

export class Candidate implements ICandidate {
  public static readonly TYPE: string = String('Candidate');

  // Relationships
  email_activities?: (IEmailActivity | IAPIRelationship)[];
  text_activities?: (ITextActivity | IAPIRelationship)[];
  feedbacks?: (IFeedback | IAPIRelationship)[];
  pre_screenings?: (IPreScreening | IAPIRelationship)[];
  background_checkings?: (IBackgroundChecking | IAPIRelationship)[];

  documents?: (IDocument | IAPIRelationship)[];
  references?: (IReference | IAPIRelationship)[];
  requests?: (IReferenceRequest | IAPIRelationship)[]; // TO DO: array of request objects
  verificationRequests?: (IVerificationRequest | IAPIRelationship)[]; // TO DO: array of request objects
  employers?: (IEmployer | IAPIRelationship)[];
  profile?: IProfile;
  added_by: IRecruiter | IAPIRelationship | any;
  metadatum: IMetadatum;
  reference_contacts: (IReferenceContact | IAPIRelationship)[];
  applications: (IApplication | IAPIRelationship)[];
  jobs?: (IJob | IAPIRelationship)[];

  // Attributes
  id: APIuuid;
  email: APIEmail;
  phone: PhoneNumber;
  country_code: string;
  score: number; // Set in server
  notes: string;
  internal_id: string;
  job_title: string;
  secondary_id: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  granted_permission: string;
  status: number;
  added_by_id?: string;
  is_active: boolean;

  is_reminders_enabled: boolean;
  has_same_ip: boolean;

  references_status: IReferencesStatus = {
    not_completed: 0,
    completed: 0,
    total: 0
  };

  reference_requests_status: IReferenceRequestsStatus = {
    not_completed: 0,
    completed: 0,
    total: 0
  };

  signature: string;
  signature_url: string;

  candidate_email_validity: IEmailValidity;
  candidate_phone_number_validity: IPhoneNumberlValidity;

  placement_date: APIDate;
  created_at: APIDate;
  updated_at: APIDate;

  constructor(partial: Partial<ICandidate>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Candidate.TYPE,
        id: this.id,
        attributes: {
          internal_id: this.internal_id,
          secondary_id: this.secondary_id,
          email: this.email,
          country_code: this.country_code,
          phone: this.phone,
          job_title: this.job_title,
          first_name: this.first_name,
          last_name: this.last_name,
          preferred_name: this.preferred_name,
          notes: this.notes,
          granted_permission: this.granted_permission,
          status: this.status,
          added_by_id: this.added_by_id,
          placement_date: this.placement_date,
          is_reminders_enabled: this.is_reminders_enabled
        }
      }
    } as IAPIData;
  }

  public get apiRelationship(): IAPIRelationship {
    return {
      type: Candidate.TYPE,
      id: this.id
    };
  }

  public get hasEmailWarning(): boolean {
    return (
      this.candidate_email_validity?.is_valid &&
      this.candidate_email_validity?.is_invalid
    );
  }

  public get hasPhoneNumberWarning(): boolean {
    return (
      this.candidate_phone_number_validity?.is_valid &&
      this.candidate_phone_number_validity?.is_invalid
    );
  }

  public get hasSameIPAddress(): boolean {
    return this.has_same_ip;
  }

  public get status_values(): any {
    return candidatesStatus.find((s: any) => s.id === this.status);
  }

  public get email_validity(): {
    label: string;
    class: string;
    tooltip: string;
  } {
    let className = 'lnr-envelope';
    let tooltip;

    if (!this.email) {
      return {
        label: '-',
        class: null,
        tooltip
      };
    }

    if (
      this.candidate_email_validity?.is_valid &&
      this.candidate_email_validity?.is_invalid
    ) {
      className += ' v-text-warning';
      tooltip = 'Check email validity';
    } else if (
      this.candidate_email_validity?.is_valid &&
      !this.candidate_email_validity?.is_invalid
    ) {
      className = 'lnr-envelope-open v-text-success';
      tooltip = 'Delivered';
    } else if (
      !this.candidate_email_validity?.is_valid &&
      this.candidate_email_validity?.is_invalid
    ) {
      className = 'lnr-envelope v-text-danger';
      tooltip = 'Failed';
    }

    return {
      label: this.email,
      class: className,
      tooltip
    };
  }

  public get phone_number_validity(): any {
    let className = 'lnr-smartphone text-muted';
    let tooltip;

    if (!this.phone_number) {
      return {
        label: '-',
        class: null
      };
    }

    if (this.candidate_phone_number_validity?.is_invalid) {
      className = className.replace('text-muted', 'v-text-warning');
      tooltip = 'Check phone number validity';
    }

    return {
      label: this.phone_number,
      class: className,
      tooltip
    };
  }

  public get candidates_status_values(): any {
    return candidatesStatus.find((s: any) => {
      let status = this.status;

      if (status === 3) {
        status = 5;
      }

      return s.id === status;
    });
  }

  public get reference_requests(): any {
    return (this.requests as any)?.filter(
      (rr: IReferenceRequest) => rr.is_active && !rr.filled
    );
  }

  public get verification_requests(): any {
    return (this.verificationRequests as any)?.filter(
      (vr: IVerificationRequest) => vr.is_active && !vr.filled
    );
  }

  public get referencesOnCompleted(): string {
    return `${this.references_status.completed} / ${this.references_status.total}`;
  }

  public get ip_address(): string {
    return this.metadatum && this.metadatum.ip_address;
  }

  public get full_name(): string {
    return this.first_name || this.last_name
      ? `${this.first_name} ${this.last_name}`
      : '';
  }

  public get name_label(): string {
    return this.full_name || this.email;
  }

  public get id_label(): string {
    return this.internal_id;
  }

  public get user(): IUser | undefined {
    return (this.profile as IProfile).user; // TO DO this doesn't seem quite right.
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  public get reference_status() {
    const isPendingCandidateRequest =
      this.reference_requests_status.not_completed > 0;
    const isPendingReferences = this.references_status.not_completed > 0;
    const pendingIcon = 'lnr-hourglass v-text-warning';
    const pluralSuffix = this.references_status.not_completed > 1 ? 's' : '';

    if (
      (this.reference_requests_status.total === 0 &&
        this.references_status.total === 0) ||
      (this.reference_requests_status.total === 0 &&
        this.references_status.total === 0 &&
        this.references_status.completed === 0)
    ) {
      return {
        label: 'No reference requests sent',
        class: 'lnr-warning v-text-danger'
      };
    } else if (isPendingCandidateRequest && isPendingReferences) {
      return {
        label: `Candidate + referee request${pluralSuffix} pending`,
        class: pendingIcon
      };
    } else if (isPendingCandidateRequest) {
      return {
        label: 'Candidate request pending',
        class: pendingIcon
      };
    } else if (isPendingReferences) {
      return {
        label: `Referee request${pluralSuffix} pending`,
        class: pendingIcon
      };
    } else {
      return {
        label: 'Completed',
        class: 'lnr-shield-check v-text-success'
      };
    }
  }

  public static fromAPI(res: IAPIData): ICandidate {
    const data = res.data;
    if (!(data && data.type === Candidate.TYPE)) {
      throw new Error(`There was a problem parsing ${Candidate.TYPE} API data`);
    }

    const candidate = new Candidate({
      id: data.id,
      ...data.attributes
    });

    return buildCandidate(candidate, data.relationships, res.included);
  }

  public static fromAPIArray(res: IAPIArrayData): ICandidate[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      const candidate = new Candidate({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;
      candidate.references =
        (relationships?.references as IAPIRelationship[]) || [];
      candidate.requests =
        (relationships?.reference_requests as IAPIRelationship[]) || [];

      const recruiter =
        included &&
        included.find(
          (i: IAPIDataObject) =>
            i.id === (relationships?.added_by as IAPIRelationship).id
        );

      if (recruiter) {
        candidate.added_by = Recruiter.fromAPI({
          data: recruiter,
          included
        });
      }

      return candidate;
    });
  }
}
