export const backgroundCheckingStatus: {
  label: string;
  btnClass: string;
  class: string;
}[] = [
  {
    label: 'Process initiated',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-hourglass'
  },
  {
    label: 'In progress',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-hourglass v-text-warning'
  },
  {
    label: 'Completed',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    label: 'Completed (Clear)',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    label: 'Completed (Not Clear)',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-cross v-text-danger'
  }
];

export enum Providers {
  Mintz = 'mintz',
  ValidityScreening = 'validity_screening'
}

export const backgroundCheckCategories = {
  [Providers.Mintz]: [
    {
      id: 4022,
      label: 'National criminal (CPIC)'
    }
    // {
    //   id: 4004,
    //   label: 'Employment Verification'
    // },
    // {
    //   id: 4007,
    //   label: 'Employment Verification - 3 years'
    // },
    // {
    //   id: 4008,
    //   label: 'Employment Verification - 5 years'
    // },
    // {
    //   id: 4009,
    //   label: 'Employment Verification - 7 years'
    // },
    // {
    //   id: 4024,
    //   label: 'Education verification'
    // },
    // {
    //   id: 4025,
    //   label: 'Professional verification'
    // },
    // {
    //   id: 4029,
    //   label: 'Provincial verification'
    // },
    // {
    //   id: 4041,
    //   label: 'International verification'
    // }
  ],
  [Providers.ValidityScreening]: [
    {
      id: 152010,
      label: 'OmniSearch (all county jurisdictions) + Quik Screen'
    },
    {
      id: 152011,
      label: 'OmniSearch Plus (all county and federal jurisdictions) + Quik Screen'
    }
  ]
};
