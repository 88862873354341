import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public isFullPage = false;
  @Input() public isBackButton = true;

  constructor(private readonly _router: Router) {}

  ngOnInit(): void {}

  public backTo() {
    this._router.navigate(['']);
  }
}
