import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {
  FeatureFlagKeys,
  ISetting
} from '@shared/interfaces/setting.interface';
import { SessionStorage } from 'ngx-webstorage';

@Directive({
  selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  @SessionStorage() public settings: ISetting;

  private hasView = false;
  private featureNames: FeatureFlagKeys[] = [];

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {}

  @Input() set appFeatureFlag(featureNames: FeatureFlagKeys[]) {
    this.featureNames = featureNames;
  }

  ngOnInit() {
    const allFeaturesEnabled = this.featureNames.every(feature =>
      this.settings.hasFeatureFlag(feature)
    );

    if (allFeaturesEnabled && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!allFeaturesEnabled && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
