import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferenceRequestsService {
  public refreshSource = new Subject();

  public refreshCalled$ = this.refreshSource.asObservable();

  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/reference_requests`
  );

  constructor(private readonly _http: HttpClient) {}

  public refresh() {
    this.refreshSource.next();
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    sort?: ISort
  ): Observable<IReferenceRequest[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (sort) {
      const sortValue = sort['isReverse'] ? `-${sort['value']}` : sort['value'];
      params = params.set('sort', sortValue);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V1, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => ReferenceRequest.fromAPIArray(res))
    );
  }

  public get(): Observable<IReferenceRequest[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V1)
      .pipe(map((res: IAPIArrayData) => ReferenceRequest.fromAPIArray(res)));
  }

  public patch(body: IAPIData): Observable<IReferenceRequest> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => ReferenceRequest.fromAPI(res)));
  }

  public sendReminder(id: string) {
    return this._http
      .post<any>(
        `${environment.ENDPOINTS.BACK_END.V1}/reminders/reference_requests/${id}`,
        {}
      )
      .pipe(map((res: any) => res));
  }
}
