import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAddress } from '@shared/interfaces/address.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { Employer } from '@shared/models/employer.model';
import { AddressesService } from '@shared/services/addresses.service';
import { EmployersService } from '@shared/services/employers.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-employers',
  templateUrl: './modals.employers.component.html'
})
export class ModalsEmployersComponent
  extends ModalDirective<IReference>
  implements OnInit
{
  @SessionStorage() public settings: ISetting;

  public title = String('MODALS.EMPLOYERS.EDIT.TITLE');
  public description = String('MODALS.EMPLOYERS.EDIT.DESCRIPTION');
  public submitKey = String('BUTTONS.SUBMIT');

  public entry: FormGroup;
  public errors: any = [];

  public config = { class: '' };
  public gaOptions: any = {};

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    maxDate: new Date(),
    showWeekNumbers: false,
    adaptivePosition: true
  };

  public isButtonsDisabled = Boolean(false);
  public isAddressEnabled = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _addresses: AddressesService,
    private readonly _fb: FormBuilder,
    private readonly _employers: EmployersService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((res: IEmployer) => {
      this.isAddressEnabled =
        this.settings?.is_employer_feedbacks_address_enabled;

      if (this.isAddressEnabled) {
        this.config.class = 'modal-lg';
      }

      if (res) {
        this.entry.patchValue({
          ...res,
          address: {
            ...res.address,
            identifiable: res.id
          }
        });
      }
    });
  }

  public updateCurrentDate() {
    const control = this.entry.controls['end_date'];
    const isCurrent = this.entry.get('isCurrent').value;

    if (!isCurrent) {
      control.setValidators([Validators.required]);
      control.enable();
    } else {
      this.entry.patchValue({
        end_date: null
      });

      control.setValidators([]);
      control.disable();
    }

    control.updateValueAndValidity();
  }

  public handleAddressChange(place: Address) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const address: any = {
      lat,
      lng
    };

    place.address_components.map((p) => {
      p.types.map((t: any) => {
        switch (t) {
          case 'street_number':
            address.unit = p.long_name;
            break;

          case 'route':
            address.line1 = p.long_name;
            break;

          case 'postal_code':
            address.postal_code = p.long_name;
            break;

          case 'locality':
            address.city = p.long_name;
            break;

          case 'administrative_area_level_1':
            address.state = p.long_name;
            break;

          case 'country':
            address.country = p.short_name;
            break;
        }
      });
    });

    this.entry.patchValue({
      address
    });
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.isButtonsDisabled = true;

      const url = `PATCH /employers/${value.id}`;
      const data = new Employer(value).apiData;
      this._employers.patch(data).subscribe(
        (res: IEmployer) => {
          this._logger.info(this.constructorName, url, res);

          if (!!value.address.line1) {
            this.postOrPatchAddress(value.address);
          }

          this._toast.success('Employer updated');
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.errors = err;
          this.isButtonsDisabled = false;
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.email]
        // [this._emailValidityValidator.validates]
      ],
      phone: ['', [NumbersOnlyValidator.NumbersOnly]],
      countryInitial: ['US'],
      country_code: ['1'],
      start_date: [''],
      end_date: [''],
      isCurrent: [false, [Validators.required]],
      candidate_position: [''],
      specialty: [''],
      address: this._fb.group({
        id: [''],
        complete: [''],
        unit: [''],
        line1: ['', [Validators.maxLength(254)]],
        country: [''],
        postal_code: [''],
        city: [''],
        state: [''],
        identifiable: ['', [Validators.required]]
      })
    });
  }

  private postOrPatchAddress(value: IAddress) {
    let url = `POST /addresses`;
    let fn = 'post';

    if (value.id) {
      url = `PATCH /addresses/${value.id}`;
      fn = 'patch';
    }

    this._addresses[fn]({
      ...value,
      identifiable: new Employer({ id: value.identifiable as string })
    }).subscribe(
      (res: IAddress) => {
        this._logger.info(this.constructorName, url, res);

        this.isButtonsDisabled = false;

        this._toast.success('Employer address updated');

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }
}
