import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { IQuestion } from '@shared/interfaces/question.interface';
import { Question } from '@shared/models/question.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/questions`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: APIuuid): Observable<IQuestion> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V1}/${id}`)
      .pipe(map((res: IAPIData) => Question.fromAPI(res)));
  }

  public patch(data: IAPIData): Observable<IQuestion> {
    const id: string = data.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${id}`, data)
      .pipe(map((res: IAPIData) => Question.fromAPI(res)));
  }

  public post(data: IAPIData): Observable<IQuestion> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V1, data)
      .pipe(map((res: IAPIData) => Question.fromAPI(res)));
  }
}
