import { IAPIData } from '@shared/interfaces/api.interface';

export interface EmailValidationWarning {
  typo_suggestion: string;
  has_deliverability_warning: boolean;
}

export class EmailValidation {
  public static readonly TYPE: string = String('EmailValidation');

  is_valid: boolean;
  warning?: EmailValidationWarning;

  constructor(partial: Partial<EmailValidation>) {
    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): EmailValidation {
    const data = res.data;

    if (!(data && data.type === EmailValidation.TYPE)) {
      throw new Error(
        `There was a problem parsing ${EmailValidation.TYPE} API data`
      );
    }

    return new EmailValidation({
      ...data.attributes
    });
  }
}
