import {
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IExperience } from '@shared/interfaces/experience.interface';
import { IVerification } from '@shared/interfaces/verification.interface';
import { Verification } from '@shared/models/verification.model';
import moment from 'moment';

export class Experience extends APIResource implements IExperience {
  public static readonly TYPE: string = String('Experience');

  id: APIuuid;
  type: string;
  company: string;
  position: string;
  start_date: string;
  end_date: string;
  description: string;
  website_url: string;
  verifications: IVerification[] = [];
  is_vitay_verified?: boolean;
  vitay_verification_requested?: boolean;

  constructor(partial: Partial<IExperience>) {
    super();

    Object.assign(this, partial);
  }

  public get formattedStartDate(): string {
    return moment(this.start_date).format('MMM DD, YYYY');
  }

  public get formattedEndDate(): string {
    return moment(this.end_date).format('MMM DD, YYYY');
  }

  public get is_verified() {
    return this.verifications.some((v: IVerification) => v.filled);
  }

  static fromAPIArray(res: IAPIArrayData): IExperience[] {
    return res.data.map((c: any) =>
      this.fromAPI({
        data: c,
        included: res.included
      })
    );
  }

  static fromAPI(res: IAPIData): IExperience {
    const data = res.data;

    if (!(data && data.type === Experience.TYPE)) {
      throw new Error(
        `There was a problem parsing ${Experience.TYPE} API data`
      );
    }

    const experience = new Experience({
      id: data.id,
      type: data.type,
      ...data.attributes
    });

    const included = res.included;

    if (included) {
      experience.verifications = Verification.fromAPIArray({
        data: included.filter(
          (i: IAPIDataObject) =>
            i.type === 'Verification' &&
            i.attributes.verifiable_type === 'Experience' &&
            i.attributes.verifiable_id === experience.id
        )
      });
    }

    return experience;
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: Experience.TYPE,
        attributes: {
          company: this.company,
          position: this.position,
          start_date: this.start_date,
          end_date: this.end_date,
          description: this.description,
          website_url: this.website_url
        },
        relationships: {}
      }
    } as IAPIData;

    return data;
  }
}
