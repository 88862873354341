import { buildApplication } from '@shared/builders/application.helper';
import {
  APIDatetime,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IApplication } from '@shared/interfaces/application.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IJob } from '@shared/interfaces/job.interface';

export class Application implements IApplication {
  public static readonly TYPE: string = String('Application');

  id: string;

  candidate: Partial<ICandidate>;
  employer: Partial<IEmployer>;
  job: Partial<IJob>;

  created_at: APIDatetime;
  updated_at: APIDatetime;

  constructor(partial: Partial<IApplication>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Application.TYPE,
        id: this.id,
        attributes: {},
        relationships: {
          candidate: this.candidate?.apiRelationship,
          employer: this.employer?.apiRelationship,
          job: this.job?.apiRelationship
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IApplication {
    const data = res.data;

    if (!(data && data.type === Application.TYPE)) {
      throw new Error(
        `There was a problem parsing ${Application.TYPE} API data`
      );
    }

    const application = new Application({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    const relationships = data.relationships;

    return buildApplication(application, relationships, included);
  }

  public static fromAPIArray(res: IAPIArrayData): IApplication[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Application.TYPE)) {
        throw new Error(
          `There was a problem parsing ${Application.TYPE} API data`
        );
      }

      const application = new Application({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      return buildApplication(application, relationships, included);
    });
  }
}
