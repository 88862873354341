import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICountry } from '@shared/interfaces/country.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { Recruiter } from '@shared/models/recruiter.model';
import { LoggerService } from '@shared/services/logger.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ToastService } from '@shared/services/toast.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-recruiters',
  templateUrl: './modals.recruiters.component.html'
})
export class ModalsRecruitersComponent
  extends ModalDirective<IRecruiter>
  implements OnInit
{
  @SessionStorage() private countries: ICountry[];
  @SessionStorage() private hiringFirm: IHiringFirm;

  public title = String('MODALS.RECRUITERS.ADD.TITLE');
  public description = String('MODALS.RECRUITERS.ADD.DESCRIPTION');
  public submitKey = String('BUTTONS.SUBMIT');

  public entry: FormGroup;
  public isButtonsDisabled = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _recruiters: RecruitersService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((r?: IRecruiter) => {
      if (!!r) {
        const country = this.countries.find(
          (c: ICountry) => c.initial === this.hiringFirm?.country
        );

        this.entry.patchValue({
          country_code: country?.phoneCode,
          countryInitial: country?.initial
        });
      }

      this.entry.patchValue({
        hiring_firm_id: this.hiringFirm.id
      });
    });

    this.createForm();
  }

  public onDismiss() {
    this.entry.enable();
    this.errors = [];

    super.onDismiss();
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.entry.disable();
      this.isButtonsDisabled = true;
      this.errors = [];

      const recruiter = {
        ...value,
        user: {
          first_name: value.first_name,
          last_name: value.last_name,
          email: value.email,
          phone: value.phone
        }
      };

      const url = 'POST /recruiters';
      this._recruiters.post(new Recruiter(recruiter).apiData).subscribe(
        (res: IRecruiter) => {
          this._logger.info(this.constructorName, url, res);

          this.isButtonsDisabled = false;
          this._toast.success('Recruiter added');

          this.resetModal(res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.isButtonsDisabled = false;
          this.errors = err;
          this.entry.enable();
        }
      );
    }
  }

  public resetForm() {
    this.entry.enable();

    super.resetForm();
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      hiring_firm_id: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.email]
        // [this._emailValidityValidator.validates]
      ],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      is_root: [false],
      phone: ['', [NumbersOnlyValidator.NumbersOnly]],
      countryInitial: ['US'],
      country_code: ['1', [Validators.required]],
      is_send_email_enabled: [true]
    });
  }
}
