import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { LoggerService } from '@shared/services/logger.service';
import { ReferencesService } from '@shared/services/references.service';

@Component({
  selector: 'app-sidebar-reference-activities',
  templateUrl: './sidebar.reference.activities.component.html'
})
export class SidebarReferenceActivitiesComponent implements OnInit {
  @Input() public reference: IReference;

  public isLoading = Boolean(true);
  public rows: IActivity[] = [];
  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.DATE',
      type: TableColType.Ago,
      selector: 'created_at'
    },
    {
      headerText: 'TABLES.HEADERS.DESCRIPTION',
      type: TableColType.Text,
      selector: 'description'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _references: ReferencesService
  ) {}

  ngOnInit(): void {
    this.getActivities();
  }

  public getActivities() {
    const id = this.reference?.id;
    const url = `GET /references/${id}/activity`;
    this._references.getReferenceActivities(id).subscribe(
      (res: IActivity[]) => {
        this._logger.info(this.constructorName, url, res);

        this.rows = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoading = false;
      }
    );
  }

  public trackByFn(i: IActivity): string {
    return i.id;
  }
}
