import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IVerification } from '@shared/interfaces/verification.interface';

@Component({
  selector: 'app-modals-verifications',
  templateUrl: './modals.verifications.component.html'
})
export class ModalsVerificationsComponent
  extends ModalDirective<IVerification>
  implements OnInit {
  public error: IAPIErrorObject;
  public entry: FormGroup;

  public title: string = String('MODALS.VERIFICATIONS.TITLE');
  public description: string = String('MODALS.VERIFICATIONS.DESCRIPTION');
  public submitKey: string = String('BUTTONS.SUBMIT');

  public isButtonsDisabled: boolean = Boolean(false);

  constructor(private readonly _fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((v: IVerification) => {
      this.entry.patchValue({
        id: v.id,
        comment: v.comment
      });
    });
  }

  public onSubmit() {}

  public onDismiss() {
    this.errors = [];
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: ['', [Validators.required]],
      comment: ['']
    });
  }
}
