import { buildJob } from '@shared/builders/job.helper';
import {
  APIDatetime,
  APIResource,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IJob } from '@shared/interfaces/job.interface';

export class Job extends APIResource implements IJob {
  public static readonly TYPE: string = String('Job');

  id: string;

  label: string;

  created_at: APIDatetime;
  updated_at: APIDatetime;

  // relationships
  hiring_firm: IHiringFirm;

  public get apiData(): IAPIData {
    return {
      data: {
        type: Job.TYPE,
        id: this.id,
        attributes: {
          label: this.label
        },
        relationships: {
          hiring_firm: this.hiring_firm?.apiRelationship
        }
      }
    } as IAPIData;
  }

  constructor(partial: Partial<IJob>) {
    super();

    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): IJob {
    const data = res.data;

    if (!(data && data.type === Job.TYPE)) {
      throw new Error(`There was a problem parsing ${Job.TYPE} API data`);
    }

    const job = new Job({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    const relationships = data.relationships;

    return buildJob(job, relationships, included);
  }

  public static fromAPIArray(res: IAPIArrayData): IJob[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Job.TYPE)) {
        throw new Error(`There was a problem parsing ${Job.TYPE} API data`);
      }

      const job = new Job({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      return buildJob(job, relationships, included);
    });
  }
}
