import { APIResource, IAPIData } from '@shared/interfaces/api.interface';
import { IAuthentication } from '@shared/interfaces/authentication.interface';

export class Authentication extends APIResource implements IAuthentication {
  public static readonly TYPE: string = String('OAuth');

  token: string;

  constructor(partial: Partial<IAuthentication>) {
    super();

    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IAuthentication {
    const data = res.data;

    if (!(data && data.type === Authentication.TYPE)) {
      throw new Error(
        `There was a problem parsing ${Authentication.TYPE} API Data`
      );
    }

    return new Authentication({
      // id: data.id,
      ...data.attributes
    });
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: Authentication.TYPE,
        id: this.id,
        attributes: {
          token: this.token
        }
      }
    } as IAPIData;

    return data;
  }
}
