import {
  APIDatetime,
  APIuuid,
  IAPIArrayData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IEmailActivity } from '@shared/interfaces/email-activity.interface';

export class EmailActivity implements IEmailActivity {
  public static readonly TYPE: string = String('EmailActivity');

  // Attributes
  id: APIuuid;
  message: string;
  email: string;
  event: string;
  created_at: APIDatetime;
  updated_at: APIDatetime;

  constructor(partial: Partial<IEmailActivity>) {
    Object.assign(this, partial);
  }

  get label(): any {
    let className = '';

    switch (this.event) {
      case 'processed':
      case 'delivered':
        className = 'lnr-shield-check v-text-success';
        break;

      case 'open':
        className = 'lnr-envelope-open v-text-success';
        break;

      case 'click':
        className = 'lnr-mouse v-text-success';
        break;

      case 'group_resubscribe':
        className = 'lnr-register v-text-success';
        break;

      case 'bounce':
      case 'dropped':
      case 'spamreport':
      case 'unsubscribe':
      case 'group_unsubscribe':
        className = 'lnr-warning v-text-danger';
        break;

      default:
        break;
    }

    return {
      label: this.event,
      class: className
    };
  }

  public static fromAPIArray(res: IAPIArrayData): IEmailActivity[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === EmailActivity.TYPE)) {
        throw new Error(
          `There was a problem parsing ${EmailActivity.TYPE} API data`
        );
      }

      const emailActivity = new EmailActivity({
        id: data.id,
        ...data.attributes
      });

      return emailActivity;
    });
  }
}
