import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAnalytics } from '@shared/interfaces/analytics.interface';
import {
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';
import { ITypeaheadable } from '@shared/interfaces/typeaheadable.interface';
import { Analytics } from '@shared/models/analytics.model';
import { Recruiter } from '@shared/models/recruiter.model';
import { Reference } from '@shared/models/reference.model';
import { Typeahead } from '@shared/models/typeahead.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecruitersService implements IPaginable, ITypeaheadable {
  public recruitersSource = new Subject();
  public recruitersCalled$ = this.recruitersSource.asObservable();

  private readonly ENDPOINT_V2: string = String(
    `${environment.ENDPOINTS.BACK_END.V2}/recruiters`
  );

  constructor(private readonly _http: HttpClient) {}

  public refresh() {
    this.recruitersSource.next();
  }

  public delete(id: string): Observable<IRecruiter> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V2}/${id}`)
      .pipe(map((res: IAPIData) => Recruiter.fromAPI(res)));
  }

  public get(filter?: any): Observable<IRecruiter[]> {
    let params: HttpParams = new HttpParams();
    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (!!filter.page_size) {
      params = params.set(`page[page_size]`, filter.page_size);
    }

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V2, { params })
      .pipe(map((res: IAPIArrayData) => Recruiter.fromAPIArray(res)));
  }

  public find(id: string): Observable<IRecruiter> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V2}/${id}`)
      .pipe(map((res: IAPIData) => Recruiter.fromAPI(res)));
  }

  public getAnalytics(
    id: string,
    query: { start_date: string; end_date: string; type: string }
  ): Observable<any> {
    let params: HttpParams = new HttpParams();

    Object.keys(query).map(
      (key: string) => (params = params.set(key, String(query[key])))
    );

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V2}/${id}/analytics`, {
        params
      })
      .pipe(
        map((res: IAPIData) => {
          const included = res?.included;
          const analytics = Analytics.fromAPI(res);

          analytics.references = Reference.fromAPIArray({
            data:
              included &&
              included.filter(
                (i: IAPIDataObject) => Reference.TYPES.indexOf(i.type) !== -1
              )
          });

          return analytics;
        })
      );
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter
  ): Observable<IRecruiter[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V2, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => Recruiter.fromAPIArray(res))
    );
  }

  public getStatistics(id: string, q: any): Observable<IAnalytics> {
    let params: HttpParams = new HttpParams();
    params = params.set('start_date', String(q.start_date));
    params = params.set('end_date', String(q.end_date));

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V2}/${id}/statistics`, { params })
      .pipe(map((res: IAPIData) => Analytics.fromAPI(res)));
  }

  public patch(body: IAPIData): Observable<IRecruiter> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V2}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => Recruiter.fromAPI(res)));
  }

  public post(recruiter: IAPIData): Observable<IRecruiter> {
    // TO DO: CLEAN *with_user ENDPOINT
    return this._http
      .post<IAPIData>(
        `${environment.ENDPOINTS.BACK_END.V2}/recruiters_with_user`,
        recruiter
      )
      .pipe(map((res: IAPIData) => Recruiter.fromAPI(res)));
  }

  public searchTypeahead(search: {
    data: { search_criteria: string };
  }): Observable<ITypeahead> {
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V2}/search`, search)
      .pipe(map((res: IAPIData) => Typeahead.fromAPI(res)));
  }
}
