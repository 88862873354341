import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICountry } from '@shared/interfaces/country.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { Country } from '@shared/models/country.model';
import { SessionStorage } from 'ngx-webstorage';
import { Select2Option, Select2Data } from 'ng-select2-component';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html'
})
export class PhoneNumberComponent implements OnInit {
  @SessionStorage() public countries: ICountry[];

  @Input() public formGroup: FormGroup;
  @Input() public language = String('en');
  @Input() public explainer = String('');

  @SessionStorage() private hiringFirm: IHiringFirm;

  public mask = '00000000000000000000';
  public countriesOptions: Array<Select2Option> = [];

  private defaultCountry: ICountry;

  constructor() {}

  ngOnInit(): void {
    this.initializeCountriesOptions();

    this.defaultCountry =
      this.countries?.find((c: ICountry) => c.initial === 'US') ||
      new Country({
        initial: 'US',
        phoneCode: '1'
      });

    if (!!!this.hiringFirm || !!!this.countries) {
      return this.setCountryCode(this.defaultCountry);
    }

    const country: ICountry =
      this.countries.find(
        (c: ICountry) => (this.hiringFirm.country || 'US') === c.initial
      ) || this.defaultCountry;

    return this.setCountryCode(country);
  }

  private initializeCountriesOptions(): void {
    this.countriesOptions = this.countries.map((c: ICountry): Select2Option => ({
      value: c?.initial,
      label: `(+${c?.phoneCode}) ${c?.label[this.language]}`
    }));
  }

  public updateCountryCode() {
    const countryCode = this.formGroup.value.countryInitial;

    let country = this.countries.find(
      (c: ICountry) => c.initial === countryCode
    );

    if (!!!country) {
      country = this.defaultCountry;
    }

    return this.formGroup.patchValue({
      country_code: country.phoneCode
    });
  }

  private setCountryCode(c: ICountry): void {
    return this.formGroup.patchValue({
      country_code: c.phoneCode,
      countryInitial: c.initial
    });
  }
}
