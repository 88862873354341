import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { IActivity } from '@shared/interfaces/activity.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { CandidatesService } from '@shared/services/candidates.service';
import { LoggerService } from '@shared/services/logger.service';

@Component({
  selector: 'app-sidebar-candidate-activities',
  templateUrl: './sidebar.candidate.activities.component.html'
})
export class SidebarCandidateActivitiesComponent implements OnInit {
  @Input() public candidate: ICandidate;

  public isLoading = Boolean(true);
  public rows: IActivity[] = [];
  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.DATE',
      type: TableColType.Ago,
      selector: 'created_at'
    },
    {
      headerText: 'TABLES.HEADERS.DESCRIPTION',
      type: TableColType.Text,
      selector: 'description'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _candidates: CandidatesService,
    private readonly _logger: LoggerService
  ) {}

  ngOnInit(): void {
    this.getActivities();
  }

  public getActivities() {
    const id = this.candidate?.id;
    const url = `GET /candidates/${id}/activity`;
    this._candidates.getCandidateActivities(id).subscribe(
      (res: IActivity[]) => {
        this._logger.info(this.constructorName, url, res);

        this.rows = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoading = false;
      }
    );
  }

  public trackByFn(i: IActivity): string {
    return i.id;
  }
}
