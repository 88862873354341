import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { QuestionCategories, QuestionType } from '@shared/enums/question.enum';
import { ratings, ratingsOn10 } from '@shared/helpers/array.helper';
import { IAnswer } from '@shared/interfaces/answer.interface';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { Answer } from '@shared/models/answer.model';
import { AnswersService } from '@shared/services/answers.service';
import { ToastService } from '@shared/services/toast.service';

@Component({
  selector: 'app-modals-answers',
  templateUrl: './modals.answers.component.html'
})
export class ModalsAnswersComponent
  extends ModalDirective<IAnswer>
  implements OnInit
{
  public title = String('MODALS.ANSWERS.ADD.TITLE');
  public submitKey = String('BUTTONS.SAVE');
  public errors: IAPIErrorObject[] = [];
  public entry: FormGroup;
  public questionCategories = QuestionCategories;
  public questionType = QuestionType;
  public ratings: { label: string; value: number }[] = ratings;
  public ratingsOn10: { label: string; value: number }[] = ratingsOn10;
  public isButtonsDisabled = Boolean(false);

  public modules: any = {
    toolbar: false
  };

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _answers: AnswersService,
    private readonly _toast: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((a: IAnswer) => {
      if (a) {
        this.title = 'MODALS.ANSWERS.EDIT.TITLE';

        this.entry.patchValue(a);
      }
    });
  }

  public getRatingLabel(rating: string, scale: number = 5) {
    if (isNaN(+rating)) {
      return;
    }

    let ratings = this.ratings;

    if (scale === 10) {
      ratings = this.ratingsOn10;
    }

    return ratings.find((r: any) => r.value === +rating).label;
  }

  public onDismiss() {
    this.errors = [];
    this.entry.enable();
    super.onDismiss();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (valid) {
      this.errors = [];
      this.isButtonsDisabled = true;

      const answerData = new Answer(value).apiData;
      this._answers.patch(answerData).subscribe(
        (res: IAnswer[]) => {
          this.resetModal(res);

          this._toast.success('Answer updated');

          this.isButtonsDisabled = false;
          this.entry.disable();
        },
        (err: any) => {
          this.errors = err;
          this.isButtonsDisabled = false;
          this.entry.disable();
        }
      );
    }
  }

  public resetForm() {
    this.entry.enable();
    super.resetForm();
  }

  public whenModalClose(type: string): void {
    this.errors = [];
    if (type === 'SUBMIT') {
      this.entityForm.ngSubmit.emit();
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      question: ['', [Validators.required]],
      value: ['', [Validators.required]],
      scale: [''],
      type: ['', [Validators.required]],
      category: ['', [Validators.required]],
      comment: ['']
    });
  }
}
