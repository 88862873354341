import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IApplication } from '@shared/interfaces/application.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ICountry } from '@shared/interfaces/country.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IJob } from '@shared/interfaces/job.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Application } from '@shared/models/application.model';
import { Candidate } from '@shared/models/candidate.model';
import { Job } from '@shared/models/job.model';
import { ApplicationsService } from '@shared/services/applications.service';
import { CandidatesService } from '@shared/services/candidates.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { JobsService } from '@shared/services/jobs.service';
import { LoggerService } from '@shared/services/logger.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ToastService } from '@shared/services/toast.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import jwtDecode from 'jwt-decode';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-modals-candidates',
  templateUrl: './modals.candidates.component.html'
})
export class ModalsCandidatesComponent
  extends ModalDirective<ICandidate>
  implements OnInit
{
  @LocalStorage() public canManageSubAccount: boolean;
  @LocalStorage() public isSubAccount: boolean;

  @SessionStorage() public settings: ISetting;
  @SessionStorage() private hiringFirm: IHiringFirm;
  @SessionStorage() private countries: ICountry[];

  @SessionStorage() private user: IUser;

  public title = String('MODALS.CANDIDATES.ADD.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');

  public candidate: ICandidate;
  public entry: FormGroup;
  public errors: IAPIErrorObject[] = [];
  public jobs: IJob[] = [];
  public isButtonsDisabled = Boolean(false);
  public isRoot = Boolean(false);
  public isCandidateEmailRequired = Boolean(false);
  public isRedirectAfterApplicationEnable = Boolean(false);
  public assignedRecruiter: IRecruiter;

  public recruiters: IRecruiter[] = [];
  public params: any = {
    type: 'candidate'
  };

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    showWeekNumbers: false,
    adaptivePosition: true
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _applications: ApplicationsService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _candidates: CandidatesService,
    private readonly _jobs: JobsService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,
    private readonly _recruiters: RecruitersService,
    private readonly _router: Router,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  public get service(): RecruitersService {
    return this._recruiters;
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((c: ICandidate) => {
      this.isRedirectAfterApplicationEnable = false;
      this.isRoot = this.user.isRootRecruiter;

      this.setDataForm();

      if (c?.id) {
        this.candidate = c;

        this.title = 'MODALS.CANDIDATES.EDIT.TITLE';
        this.submitKey = 'BUTTONS.SAVE';

        this.entry.patchValue({
          ...this.candidate,
          placement_date: c.placement_date ? new Date(c.placement_date) : '',
          added_by_id: this.candidate.added_by.id
        });
      } else {
        const token = this._commonEnvironments.getToken();
        const payload: IPayload = jwtDecode(token);
        const addedById = payload.recruiter_id;

        this.entry.patchValue({
          added_by_id: addedById
        });
      }

      this.getAssignedRecruiter();

      if (this.settings.is_plugins_job_board_enabled) {
        this.getJobs();

        const applications = c?.applications || [];

        if (applications.length > 0) {
          const job = (applications[0] as IApplication).job;
          this.entry.patchValue({
            job
          });
        }
      }
    });

    this.setDataForm();
  }

  public onMatOptionSelect(r: IRecruiter) {
    this.entry.patchValue({
      added_by_id: r.id
    });

    this.getAssignedRecruiter();
  }

  public onGetValueFromAttributes(r: IUser) {
    return `${r.first_name} ${r.last_name} (${r.email})`;
  }

  public generateID() {
    const internalId = uuid().substring(0, 8);
    this.entry.patchValue({
      internal_id: internalId
    });
  }

  public onDismiss() {
    this.errors = [];
    this.entry.enable();

    this.resetAll();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.isButtonsDisabled = true;
      this.entry.disable();
      this.errors = [];

      if (!!value.job.id || !!value.job.label) {
        if (!!value.job.id) {
          this.postApplication(value);
        } else {
          this.postJob(value);
        }

        if (!!value.id) {
          this.patch(value);
        }
      } else {
        if (this.candidate?.applications?.length > 0) {
          this.deleteApplication(this.candidate.applications[0].id);
        }

        if (!!value.id) {
          this.patch(value);
        } else {
          this.post(value);
        }
      }
    }
  }

  public typeaheadOnSelect(i: TypeaheadMatch): void {
    this.entry.get('job').patchValue(i.item);
  }

  public typeaheadNoResults(): void {
    this.entry.get('job').patchValue({
      id: ''
    });
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      added_by_id: [''],
      email: [
        '',
        [Validators.email]
        // [this._emailValidityValidator.validates]
      ],
      internal_id: ['', [Validators.required, Validators.maxLength(63)]],
      secondary_id: ['', [Validators.maxLength(63)]],
      job_title: '',
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      preferred_name: '',
      notes: '',
      phone: ['', [NumbersOnlyValidator.NumbersOnly]],
      countryInitial: [''],
      country_code: ['', [Validators.required]],
      granted_permission: ['', [Validators.required]],
      placement_date: [''],
      job: this._fb.group({
        id: [''],
        label: ['', this.settings?.is_plugins_job_board_enabled ? Validators.required : null]
      })
    });
  }

  private getAssignedRecruiter() {
    const url = String('GET /recruiters');
    this._recruiters.find(this.entry.get('added_by_id').value).subscribe(
      (res: IRecruiter) => {
        this._logger.info(this.constructorName, url, res);

        this.assignedRecruiter = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private patch(value: ICandidate) {
    const url = `PATCH /candidates/${value.id}`;
    this._candidates.patch(new Candidate(value).apiData).subscribe(
      (res: ICandidate) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Candidate updated');
        this.resetModal(res); // reset form, close modal, and give out returned value to the parent.
        this.resetAll();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;

        this.entry.enable();
        this.isButtonsDisabled = false;
      }
    );
  }

  private post(value: any, isApplication?: boolean) {
    this.errors = [];

    const url = 'POST /candidates';
    this._candidates.post(new Candidate(value).apiData).subscribe(
      (res: ICandidate) => {
        this._logger.info(this.constructorName, url, res);

        if (isApplication) {
          this.candidate = res;
          this.postApplication(value);
        } else {
          this._toast.success('Candidate added');
          this._router.navigate(['/candidates', res.id]);

          this.resetModal(res);
          this.resetAll();
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        // TO: CLEAN BACK END ERROR HANDLING
        this.errors = err;

        this.entry.enable();
        this.isButtonsDisabled = false;
      }
    );
  }

  private resetAll() {
    this.entry.enable();
    this.entry.reset();

    this.candidate = null;
    this.isButtonsDisabled = false;
  }

  private setDataForm() {
    const country = this.countries.find(
      (c: ICountry) =>
        c.phoneCode === this.candidate?.country_code ||
        c.initial === this.hiringFirm?.country
    );

    const email = this.entry.get('email');
    if (this.isCandidateEmailRequired) {
      email.setValidators([Validators.required, Validators.email]);
    } else {
      email.setValidators([Validators.email]);
    }

    // email.setAsyncValidators([this._emailValidityValidator.validates]);

    email.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    this.entry.patchValue({
      ...this.candidate,
      country_code: country?.phoneCode,
      countryInitial: country?.initial,
      granted_permission: !this.settings.require_candidate_permission
    });
  }

  private getJobs() {
    const url = `GET /jobs`;
    this._jobs.get().subscribe(
      (res: IJob[]) => {
        this._logger.info(this.constructorName, url, res);

        this.jobs = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
      }
    );
  }

  private postJob(value: any) {
    this.errors = [];

    const jobData = new Job({ label: value.job.label }).apiData;

    const url = 'POST /jobs';
    this._jobs.post(jobData).subscribe(
      (res: IJob) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.patchValue({ job: res });

        this.postApplication(this.entry.value);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private postApplication(value: any) {
    this.errors = [];

    if (!!!this.candidate) {
      this.isRedirectAfterApplicationEnable = true;
      return this.post(value, true);
    }

    const applicationData = new Application({
      candidate: this.candidate,
      job: new Job(value.job)
    }).apiData;

    const url = 'POST /applications';
    this._applications.post(applicationData).subscribe(
      (res: IApplication) => {
        this._logger.info(this.constructorName, url, res);

        if (this.isRedirectAfterApplicationEnable) {
          this._toast.success('Candidate added');
          this._router.navigate(['/candidates', this.candidate.id]);
        }

        this.resetModal(res);
        this.resetAll();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.entry.enable();
        this.isButtonsDisabled = false;
      }
    );
  }

  private deleteApplication(id: string) {
    const url = `POST /applications/${id}`;
    this._applications.delete(id).subscribe(
      (res: IApplication) => {
        this._logger.info(this.constructorName, url, res);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
