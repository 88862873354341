import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ITab } from '@shared/interfaces/tab.interface';
import { CandidatesService } from '@shared/services/candidates.service';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-sidebar-candidate',
  templateUrl: './sidebar.candidate.component.html'
})
export class SidebarCandidateComponent implements OnInit {
  @ViewChild(TabsetComponent, { static: true }) public tabset: TabsetComponent;

  @Input() private id: string;

  public isLoading = Boolean(true);
  public tabs: ITab[] = [
    {
      id: 0,
      label: 'TABS.ACTIVITIES',
      icon: 'lnr-papers',
      isActive: true
    },
    {
      id: 1,
      label: 'TABS.EMAIL_ACTIVITY',
      icon: 'lnr-envelope'
    },
    {
      id: 2,
      label: 'TABS.TEXT_ACTIVITY',
      icon: 'lnr-smartphone'
    },
    {
      id: 3,
      label: 'TABS.REFERENCE_REQUESTS',
      icon: 'lnr-paper-plane'
    },
    {
      id: 4,
      label: 'TABS.REMINDERS',
      icon: 'lnr-alarm'
    },
    {
      id: 5,
      label: 'TABS.ABOUT',
      icon: 'lnr-question'
    }
  ];

  public activeTab = Number(0);

  public candidate: ICandidate;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _candidates: CandidatesService,
    private readonly _logger: LoggerService,
    private readonly _sidebar: SidebarService
  ) {}

  ngOnInit(): void {
    this.getCandidate();
  }

  public selectTab(index: number): void {
    this.tabs[index].isActive = true;
    this.activeTab = index;
  }

  public close() {
    this._sidebar.closeSidebar();
  }

  public trackByFn(i: any) {
    return i.id;
  }

  private getCandidate() {
    const url = `GET /candidates/${this.id}`;
    this._candidates.find(this.id).subscribe(
      (res: ICandidate) => {
        this._logger.info(this.constructorName, url, res);

        this.candidate = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.isLoading = false;
      }
    );
  }
}
