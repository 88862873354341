import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';
import { SidebarService } from '@shared/services/sidebar.service';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appSidebarOnScroll]'
})
export class SidebarOnScrollDirective implements OnInit, AfterViewInit {
  constructor(
    private readonly _el: ElementRef,
    private readonly _sidebars: SidebarService
  ) {}

  ngOnInit(): void {
    fromEvent(this._el.nativeElement.parentElement, 'scroll').subscribe(() => {
      this._sidebars.scrollSidebar(true);
    });
  }

  ngAfterViewInit(): void {}
}
