import {
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IJob } from '@shared/interfaces/job.interface';
import { HiringFirm } from '@shared/models/hiring-firm.model';

export const buildJob = (
  job: IJob,
  relationships: any,
  included: any
): IJob => {
  const hiringFirm =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.hiring_firm as IAPIRelationship).id
    );

  if (hiringFirm) {
    job.hiring_firm = HiringFirm.fromAPI({
      data: hiringFirm,
      included
    });
  }

  return job;
};
