import { IAPIRelationship } from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { Customer } from '@shared/models/customer.model';
// import { Recruiter } from '@shared/models/recruiter.model';
import { Setting } from '@shared/models/setting.model';
import { Subscription } from '@shared/models/subscription.model';
import { User } from '@shared/models/user.model';

export const buildHiringFirm = (
  hiringFirm: IHiringFirm,
  relationships: any,
  included: any
): any => {
  if (relationships.roots) {
    hiringFirm.roots = relationships.roots;
  }

  const rootRecruiter =
    included &&
    included.find(
      (i: any) =>
        i.type === 'Recruiter' &&
        i.attributes.is_default &&
        i.attributes.is_root &&
        i.attributes.hiring_firm_id === hiringFirm.id
    );

  if (!!rootRecruiter) {
    hiringFirm.default_root = User.fromAPI({
      data:
        included &&
        included.find(
          (i: IAPIRelationship) =>
            i.type === 'User' && i.id === rootRecruiter.attributes.user_id
        )
    });
  }

  const customerData = included.find((i: IAPIRelationship) => i.type === 'Customer')
  
  if (relationships.customer && customerData) {
    hiringFirm.customer = Customer.fromAPI({
      data: included.find((i: IAPIRelationship) => i.type === 'Customer')
    });
  }

  if (!!relationships.subscription) {
    hiringFirm.subscription = Subscription.fromAPI({
      data:
        included &&
        included.find(
          (i: IAPIRelationship) =>
            i.type === 'Subscription' &&
            relationships &&
            i.id === (relationships.subscription as IAPIRelationship).id
        )
    });
  }

  if (!!relationships.settings) {
    hiringFirm.settings = Setting.fromAPI({
      data:
        included &&
        included.find(
          (i: IAPIRelationship) =>
            i.type === 'Setting' &&
            relationships &&
            i.id === (relationships.settings as IAPIRelationship).id
        )
    });
  }

  return hiringFirm;
};
