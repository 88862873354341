import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { reportFormats } from '@shared/helpers/array.helper';
import { externalAuthorizations } from '@shared/helpers/integrations.helper';
import { IAnswer } from '@shared/interfaces/answer.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IIntegration } from '@shared/interfaces/integration.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IReferenceRelationship } from '@shared/interfaces/reference-relationship.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { Answer } from '@shared/models/answer.model';
import { DocumentsService } from '@shared/services/documents.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { IntegrationsAuthorizationsService } from '@shared/services/integrations/integrations.authorizations.service';
import { IntegrationsService } from '@shared/services/integrations/integrations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ReferencesService } from '@shared/services/references.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { ToastService } from '@shared/services/toast.service';
import FileSaver from 'file-saver';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { Broadcaster, NgXCable } from 'ngx-cable';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar-reference-question-set',
  templateUrl: './sidebar.reference.question-set.component.html'
})
export class SidebarReferenceQuestionSetComponent implements OnInit, OnDestroy {
  @Input() public reference: IReference;
  @SessionStorage() public settings: ISetting;

  @SessionStorage() private referenceRelationships: IReferenceRelationship[];

  @LocalStorage() private lng: string;

  public reportFormats: any[] = reportFormats;
  public externalAuthorizations: IIntegration[] = externalAuthorizations;

  public isLoading = Boolean(true);
  public isCompleting = Boolean(false);
  public isPending = Boolean(false);
  public isEditable = Boolean(false);
  public isDownloading = Boolean(false);
  public isSynchronizing = Boolean(false);
  public includeReportContact = Boolean(false);
  public isReferenceSyncAvailable = Boolean(false);
  public isButtonsDisabled = Boolean(false);
  public isDownloadable = Boolean(false);

  public title = String('REFERENCE.TITLE');
  public channel = String('ReferencesChannel');
  public answers: IAnswer[] = [];

  public openReferenceModal: Subject<any> = new Subject();
  public openConfirmModal: Subject<boolean> = new Subject();
  public openNotificationModal: Subject<any> = new Subject();
  public closeModal: Subject<any> = new Subject();

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _broadcaster: Broadcaster,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _documents: DocumentsService,
    private readonly _externalServices: IntegrationsAuthorizationsService,
    private readonly _logger: LoggerService,
    private readonly _ngcable: NgXCable,
    private readonly _integrations: IntegrationsService,
    private readonly _references: ReferencesService,
    private readonly _toast: ToastService,
    private readonly _sidebar: SidebarService
  ) {
    this.afterSubmit = this.afterSubmit.bind(this);
    this.delete = this.delete.bind(this);
  }

  ngOnInit(): void {
    if (!this.reference.filled) {
      this._broadcaster.on(this.channel).subscribe((res: any) => {
        this._logger.info(
          this.constructorName,
          `Broadcast ${this.channel}`,
          res
        );

        const type = res.type;
        switch (type) {
          case 'references_completing':
            this.isCompleting = res.isCompleting;
            break;

          case 'references_completed':
            this.getReference(true);
            break;

          case 'references_unsubscribed':
          default:
            this.isCompleting = false;
            break;
        }
      });

      this._ngcable.connect(this._commonEnvironments.ENDPOINTS_SOCKET);

      this._ngcable.create({
        channel: this.channel,
        room: this.reference.id
      });
    }

    this.getReference();
    this.getHiringFirmIntegrations();
  }

  ngOnDestroy(): void {
    // No need to unsubscribe here. We close _ngcable on the candidate page
    // this._ngcable?.unsubscribe();
  }

  public getRelationshipComplete(r: string) {
    const relationship = this.referenceRelationships?.find(
      (rr: IReferenceRelationship) => rr.value === r && rr.language === this.lng
    );

    const label = relationship ? relationship.label : '-';

    let company = '';
    if (!!this.reference.company_name) {
      company = `at ${this.reference.company_name}`;
    }

    let dates = '';
    if (!!this.reference.start_date || !!this.reference.end_date) {
      dates = `(from ${
        this.reference.start_date
          ? moment(this.reference.start_date).format('ll')
          : '-'
      } to ${
        this.reference.end_date
          ? moment(this.reference.end_date).format('ll')
          : 'Present'
      })`;
    }

    return `${label} ${company} ${dates}`;
  }

  public getReference(isRefresh?: boolean) {
    const id = this.reference?.id;
    const url = `GET /references/${id}`;
    this._references.find(id).subscribe(
      (res: IReference) => {
        this._logger.info(this.constructorName, url, res);

        this.reference = res;
        this.answers =
          (this.reference.filled &&
            (this.reference.answer_set?.answers as Answer[])) ||
          [];

        this.isPending =
          this.reference?.is_pending && !this.reference?.filled_at;

        const candidateRecruiter = (this.reference.candidate as ICandidate)
          .added_by;

        const token = this._commonEnvironments.getToken();
        const payload: IPayload = jwtDecode(token);

        this.isEditable =
          payload.recruiter_id === candidateRecruiter.id ||
          payload.recruiter_id === this.reference.requested_by_id ||
          payload.roles.includes('root_recruiter');

        this.isDownloadable =
          this.reference.filled && !!!this.reference.decline;

        if (isRefresh) {
          this._toast.success('Reference refreshed');
          this._references.refresh(this.reference);
        }

        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.isLoading = false;
      }
    );
  }

  public afterSubmit() {
    this.getReference(true);
    if (!this.reference.filled) {
      this.openReferenceNotificationModal();
    }
  }

  public download(endpoint: string = 'pdf') {
    this.isDownloading = true;

    const params: any = {
      id: this.reference.id,
      type: 'references',
      include_contact: this.includeReportContact,
      endpoint
    };

    const url = `GET /documents/${params.endpoint}?type=${params.type}`;
    this._documents.get(params).subscribe(
      (res: Blob) => {
        this._logger.info(this.constructorName, url, res);
        const candidate = this.reference.candidate as ICandidate;

        FileSaver.saveAs(
          res,
          `${candidate.internal_id}_${candidate.first_name}_${candidate.last_name}_by_${this.reference.last_name}_reference_report.${params.endpoint}`
        );

        this.isDownloading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isDownloading = false;
      }
    );
  }

  public openDeleteModal() {
    this.openConfirmModal.next();
  }

  public openReferenceNotificationModal() {
    this.openNotificationModal.next({ reference: this.reference });
  }

  public openModal() {
    this.openReferenceModal.next({ reference: this.reference });
  }

  public uploadDocument(label: string): void {
    this.isSynchronizing = true;

    const url = `POST /services/${label}/documents`;
    this._integrations
      .uploadDocument(label, 'references', this.reference.id)
      .subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success(`Reference document uploaded`);
          this.isSynchronizing = false;
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this._toast.error(err[0].detail);
          this.isSynchronizing = false;
        }
      );
  }

  public uploadReference(service: string): void {
    this.isSynchronizing = true;

    const url = `POST /services/${service}/references`;
    this._integrations.uploadReference(service, this.reference.id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success(`Reference uploaded`);
        this.isSynchronizing = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error(err[0].detail);
        this.isSynchronizing = false;
      }
    );
  }

  public delete() {
    this.isButtonsDisabled = true;

    const id = this.reference.id;
    const url = `DELETE /references/${id}`;
    this._references.delete(id).subscribe(
      (res: IReference) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reference deleted');
        this.isButtonsDisabled = false;

        this.closeModal.next();
        this._sidebar.closeSidebar();
        this._references.refresh();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isButtonsDisabled = false;
      }
    );
  }

  private getHiringFirmIntegrations() {
    const url = 'GET /external_authorizations';
    this._externalServices.get().subscribe(
      (res: IIntegration[]) => {
        this._logger.info(this.constructorName, url, res);

        res.forEach((i: IIntegration) => {
          this.externalAuthorizations = this.externalAuthorizations.map(
            (e: IIntegration) => {
              if (e.id === i.id) {
                if (e.id === 'target_recruit' || e.id === 'erecruit') {
                  this.isReferenceSyncAvailable = true;
                }

                return i;
              }

              return e;
            }
          );
        });
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
