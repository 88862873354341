import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html'
})
export class ScoreComponent implements OnInit {
  @Input() public isAnimated: boolean = Boolean(false);
  @Input() public score: number;
  @Input() public backgroundColor: string = String('#FFFFFF');
  @Input() public title: string;
  @Input() public backgroundOpacity: number = Number(0);
  @Input() public radius: number = Number(15);
  @Input() public tooltip: string;
  @Input() public titleFontSize: number = Number(10);
  @Input() public titleColor: string = String('#444444');
  @Input() public outerStrokeWidth: number = Number(2);

  public value: number;
  public animationDuration: number = Number(150);

  constructor() {}

  ngOnInit(): void {}

  public isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  public getColor(): string {
    if (typeof this.score === 'string') {
      return 'rgba(0, 0, 0, 0)';
    } else if (this.score < 50) {
      return '#FF2721';
    } else if (this.score < 60) {
      return '#D35400';
    } else if (this.score < 75) {
      return '#F1C40F';
    } else if (this.score < 85) {
      return '#97E031';
    } else {
      return '#21CE99';
    }
  }
}
