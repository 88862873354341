import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IntegrationId } from '@shared/interfaces/integration.interface';
import { IntegrationsJobDiva } from '@shared/models/integrations/integrations.jobdiva.model';
import { Reference } from '@shared/models/reference.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  public id: IntegrationId;
  public label: string;
  public isAuthorized: boolean;
  public isLoading: boolean;

  constructor(private readonly _http: HttpClient) {}

  public uploadReference(service: string, id: string): any {
    return this._http
      .post(
        `${environment.SERVER_ENDPOINT}/api/services/${service}/references`,
        new Reference({ id }).apiData
      )
      .pipe(map((res: IAPIData) => res));
  }

  public uploadCandidate(
    service: string,
    type: string,
    id: string
  ): Observable<any> {
    let url = `${environment.SERVER_ENDPOINT}/api/${service}/entity`;
    let method = 'put';
    let params: any = {
      params: {
        entity_type: type,
        id
      }
    };

    if (
      service === 'jobdiva' ||
      service === 'erecruit' ||
      service === 'target_recruit' ||
      service === 'salesforce' ||
      service === 'salesforce_isv'
    ) {
      url = `${environment.SERVER_ENDPOINT}/api/services/${service}/candidates`;
      method = 'post';
      params = {
        data: {
          attributes: {
            entity_type: type,
            id
          }
        }
      };
    }

    return this._http[method](url, params);
  }

  public uploadDocument(
    service: string,
    type: string,
    id: string
  ): Observable<any> {
    if (
      service === 'jobdiva' ||
      service === 'erecruit' ||
      service === 'target_recruit'
    ) {
      let body;

      switch (service) {
        case 'jobdiva':
          body = new IntegrationsJobDiva({
            id
          }).apiData;
          break;

        case 'target_recruit':
        case 'erecruit':
          body = {
            data: {
              attributes: {
                id,
                type
              }
            }
          };
          break;

        default:
          throw new Error('Upload integrations documentation: Bad service');
      }

      return this._http.post(
        `${environment.SERVER_ENDPOINT}/api/services/${service}/documents`,
        body
      );
    } else {
      // Bullhorn
      // TO DO REFACTOR THAT SHIT
      return this._http.put(
        `${environment.SERVER_ENDPOINT}/api/${service}/entity/${id}/documents`,
        {
          entity_type: type,
          // TO DO clean this variable later if we want to upload resume and candidate report
          type: 'candidates'
        }
      );
    }
  }
}
