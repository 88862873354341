import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '@environment/environment';
import { IActivity } from '@shared/interfaces/activity.interface';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IEmailActivity } from '@shared/interfaces/email-activity.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { ITextActivity } from '@shared/interfaces/text-activity.interface';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';
import { Activity } from '@shared/models/activity.model';
import { Candidate } from '@shared/models/candidate.model';
import { EmailActivity } from '@shared/models/email-activity.model';
import { Reference } from '@shared/models/reference.model';
import { Reminder } from '@shared/models/reminder.model';
import { TextActivity } from '@shared/models/text-activity.model';
import { Typeahead } from '@shared/models/typeahead.model';
import { CandidatesService } from '@shared/services/candidates.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService implements IPaginable {
  public referencesSource = new Subject();
  public referencesCalled$ = this.referencesSource.asObservable();

  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/references`
  );
  private readonly ENDPOINT_V2: string = String(
    `${environment.ENDPOINTS.BACK_END.V2}/references`
  );
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/references`
  );

  constructor(
    private readonly _http: HttpClient,
    private readonly _candidates: CandidatesService,
    private readonly _router: Router
  ) {}

  public refresh(r?: IReference) {
    this.referencesSource.next(r);
  }

  public addCandidateReference(reference: IReference) {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V1, reference.apiData)
      .pipe(map((res: IAPIData) => Reference.fromAPI(res)));
  }

  public approveReference(id: string) {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}/approve`)
      .pipe(map((res: IAPIData) => Reference.fromAPI(res)));
  }

  public delete(id: any) {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V1}/${id}`)
      .pipe(map((res: IAPIData) => Reference.fromAPI(res)));
  }

  public find(id: string) {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}`)
      .pipe(map((res: IAPIData) => Reference.fromAPI(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    sort?: ISort
  ): Observable<IReference[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (sort) {
      const sortValue = sort['isReverse'] ? `-${sort['value']}` : sort['value'];
      params = params.set('sort', sortValue);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V2, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => Reference.fromAPIArray(res))
    );
  }

  public getAnalytics(q: any): Observable<any> {
    let params: HttpParams = new HttpParams();

    params = params.set('scope', q.scope);
    params = params.set('start_date', q.start_date);
    params = params.set('end_date', q.end_date);

    return this._http.get<IAPIArrayData>(`${this.ENDPOINT_V2}/analytics`, {
      params
    });
  }

  public getReferenceActivities(id: APIuuid): Observable<IActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/activities`)
      .pipe(map((res: IAPIArrayData) => Activity.fromAPIArray(res)));
  }

  public getReferencerCandidates(id: string) {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V2}/${id}/candidates`)
      .pipe(map((res: IAPIArrayData) => Candidate.fromAPIArray(res)));
  }

  public getReferenceReminders(id: APIuuid): Observable<IReminder[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/reminders`)
      .pipe(map((res: IAPIArrayData) => Reminder.fromAPIArray(res)));
  }

  public getReferenceEmailActivities(
    id: APIuuid
  ): Observable<IEmailActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/email_activities`)
      .pipe(map((res: IAPIArrayData) => EmailActivity.fromAPIArray(res)));
  }

  public getReferenceTextActivities(id: APIuuid): Observable<ITextActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/text_activities`)
      .pipe(map((res: IAPIArrayData) => TextActivity.fromAPIArray(res)));
  }

  public patch(r: IReference) {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${r.id}`, r.apiData)
      .pipe(map((res: IAPIData) => Reference.fromAPI(res)));
  }

  public postRequest(rr: IReferenceRequest) {
    return this._http
      .post<IAPIData>(
        `${environment.ENDPOINTS.BACK_END.V1}/reference_requests`,
        rr.apiData
      )
      .pipe(map((res: IAPIData) => res));
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const candidateId = route?.parent?.parent?.params?.id;
    return this._candidates.find(candidateId).pipe(
      catchError(() => {
        this._router.navigate(['/candidates']);
        return of(null);
      })
    );
  }

  public searchTypeahead(search: {
    data: { search_criteria: string };
  }): Observable<ITypeahead> {
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V2}/search`, search)
      .pipe(map((res: IAPIData) => Typeahead.fromAPI(res)));
  }

  public sendReferenceReminder(id: string) {
    return this._http
      .post(
        `${environment.ENDPOINTS.BACK_END.V1}/reminders/references/${id}`,
        {}
      )
      .pipe(map((res: any) => res));
  }
}
