import { Component, Input, OnInit } from '@angular/core';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {
  @Input() public pagination: PaginationService<any>;
  public totalEntries: number;

  constructor() {}

  ngOnInit(): void {
    this.totalEntries = 0;
    this.pagination.entries.subscribe((totalEntries: number) => {
      this.totalEntries = totalEntries;
    });

    this.pagination.checkAndLoadPage(this.pagination.currentPage);
  }

  public onPageChange(event: any) {
    this.pagination.checkAndLoadPage(event.page);
  }
}
