import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {
  public updatesSource = new Subject();
  public updatesCalled$ = this.updatesSource.asObservable();

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly swUpdate: SwUpdate
  ) {
    const hourly$ = interval(60 * 60 * 1000);

    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );

    concat(appIsStable$, hourly$).subscribe(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
    });
  }

  public checkUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then((value) => {
        this.updatesSource.next(value);
      });
    }
  }
}
