import {
  APIDate,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIErrorObject
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';

export class Document implements IDocument {
  public static readonly TYPE: string = String('ActiveStorage::Attachment');

  // Attributes
  id: APIuuid;
  filename: string;
  content_type: string;
  url: string;
  blob: any;

  // After upload only
  candidates?: ICandidate[];
  errors: IAPIErrorObject[];
  reference_requests: IReferenceRequest[];
  statistics?: {
    duplicate_entries: number;
    new_entries: number;
    total_entries: number;
  };

  isDownloading?: boolean;

  created_at: APIDate;

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: Document.TYPE,
        attributes: {
          filename: this.filename,
          content_type: this.content_type,
          url: this.url,
          blob: this.blob,

          candidates: this.candidates,
          reference_requests: this.reference_requests,
          statistics: this.statistics,

          errors: this.errors
        }
      }
    } as IAPIData;

    return data;
  }

  constructor(partial: Partial<IDocument>) {
    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): IDocument {
    const data = res.data;

    if (
      !(
        (data && data.type === Document.TYPE) ||
        data.type === 'CSV' ||
        data.type === 'PDF'
      )
    ) {
      throw new Error(`There was a problem parsing ${Document.TYPE} API data`);
    }

    const document = new Document({
      id: data.id,
      ...data.attributes
    });

    return document;
  }

  public static fromAPIArray(res: IAPIArrayData): IDocument[] {
    const dataArray = res.data;
    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Document.TYPE)) {
        throw new Error(
          `There was a problem parsing ${Document.TYPE} API data`
        );
      }

      const document = new Document({
        id: data.id,
        ...data.attributes
      });

      return document;
    });
  }
}
