import { buildFeedback } from '@shared/builders/feedback.helper';
import { IAnswerSet } from '@shared/interfaces/answer-set.interface';
import {
  APIDatetime,
  APIDate,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISetting } from '@shared/interfaces/setting.interface';

export class Feedback implements IFeedback {
  public static readonly TYPE: string = String('Feedback');

  // Relationships
  public candidate: ICandidate;
  public employer: IEmployer;
  public recruiter: Partial<IRecruiter>;
  public question_set: IQuestionSet;
  public requested_by: IRecruiter;
  public answer_set: IAnswerSet;

  public id: string;

  public identifiable: ICandidate | IEmployer | IRecruiter;
  public recipient: ICandidate;
  public is_reminders_enabled: boolean;
  public reminder: IReminder;
  public filled_at: APIDatetime;
  public created_at: APIDatetime;
  public updated_at: APIDatetime;
  public pages: IPage[];
  public score: number;
  public scheduled_date: APIDate;
  public notes: string;

  public identifiable_type: string;

  public settings?: ISetting;

  constructor(partial: Partial<IFeedback>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Feedback.TYPE,
        id: this.id,
        attributes: {
          filled_at: this.filled_at,
          identifiable_type: this.identifiable_type,
          scheduled_date: this.scheduled_date,
          notes: this.notes
        },
        relationships: {
          candidate: {
            id: this.candidate?.id
          } as IAPIRelationship,
          employer: {
            id: this.employer?.id
          } as IAPIRelationship,
          recruiter: {
            id: this.recruiter?.id
          } as IAPIRelationship,
          question_set: {
            id: this.question_set?.id
          } as IAPIRelationship,
          settings: {
            id: this.settings?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  public get pillsType(): any {
    let className;

    switch (this.identifiable_type) {
      case 'Reference':
        className = 'v-btn-primary';
        break;

      case 'Employer':
        className = 'v-btn-success';
        break;

      case 'Recruiter':
        className = 'v-btn-warning';
        break;

      case 'Candidate':
      default:
        className = 'v-btn-secondary';
        break;
    }

    return {
      label: this.identifiable_type,
      class: className
    };
  }

  public static fromAPI(res: IAPIData): IFeedback {
    const data = res.data;

    if (!(data && data.type === Feedback.TYPE)) {
      throw new Error(`There was a problem parsing ${Feedback.TYPE} API data`);
    }

    const feedback = new Feedback({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    const relationships = data.relationships;

    return buildFeedback(feedback, relationships, included);
  }

  public static fromAPIArray(res: IAPIArrayData): IFeedback[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Feedback.TYPE)) {
        throw new Error(
          `There was a problem parsing ${Feedback.TYPE} API data`
        );
      }

      const feedback = new Feedback({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      return buildFeedback(feedback, relationships, included);
    });
  }
}
