import {
  APIDate,
  APIDatetime,
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { IVerificationRequest } from '@shared/interfaces/verification-request.interface';
import { Candidate } from '@shared/models/candidate.model';
import { HiringFirm } from '@shared/models/hiring-firm.model';

export class VerificationRequest
  extends APIResource
  implements IVerificationRequest {
  public static readonly TYPE: string = String('VerificationRequest');

  id: APIuuid;

  is_active: boolean;
  filled_at: APIDatetime;
  filled: boolean;

  question_set: IQuestionSet;
  candidate?: ICandidate;
  hiringFirm?: IHiringFirm;
  notes: string;
  reminder: IReminder;
  language: string;
  minimum_verifications: number;

  created_at: APIDate;
  updated_at: APIDate;

  get label() {
    return 'Employment verification';
  }

  constructor(partial: Partial<IVerificationRequest>) {
    super();

    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IVerificationRequest {
    const data = res.data;
    const included = res.included;
    const relationships = data.relationships;

    if (!(data && data?.type === VerificationRequest.TYPE)) {
      throw new Error(
        `There was a problem parsing ${VerificationRequest.TYPE} API data`
      );
    }

    const vr = new VerificationRequest({
      id: data.id,
      ...data.attributes
    });

    const candidate =
      relationships.candidate &&
      included &&
      included.find(
        (i: IAPIDataObject) =>
          i.id === (relationships.candidate as IAPIRelationship).id
      );

    if (!!candidate) {
      vr.candidate = Candidate.fromAPI({
        data: candidate,
        included
      });
    }

    const hiringFirm =
      included &&
      included.find(
        (i: IAPIDataObject) =>
          i.id === (relationships.hiring_firm as IAPIRelationship).id
      );

    if (!!hiringFirm) {
      vr.hiringFirm = HiringFirm.fromAPI({
        data: hiringFirm,
        included
      });
    }

    return vr;
  }

  static fromAPIArray(res: IAPIArrayData): IVerificationRequest[] {
    const dataArray = res.data;
    return dataArray.map(
      (data: IAPIDataObject) =>
        new VerificationRequest({
          id: data.id,
          ...data.attributes
        })
    );
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: VerificationRequest.TYPE,
        attributes: {
          notes: this.notes,
          is_active: this.is_active,
          minimum_verifications: this.minimum_verifications,
          language: this.language
        },
        relationships: {
          candidate: this.candidate?.apiRelationship
        }
      }
    } as IAPIData;

    return data;
  }
}
