import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { ISkill } from '@shared/interfaces/skill.interface';

export class Skill implements ISkill {
  public static readonly TYPE: string = String('Skill');

  id: APIuuid;

  industry: string;
  technology: string;
  social: string;

  constructor(partial: Partial<ISkill>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): ISkill {
    const data = res.data;
    if (!(data && data.type === Skill.TYPE)) {
      throw new Error(`There was a problem parsing ${Skill.TYPE} API data`);
    }

    return new Skill({
      id: data.id,
      ...data.attributes
    });
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: Skill.TYPE,
        attributes: {
          industry: this.industry,
          technology: this.technology,
          social: this.social
        }
      }
    } as IAPIData;

    return data;
  }
}
