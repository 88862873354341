import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string, args?: string): string {
    const m = moment(date, moment.ISO_8601, true);
    if (m.isValid()) {
      return m.format(args || 'MMM DD, YYYY');
    } else {
      return date;
    }
  }
}
