import { QuestionCategory, QuestionType } from '@shared/enums/question.enum';
import { getSentimentScore } from '@shared/helpers/utils.helper';
import { IAnswer } from '@shared/interfaces/answer.interface';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';

export class Answer implements IAnswer {
  id: APIuuid;
  answer_set_id: string;
  category: QuestionCategory;
  type: QuestionType;
  question_group: string;
  comment: string;
  scale: number;
  language: string;
  question: string;
  sentiment_score: number;
  comment_sentiment_score: number;
  value: string;
  not_applicable?: boolean;
  question_id: APIuuid;

  get answerSentiment(): any {
    if (!this.sentiment_score) {
      return;
    }

    return getSentimentScore(+this.sentiment_score);
  }

  get commentSentiment(): any {
    if (!this.comment_sentiment_score) {
      return;
    }

    return getSentimentScore(+this.comment_sentiment_score);
  }

  get arrayValues(): any[] {
    if (
      this.type === QuestionType.Checkbox ||
      this.type === QuestionType.Ranking
    ) {
      return this.value.split(',').filter((a: any) => a);
    } else if (this.type === QuestionType.Matrix) {
      return JSON.parse(this.value);
    }

    return null;
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: 'Answer',
        id: this.id,
        attributes: {
          question: this.question,
          value: this.value,
          question_group: this.question_group,
          category: this.category,
          scale: this.scale,
          type: this.type,
          comment: this.comment,
          language: this.language
        }
      }
    } as IAPIData;
  }

  constructor(partial: Partial<IAnswer>) {
    Object.assign(this, partial);
  }

  public static fromAPIArray(res: IAPIArrayData): IAnswer[] {
    const dataArray = res.data;
    return dataArray.map((data: IAPIDataObject) => {
      return new Answer({
        id: data.id,
        ...data.attributes
      });
    });
  }
}
