import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html'
})
export class CardComponent implements OnInit, OnDestroy {
  @ViewChild('card', { static: false })
  public headerEl: ElementRef;

  @Input() public isHeaderLeft = Boolean(true);

  public isStickyTop: boolean;

  private readonly constructorName: string = String(this.constructor.name);

  private sidebarScrollSubscription: Subscription;

  constructor(
    private readonly _logger: LoggerService,
    private readonly _sidebar: SidebarService
  ) {}

  ngOnInit(): void {
    const url = 'Sidebar scroll';
    this.sidebarScrollSubscription =
      this._sidebar.scrollSidebarCalled$.subscribe(
        () => {
          // this._logger.info(this.constructorName, url, res);

          if (!!this.headerEl) {
            const rect = this.headerEl.nativeElement.getBoundingClientRect();
            const top = rect.top;

            this.isStickyTop = top === 0;
          }
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );
  }

  ngOnDestroy(): void {
    this.sidebarScrollSubscription?.unsubscribe();
  }
}
