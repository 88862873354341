import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html'
})
export class ModalsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('template', { static: true })
  public template: TemplateRef<any>;

  @Input() public title: string;
  @Input() public description: string;
  @Input() public config = {};
  @Input() public showFooter = Boolean(true);
  @Input() public openModal: Subject<any>;
  @Input() public closeModal: Subject<any>;
  @Input() public deleteKey: string;
  @Input() public invalidFormGroup: boolean;
  @Input() public isCloseEnabled = Boolean(true);
  @Input() public isButtonsDisabled: boolean;
  @Input() public submitKey: string;
  @Input() public warningKey: string;
  @Input() public backKey: string;
  @Output() public goBack: EventEmitter<any> = new EventEmitter();
  @Input() public isShaking = Boolean(false);

  public modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  // tslint:disable semicolon
  @Input() public onDismiss: (arg0: any) => void = () => {};
  // tslint:disable semicolon
  @Input() public whenModalClose: (arg0: any) => void = () => {};

  ngOnInit(): void {
    this.openModal?.subscribe(() => this.open(this.template));
    this.closeModal?.subscribe(() => this.close());
  }

  ngOnDestroy(): void {
    this.openModal?.unsubscribe();
    this.closeModal?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const element: HTMLCollectionOf<Element> =
      document.getElementsByTagName('modal-container');

    if (element && changes) {
      const modal: Element = element[0];
      const classList = modal && modal.classList;

      if (classList && changes.isShaking && changes.isShaking.currentValue) {
        classList.add('shake');
      } else if (modal && modal.className.indexOf('shake') > -1) {
        classList.remove('shake');
      }
    }
  }

  public close() {
    this.modalRef.hide();
  }

  public open(template: TemplateRef<any>) {
    this.modalService.onHidden.subscribe((reason: string) => {
      this.onDismiss(reason);
    });

    this.modalService.onShown.subscribe(() => {
      const inputFocusable = document.querySelector(
        'modal-container input[appAutoFocus]'
      ) as HTMLElement;

      if (inputFocusable) {
        inputFocusable.focus();
      }
    });

    this.modalRef = this.modalService.show(template, {
      ...this.config,
      ignoreBackdropClick: true
    });
  }
}
