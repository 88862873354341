import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnswersService {
  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/answers`
  );

  constructor(private readonly _http: HttpClient) {}

  public patch(data): Observable<any> {
    return this._http.patch(`${this.ENDPOINT_V1}/${data.data.id}`, data);
  }
}
