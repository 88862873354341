import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IVerificationRequest } from '@shared/interfaces/verification-request.interface';
import { VerificationRequest } from '@shared/models/verification-request.model';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { VerificationRequestsService } from '@shared/services/verification-requests.service';

@Component({
  selector: 'app-modals-verification-requests',
  templateUrl: './modals.verification-requests.component.html'
})
export class ModalsVerificationRequestsComponent
  extends ModalDirective<IVerificationRequest>
  implements OnInit, OnDestroy {
  public title = String('MODALS.VERIFICATION_REQUESTS.TITLE');
  public description = String('MODALS.VERIFICATION_REQUESTS.DESCRIPTION');

  public params: any = {
    type: 'candidate'
  };

  public entry: FormGroup;
  public errors: any = [];
  public isButtonsDisabled = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public categories: FormArray;
  public candidate: ICandidate;
  public minVerificationsCountArray: number[] = Array(10);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,
    private readonly _verificationRequests: VerificationRequestsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((c: ICandidate) => {
      if (c) {
        this.candidate = c;

        this.entry.patchValue({ candidate: c.id });
      }
    });
  }

  ngOnDestroy(): void {}

  public onDismiss() {
    super.onDismiss();

    this.isButtonsDisabled = false;
    this.errors = [];
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.errors = [];
      this.isButtonsDisabled = true;

      const url = 'POST /verification_requests';
      const data = new VerificationRequest({
        ...value,
        candidate: this.candidate
      }).apiData;

      this._verificationRequests.post(data).subscribe(
        (res) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success('Verifcation request requested');

          this.isButtonsDisabled = false;
          this.resetModal();
        },
        (err) => {
          this._logger.error(this.constructorName, url, err);

          this.errors = err;
          this.isButtonsDisabled = false;
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      candidate: ['', [Validators.required]],
      minimum_verifications: [1, [Validators.required]],
      notes: ['']
    });
  }
}
