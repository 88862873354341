import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import {
  Providers,
  backgroundCheckCategories
} from '@shared/helpers/background-checking.helper';
import { IBackgroundChecking } from '@shared/interfaces/background-checking.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { BackgroundChecking } from '@shared/models/background-checking';
import { BackgroundCheckingsService } from '@shared/services/background-checkings.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-background-checking-requests',
  templateUrl: './modals.background-checking-requests.component.html'
})
export class ModalsBackgroundCheckingRequestsComponent
  extends ModalDirective<IBackgroundChecking>
  implements OnInit, OnDestroy
{
  @SessionStorage() public settings: ISetting;

  public title = String('MODALS.BACKGROUND_CHECKING_REQUESTS.TITLE');
  public description = String(
    'MODALS.BACKGROUND_CHECKING_REQUESTS.DESCRIPTION'
  );
  public selectedTab = 'Canada'; // Default selected tab

  public entry: FormGroup;
  public errors: any = [];
  public isButtonsDisabled = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');

  public activeTabs = {
    Canada: false,
    UnitedStates: false
  };

  public backgroundCheckCategories: any[] =
    backgroundCheckCategories[Providers.Mintz];

  private readonly constructorName: string = String(this.constructor.name);

  private candidate: ICandidate;

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _backgroundCheckings: BackgroundCheckingsService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activateTabs();

    this.openModal.subscribe((c: ICandidate) => {
      if (!!c) {
        this.candidate = c;
      }

      this.backgroundCheckCategories.map((r) => (r.isChecked = false));
    });

    this.createForm();
  }

  ngOnDestroy(): void {}

  public onDismiss() {
    super.onDismiss();

    this.isButtonsDisabled = false;
    this.errors = [];
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.errors = [];
      this.isButtonsDisabled = true;

      const url = 'POST /background_checkings';
      this._backgroundCheckings
        .post(
          new BackgroundChecking({
            ...value,
            ...{ provider: this.getProvider() },
            candidate: this.candidate
          }).apiData
        )
        .subscribe(
          (res) => {
            this._logger.info(this.constructorName, url, res);

            this._toast.success('Background screening requested');

            this.isButtonsDisabled = false;
            this.resetModal();
          },
          (err) => {
            this._logger.error(this.constructorName, url, err);

            this.errors = err;
            this.isButtonsDisabled = false;
          }
        );
    }
  }

  public onSelect(data: TabDirective): void {
    this.selectedTab = data.heading;
    if (this.selectedTab === 'Canada') {
      this.backgroundCheckCategories =
        backgroundCheckCategories[Providers.Mintz];
    } else {
      this.backgroundCheckCategories =
        backgroundCheckCategories[Providers.ValidityScreening];
    }
  }

  protected activateTabs() {
    if (
      this.settings.is_plugins_background_checking_enabled &&
      this.settings.plugins_json.validity_screening_plugin
    ) {
      this.activeTabs.Canada = true;
      this.activeTabs.UnitedStates = true;

      return;
    }

    if (this.settings.is_plugins_background_checking_enabled) {
      this.activeTabs.Canada = true;

      return;
    }

    if (this.settings.plugins_json.validity_screening_plugin) {
      this.selectedTab = 'United States';
      this.activeTabs.UnitedStates = true;
      this.backgroundCheckCategories = backgroundCheckCategories[Providers.ValidityScreening];

    }
  }

  protected getProvider() {
    return this.selectedTab === 'Canada'
      ? Providers.Mintz
      : Providers.ValidityScreening;
  }

  protected createForm() {
    this.entry = this._fb.group({
      category: ['', [Validators.required]]
    });
  }
}
