import { phoneFormat } from '@shared/builders/phone-formart.helper';
import {
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IMetadatum } from '@shared/interfaces/metadatum.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Candidate } from '@shared/models/candidate.model';
import { Feedback } from '@shared/models/feedback.model';
import { HiringFirm } from '@shared/models/hiring-firm.model';
import { Metadatum } from '@shared/models/metadatum.model';
import { QuestionSet } from '@shared/models/question-set.model';
import { User } from '@shared/models/user.model';
import { CountryCode, PhoneNumber } from 'libphonenumber-js';

export class Recruiter extends APIResource implements IRecruiter {
  public static readonly TYPE: string = String('Recruiter');

  id: APIuuid;
  user: IUser;
  hiring_firm: IHiringFirm | IAPIRelationship;
  candidates: (ICandidate | IAPIRelationship)[];
  hiring_firm_id: APIuuid;
  score: number;
  notifications: boolean;
  phone: PhoneNumber;
  country_code: CountryCode;

  is_root: boolean;
  is_recruiter: boolean;
  is_default: boolean;
  is_active = Boolean(true);
  is_send_email_enabled?: boolean;

  completed_references?: number;
  total_references?: number;
  total_candidates?: number;
  references?: IReference[];
  feedbacks: IFeedback[];
  metadatum: IMetadatum;

  constructor(partial: Partial<IRecruiter>) {
    super();

    Object.assign(this, partial);
  }

  public get client_id(): string {
    return this.user?.client_id;
  }

  public get ip_address(): string {
    return this.metadatum && this.metadatum.ip_address;
  }

  public get reference_completed_percentage(): number {
    if (this.completed_references && this.total_references) {
      return Math.floor(
        (this.completed_references / this.total_references) * 100
      );
    } else {
      return 0;
    }
  }

  get full_name(): string {
    return this.user?.full_name || '-';
  }

  get user_email(): string {
    return this.user?.email || '-';
  }

  get last_connected_at(): string | null {
    return this.user?.last_connected_at;
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  get name_label(): string {
    return this.user?.full_name || this.user?.email || '';
  }

  get pillsRole(): any {
    let label = 'Recruiter';
    let className = 'v-btn-primary';

    const roles = this.user.roles;

    if (roles.includes('root_recruiter')) {
      label = 'Root';
      className = 'v-btn-success';
    } else if (roles.includes('supervisor')) {
      label = 'Supervisor';
      className = 'v-btn-warning';
    }

    return {
      label,
      class: className
    };
  }

  public get apiData(): IAPIData {
    return {
      data: {
        id: this.id,
        type: Recruiter.TYPE,
        attributes: {
          id: this.id,
          hiring_firm_id: this.hiring_firm_id,
          is_active: this.is_active,
          is_root: this.is_root,
          is_default: this.is_default,
          is_send_email_enabled: this.is_send_email_enabled,
          notifications: this.notifications
        },
        relationships: {
          user: {
            type: 'User',
            id: this.user.id,
            attributes: {
              email: this.user.email,
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              phone: this.user.phone,
              country_code: this.user.country_code,
              last_connected_at: this.user.last_connected_at
            }
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  static fromAPI(
    res: IAPIData,
    optionalData: { user?: IUser } = {}
  ): IRecruiter {
    const data = res.data;
    const included = res.included;
    const relationships = data.relationships;

    if (!(data && data.type === Recruiter.TYPE)) {
      throw new Error(`There was a problem parsing ${Recruiter.TYPE} API data`);
    }

    const recruiter: IRecruiter = new Recruiter({
      id: data.id,
      ...data.attributes
    });

    if (included) {
      recruiter.user =
        optionalData.user ||
        User.fromAPI({
          data:
            included &&
            included.find(
              (i: IAPIDataObject) =>
                i.id === (relationships.user as IAPIRelationship).id
            )
        });

      const hiringFirmData = included.find(
        (i: IAPIDataObject) =>
          i.type === 'HiringFirm' &&
          i.id === (relationships.hiring_firm as IAPIRelationship).id
      );

      if (hiringFirmData) {
        recruiter.hiring_firm = new HiringFirm(hiringFirmData.attributes);
      }

      recruiter.candidates = Candidate.fromAPIArray({
        data: included.filter((i: IAPIDataObject) => i.type === 'Candidate')
      });

      recruiter.feedbacks = Feedback.fromAPIArray({
        data: included.filter((i: IAPIDataObject) => i.type === 'Feedback')
      });

      if (recruiter.feedbacks) {
        recruiter.feedbacks.map((f: IFeedback) => {
          const feebdack = included.find((i: IAPIDataObject) => i.id === f.id);
          const qs = included.find(
            (i: IAPIDataObject) =>
              i.id ===
              (feebdack.relationships.question_set as IAPIRelationship).id
          );

          if (qs) {
            f.question_set = new QuestionSet(qs.attributes);

            if (f.identifiable_type === 'Recruiter') {
              const recipient = included.find(
                (i: IAPIDataObject) =>
                  i.id ===
                  (feebdack.relationships.recipient as IAPIRelationship).id
              );

              if (recipient) {
                f.recipient = new Candidate(recipient.attributes);
              }
            }
          }
        });
      }

      const metadatum =
        relationships.metadatum &&
        included &&
        included.find(
          (i: IAPIDataObject) =>
            i.id === (relationships.metadatum as IAPIRelationship).id
        );

      if (metadatum) {
        recruiter.metadatum = Metadatum.fromAPI({
          data: metadatum,
          included
        });
      }
    }

    return recruiter;
  }

  static fromAPIArray(res: IAPIArrayData): IRecruiter[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      const relationships = data.relationships;
      const recruiter: IRecruiter = new Recruiter({
        id: data.id,
        ...data.attributes
      });

      recruiter.candidates = relationships.candidates as IAPIRelationship[];
      recruiter.user = User.fromAPI({
        data:
          included &&
          included.find(
            (i: IAPIDataObject) =>
              i.id === (relationships.user as IAPIRelationship).id
          )
      });

      const feedbacks = included?.filter(
        (i: IAPIDataObject) => i.type === 'Feedback'
      );

      recruiter.feedbacks = !!feedbacks
        ? Feedback.fromAPIArray({
            data: included?.filter((i: IAPIDataObject) => i.type === 'Feedback')
          })
        : [];

      return recruiter;
    });
  }
}
