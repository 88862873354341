import { Directive, Input, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { noop, Subject } from 'rxjs';

@Directive()
export abstract class ModalDirective<T> {
  @ViewChild('entityForm', { static: false })
  public entityForm: FormGroupDirective;

  @Input() public openModal: Subject<any>;
  @Input() public afterSubmit: (arg0: T) => any = noop;

  public errors: IAPIErrorObject[] = [];
  public closeModal: Subject<any> = new Subject<any>();
  public abstract title: string;

  constructor() {
    this.whenModalClose = this.whenModalClose.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  public onDismiss() {
    this.errors = [];
    this.createForm();
  }

  public resetForm() {
    this.entityForm.reset();
    this.createForm();
    this.errors = [];
  }

  public resetModal(entity?: any) {
    this.resetForm();
    this.closeModal.next();
    this.afterSubmit(entity);
  }

  public whenModalClose(type?: string) {
    this.errors = [];
    this.entityForm.ngSubmit.emit(type);
  }

  public abstract onSubmit(entity: any): void;

  protected abstract createForm(): void;
}
