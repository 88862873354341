import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import {
  EmailValidation,
  EmailValidationWarning
} from '@shared/models/email-validation.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailValidationService {
  public validationWarningSubject =
    new BehaviorSubject<EmailValidationWarning | null>(null);
  public emailValidationWarning$ = this.validationWarningSubject.asObservable();

  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/validations/email`
  );

  constructor(private readonly _http: HttpClient) {}

  public resetEmailWarnings(): void {
    this.validationWarningSubject.next(null);
  }

  public validateEmail(email: string): Observable<any> {
    if (!email) {
      return of(null);
    }

    return this._http
      .get<IAPIData>(this.ENDPOINT_V3, { params: { email } })
      .pipe(
        map((res: any) => EmailValidation.fromAPI(res)),
        tap((validationData: EmailValidation) => {
          const warning = validationData.warning;
          if (
            warning !== null &&
            (warning.typo_suggestion || warning.has_deliverability_warning)
          ) {
            this.validationWarningSubject.next(validationData.warning);
          } else {
            this.validationWarningSubject.next(null);
          }
        })
      );
  }
}
