import { phoneFormat } from '@shared/builders/phone-formart.helper';
import {
  APIDatetime,
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IEducation } from '@shared/interfaces/education.interface';
import { IExperience } from '@shared/interfaces/experience.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { IVerification } from '@shared/interfaces/verification.interface';
import { Education } from '@shared/models/education.model';
import { Experience } from '@shared/models/experience.model';
import { User } from '@shared/models/user.model';
import { PhoneNumber } from 'libphonenumber-js';

export class Verification extends APIResource implements IVerification {
  public static readonly VERIFIABLE_TYPES: string[] = [
    'Experience',
    'Education'
  ];

  id: APIuuid;
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  title: string;
  phone: PhoneNumber;
  country_code: string;
  is_verified: boolean;
  comment: string;
  is_active: boolean;
  filled_at: APIDatetime;
  filled: boolean;
  created_at: APIDatetime;
  is_outdated: boolean;
  reason: string;
  employment_status: string;
  verifiable_type: string;
  verifiable_id: string;
  verifiable: IExperience | IEducation;
  requester: IUser;

  constructor(partial: Partial<IVerification>) {
    super();

    Object.assign(this, partial);
  }

  public get full_name(): string {
    return this.first_name || this.last_name
      ? `${this.first_name} ${this.last_name}`
      : '';
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  public get methods(): any[] {
    return [this.phone, this.email];
  }

  public get pillsStatus(): any {
    let label = 'Verifying';
    let className = 'lnr-shield v-text-warning';

    if (this.is_verified) {
      label = 'Verified';
      className = 'lnr-shield-check v-text-success';
    } else if (!this.is_active && !this.is_verified) {
      label = 'Incorrect';
      className = 'lnr-cross v-text-danger';
    }

    return {
      label,
      class: className
    };
  }

  static fromAPI(res: IAPIData): IVerification {
    const data = res.data;
    const included = res.included;

    if (
      !(
        data &&
        Verification.VERIFIABLE_TYPES.indexOf(
          data.attributes.verifiable_type
        ) !== -1
      )
    ) {
      throw new Error('There was a problem parsing Verification API data');
    }
    const verification = new Verification({
      id: data.id,
      ...data.attributes
    });

    if (included) {
      const verifiable = included.find(
        (i: IAPIDataObject) =>
          i.type === verification.verifiable_type &&
          i.id === verification.verifiable_id
      );

      if (verifiable) {
        verification.verifiable =
          verifiable.type === 'Experience'
            ? Experience.fromAPI({ data: verifiable })
            : Education.fromAPI({ data: verifiable });
      }

      const userData = included.find((i: IAPIDataObject) => i.type === 'User');

      verification.requester = new User({
        ...userData,
        ...userData.attributes
      });
    }

    return verification;
  }

  static fromAPIArray(res: IAPIArrayData): IVerification[] {
    const dataArray = res.data;
    return dataArray.map((data: IAPIDataObject) => {
      const verification = new Verification({
        id: data.id,
        ...data.attributes
      });

      return verification;
    });
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: 'Verification',
        attributes: {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          title: this.title,
          company_name: this.company_name,
          phone: this.phone,
          country_code: this.country_code,
          is_verified: this.is_verified,
          is_outdated: this.is_outdated,
          verifiable_type: this.verifiable_type,
          employment_status: this.employment_status,
          reason: this.reason,
          comment: this.comment
        },
        relationships: {}
      }
    } as IAPIData;

    return data;
  }
}
