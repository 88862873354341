import { ICountry } from '@shared/interfaces/country.interface';

export class Country implements ICountry {
  public initial: string;
  public phoneCode: string;
  public label: {
    en: string;
    es: string;
    fr: string;
  };

  constructor(partial: Partial<ICountry>) {
    Object.assign(this, {
      ...partial,
      phoneCode: partial?.phoneCode?.replace(/\s/g, '')
    });
  }
}
