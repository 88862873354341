import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IReferenceContact } from '@shared/interfaces/reference-contact.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { ReferenceContact } from '@shared/models/reference-contact.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferenceContactsService implements IPaginable {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/reference_contacts`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: any): Observable<any> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => ReferenceContact.fromAPI(res)));
  }

  public find(id: string): Observable<any> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => ReferenceContact.fromAPI(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    sort?: ISort
  ): Observable<IReferenceContact[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (sort) {
      const sortValue = sort['isReverse'] ? `-${sort['value']}` : sort['value'];
      params = params.set('sort', sortValue);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V3, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => ReferenceContact.fromAPIArray(res))
    );
  }

  public patch(r: IReferenceContact): Observable<any> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${r.id}`, r.apiData)
      .pipe(map((res: IAPIData) => ReferenceContact.fromAPI(res)));
  }

  public post(rr: IReferenceContact): Observable<any> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, rr.apiData)
      .pipe(map((res: IAPIData) => ReferenceContact.fromAPI(res)));
  }
}
