import { AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from '@environment/environment';

export class PasswordValidator {
  static MatchPassword(
    passwordFieldName: string,
    confirmationFieldName: string
  ) {
    return (AC: AbstractControl) => {
      if (!AC) {
        return null;
      }

      const password = AC.get(passwordFieldName)?.value;
      const confirmation = AC.get(confirmationFieldName)?.value;

      AC.get(passwordFieldName)?.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });

      AC.get(confirmationFieldName)?.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });

      if ((password || confirmation) && password !== confirmation) {
        return AC.get(confirmationFieldName)?.setErrors({
          MatchPassword: true
        });
      } else {
        return null;
      }
    };
  }

  static ComplexityPassword(AC: AbstractControl): ValidationErrors | null {
    const value = AC?.value;
    if (!!value && environment.IS_PRODUCTION) {
      const hasValidLength = value.length >= 8;
      const hasAlphaNumericAndSpecCharacters = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(
        value
      );

      if (hasValidLength && hasAlphaNumericAndSpecCharacters) {
        return null;
      } else {
        return {
          PasswordComplexity: true
        };
      }
    }

    return null;
  }
}
