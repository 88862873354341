import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData } from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { HiringFirm } from '@shared/models/hiring-firm.model';
import { SessionStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicHiringFirmsService {
  @SessionStorage() public hiringFirm: IHiringFirm;

  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/public/hiring_firms`
  );

  constructor(private readonly _http: HttpClient) {}

  // Only used for whitelabelling
  public get(): Observable<IHiringFirm[]> {
    let params: HttpParams = new HttpParams();
    params = params.set(
      'settings[is_plugins_white_label_enabled]',
      String(true)
    );
    params = params.set(
      'settings[domain_name]',
      String(window.location.origin)
    );

    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V1}`, { params })
      .pipe(
        map((res: IAPIArrayData) => {
          const hfs = HiringFirm.fromAPIArray(res);

          if (hfs?.length > 0) {
            this.hiringFirm = hfs[0];
          }

          return hfs;
        })
      );
  }
}
