export enum TableColType {
  Text,
  Date,
  Score,
  Toggle,
  Pills,
  Button,
  Badge,
  Email,
  Currency,
  Links,
  List,
  Icon,
  Ago,
  Avatar
}
