import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IApplication } from '@shared/interfaces/application.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Application } from '@shared/models/application.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService implements IPaginable {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/applications`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: string): Observable<IApplication> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => Application.fromAPI(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void
  ): Observable<IApplication[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V3, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => Application.fromAPIArray(res))
    );
  }

  public patch(body: IAPIData): Observable<IApplication> {
    const id = body.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}`, body)
      .pipe(map((res: IAPIData) => Application.fromAPI(res)));
  }

  public post(body: IAPIData): Observable<IApplication> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => Application.fromAPI(res)));
  }
}
