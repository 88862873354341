export const referencesEnvironment = {
  NEW_RELIC: {
    ACCOUNT_ID: '2912775',
    TRUST_KEY: '2912775',
    APPLICATION_ID: '647982077',
    LICENCE_KEY: 'NRJS-752ac3a3ec296e6947b',
    AGENT_ID: '652925555'
  },
  GOOGLE_ANALYTICS: {
    domain: 'auto',
    GTM: 'GTM-KNFJWXT'
  },
  GOOGLE_SEARCH: {
    key: 'AIzaSyDRqBZS-N-WBmYqb5fK_Gdhxw3p-RUytiQ'
  }
};
