import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

export class UniquenessValidator {
  static field(fieldName: string, isUniquenessRequired: boolean = true) {
    return (AC: AbstractControl): ValidationErrors | ValidatorFn | null => {
      if (AC.parent && isUniquenessRequired) {
        const values: string[] = [];
        const fa: FormArray = AC.parent.parent as FormArray;

        if (fa) {
          const faControls = fa.controls;
          for (const fg of faControls as FormGroup[]) {
            if (fg === AC.parent) {
              continue;
            }

            const fgControls = fg.controls;

            if (!fgControls[fieldName].valid) {
              fgControls[fieldName].updateValueAndValidity({
                onlySelf: true,
                emitEvent: false
              });
            }

            values.push(fgControls[fieldName].value);
          }

          if (AC.value && values.indexOf(AC.value) !== -1) {
            return { [fieldName + 'DuplicationError']: true };
          }
        }
      }

      return null;
    };
  }
}
