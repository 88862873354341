export const VALIDITY_SCREENING_CONFIG = {
  PROD: {
    PRODUCTS: [
      // {
      //   id: 151654, // TODO: confirm with validity
      //   label: 'OmniSearch (all county jurisdictions)'
      // },
      // {
      //   id: 151655, // TODO: confirm with validity
      //   label: 'OmniSearch Plus (all county and federal jurisdictions)'
      // },
      // {
      //   id: 151662,
      //   label: 'Education Verification'
      // },
      {
        id: 152010,
        label: 'Omni Search + Quik Screen'
      },
      {
        id: 152011,
        label: 'Omni Search Plus + Quik Screen'
      }
    ]
  },
  DEV: {
    PRODUCTS: [
      {
        id: 135, // TODO: confirm with validity
        label: 'OmniSearch (all county jurisdictions)'
      },
      {
        id: 136, // TODO: confirm with validity
        label: 'OmniSearch Plus (all county and federal jurisdictions)'
      },
      {
        id: 137,
        label: 'Education Verification'
      }
    ]
  }
};
