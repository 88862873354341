import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { tableFields } from '@shared/helpers/table-fields.helper';
import { DocumentsService } from '@shared/services/documents.service';
import { LoggerService } from '@shared/services/logger.service';
import FileSaver from 'file-saver';
import moment from 'moment';

@Component({
  selector: 'app-modals-export',
  templateUrl: './modals.export.component.html'
})
export class ModalsExportComponent
  extends ModalDirective<any>
  implements OnInit
{
  public title = String('MODALS.EXPORT.TITLE');
  public description = String('MODALS.EXPORT.DESCRIPTION');
  public submitKey = String('MODALS.EXPORT.CTA');

  public entry: FormGroup;

  public isButtonsDisabled = Boolean(false);

  public tables: { label: string; type: string; fields: string[] }[] = [
    {
      label: 'answers',
      type: 'answers',
      fields: tableFields.answers
    },
    {
      label: 'candidates',
      type: 'candidates',
      fields: tableFields.candidates
    },
    {
      label: 'Reference answers per candidates',
      type: 'referenceAnswersPerCandidates',
      fields: tableFields.referenceAnswersPerCandidates
    },
    {
      label: 'Candidates per recruiter',
      type: 'candidatesPerRecruiters',
      fields: tableFields.candidatesPerRecruiters
    },
    {
      label: 'feedback',
      type: 'feedbacks',
      fields: tableFields.feedbacks
    },
    {
      label: 'Feedback Answers',
      type: 'feedback_answers',
      fields: tableFields.feedbackAnswers
    },
    {
      label: 'pre screening',
      type: 'pre_screenings',
      fields: tableFields.pre_screenings
    },
    {
      label: 'references',
      type: 'references',
      fields: tableFields.references
    }
  ];

  public fields: string[] = [];

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    minDate: moment(new Date(2018, 1, 1)).startOf('year').toDate(),
    maxDate: new Date(),
    showWeekNumbers: false,
    adaptivePosition: true
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _documents: DocumentsService,
    private readonly _logger: LoggerService
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  public selectFields() {
    const type = this.entry.get('type').value;
    const control = this.entry.controls['fields'];
    const fields = this.tables.find((t: any) => t.type === type);

    if (type) {
      control.setValidators([]);
    } else {
      control.setValidators([Validators.required]);
    }

    this.fields = !!fields ? fields.fields : [];
    control.updateValueAndValidity();
    this.entry.patchValue({ fields: '' });
  }

  public onSubmit({ valid, value }: FormGroup): void {
    if (valid) {
      this.isButtonsDisabled = true;

      const type = value.type === 'references' ? 'leads' : value.type;
      const fields = value.fields;
      const startDate = value.dateRange[0];
      const endDate = value.dateRange[1];

      const url = 'Download candidates CSV';
      this._documents
        .get({
          endpoint: 'csv',
          type,
          fields,
          filter: [
            {
              key: 'candidates',
              value: 'scope:all'
            }
          ],
          dates: {
            start_date: startDate,
            end_date: endDate
          }
        })
        .subscribe(
          (res: Blob) => {
            this._logger.info(this.constructorName, url, res);

            this.isButtonsDisabled = false;
            FileSaver.saveAs(res, `${value.type}.csv`);
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);

            this.isButtonsDisabled = false;
          }
        );
    }
  }

  public selectField(f: string) {
    let fields = this.entry.get('fields').value;

    const regex = new RegExp(`\\b${f}\\b`);
    if (regex.test(fields)) {
      fields = fields.replace(`${f},`, '');
    } else {
      fields += `${f},`;
    }

    this.entry.patchValue({ fields });
  }

  protected createForm() {
    this.entry = this._fb.group({
      type: ['', [Validators.required]],
      dateRange: new FormControl([
        moment().subtract(1, 'month').startOf('day').toDate(),
        moment().add(1, 'day').endOf('day').toDate()
      ]),
      fields: ['', [Validators.required]]
    });
  }
}
