import { IAPIRelationship } from '@shared/interfaces/api.interface';
import { ICustomer } from '@shared/interfaces/customer.interface';
import { Card } from '@shared/models/card.model';

export const buildCustomer = (
  customer: ICustomer,
  relationships: any,
  _included: any
): any => {
  customer.cards = Card.fromAPIArray({
    data:
      (relationships &&
        relationships.cards.filter((r: any) => r.type === 'Card')) ||
      ([] as IAPIRelationship[]),
    included: relationships.cards
  });

  return customer;
};
