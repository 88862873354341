import { phoneFormat } from '@shared/builders/phone-formart.helper';
import {
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IReferenceContact } from '@shared/interfaces/reference-contact.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { Reference } from '@shared/models/reference.model';
import { PhoneNumber } from 'libphonenumber-js';

export class ReferenceContact extends APIResource implements IReferenceContact {
  public static readonly TYPE: string = String('ReferenceContact');

  public id: APIuuid;
  public type: string;

  public candidate?: ICandidate;

  public email: string;
  public first_name: string;
  public last_name: string;
  public phone: PhoneNumber;
  public company_name: string;
  public title: string;
  public relationship: string;
  public country_code: string;

  public is_outdated: boolean;
  public is_active: boolean;

  get full_name(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  constructor(partial: Partial<IReferenceContact>) {
    super();

    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        id: this.id,
        type: 'Reference',
        attributes: {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          company_name: this.company_name,
          title: this.title,
          relationship: this.relationship,
          country_code: this.country_code,
          is_active: this.is_active,
          is_outdated: this.is_outdated
        },
        relationships: {
          candidate: this.candidate?.apiRelationship
        }
      }
    } as IAPIData;
  }

  static fromAPI(res: IAPIData): IReferenceContact | IReference {
    const data = res.data;

    const type = data.type;
    if (type === 'ProfileReference') {
      return new Reference({
        id: data.id,
        ...data.attributes
      });
    } else if (type !== ReferenceContact.TYPE) {
      throw new Error(
        `There was a problem parsing ${ReferenceContact.TYPE} API data`
      );
    }

    return new ReferenceContact({
      id: data.id,
      ...data.attributes
    });
  }

  static fromAPIArray(res: IAPIArrayData): IReferenceContact[] {
    const dataArray = res.data;
    return dataArray.map((data: IAPIDataObject) => {
      if (!(data?.type === ReferenceContact.TYPE)) {
        throw new Error(
          `There was a problem parsing ${ReferenceContact.TYPE} API data`
        );
      }

      const rf = new ReferenceContact({
        id: data.id,
        ...data.attributes
      });

      return rf;
    });
  }
}
