import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICountry } from '@shared/interfaces/country.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Recruiter } from '@shared/models/recruiter.model';
import { User } from '@shared/models/user.model';
import { LoggerService } from '@shared/services/logger.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ToastService } from '@shared/services/toast.service';
import { UsersService } from '@shared/services/users.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import { PasswordValidator } from '@shared/validators/password.validators';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-users',
  templateUrl: './modals.users.component.html'
})
export class ModalsUsersComponent
  extends ModalDirective<{ user: IUser; recruiter: IRecruiter }>
  implements OnInit
{
  @Input() public isAdmin = Boolean(false);

  @SessionStorage() public user: IUser;
  @SessionStorage() public countries: ICountry[];

  public title = String('MODALS.USERS.ADD.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');

  public entry: FormGroup;
  public entryRecruiter: FormGroup;

  public isButtonsDisabled = Boolean(false);
  public isRoot = Boolean(false);
  public isSupervisor = Boolean(false);
  public isSelf = Boolean(false);

  public recruiter: IRecruiter;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _toast: ToastService,
    private readonly _users: UsersService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _recruiters: RecruitersService,

    private _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((res: { user: IUser; recruiter: IRecruiter }) => {
      this.isRoot = this.user.isRootRecruiter;

      this.isSelf = this.user.id === res.user.id;
      this.isSupervisor = res.user.isSupervisor;

      const user = res.user;
      this.recruiter = res.recruiter;

      if (!!user) {
        const isEdit = user?.id;
        const country = this.countries.find(
          (c: ICountry) => c.phoneCode === user?.country_code
        );

        this.entry.patchValue({
          ...user,
          country_code: country?.phoneCode,
          countryInitial: country?.initial
        });

        if (isEdit) {
          this.title = 'MODALS.USERS.EDIT.TITLE';
          this.submitKey = 'BUTTONS.SAVE';
        }
      }
    });

    this.createForm();
  }

  public onChange(s: string) {
    (this.recruiter as any)[s] = !(this.recruiter as any)[s];

    const id = this.recruiter.id;
    const url = `PATCH /recruiters/${id}`;

    const recruiterData = new Recruiter(this.recruiter).apiData;
    this._recruiters.patch(recruiterData).subscribe(
      (res: IRecruiter) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Recruiter updated');
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error(err[0].detail);
        this.resetAll();
      }
    );
  }

  public onSubmit({ value }: FormGroup): void {
    this.isButtonsDisabled = true;
    this.entry.disable();
    this.errors = [];

    const userData = new User(value).apiData;
    userData.data.attributes = {
      ...userData.data.attributes,
      ...value
    };

    const id = value.id;
    const url = `PATCH /users/${id}`;
    this._users.patch(userData).subscribe(
      (res: IUser) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Recruiter updated');

        this.resetAll();
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error(err[0].detail);
        this.resetAll();
      }
    );
  }

  public onChangeSupervisorRole(value: boolean) {
    const id = this.entry.get('id').value;
    const url = `PATCH /users/${id}/roles`;
    const roles = !!value ? ['supervisor'] : []

    this._users.patchRoles(id, { roles }).subscribe(
      (res: IUser) => {
        this._logger.info(this.constructorName, url, res);
        this._toast.success('Role updated');
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error(err[0].detail);
        this.resetAll();
      }
    );
  }

  protected createForm() {
    this.entry = this._fb.group(
      {
        id: [''],
        first_name: [''],
        last_name: [''],
        email: [
          '',
          [Validators.email, Validators.required]
          // [this._emailValidityValidator.validates]
        ],
        phone: ['', [NumbersOnlyValidator.NumbersOnly]],
        countryInitial: [''],
        country_code: [''],
        password: [''],
        password_confirmation: [''],
        failed_attempts: [''],
        is_locked: [false],
        is_mfa_enabled: [false]
      },
      {
        validator: PasswordValidator.MatchPassword(
          'password',
          'password_confirmation'
        )
      }
    );
  }

  private resetAll() {
    this.entry.enable();
    this.isButtonsDisabled = false;
  }
}
