import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IActivity } from '@shared/interfaces/activity.interface';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmailActivity } from '@shared/interfaces/email-activity.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IReferenceContact } from '@shared/interfaces/reference-contact.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { ITextActivity } from '@shared/interfaces/text-activity.interface';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';
import { ITypeaheadable } from '@shared/interfaces/typeaheadable.interface';
import { IVerificationRequest } from '@shared/interfaces/verification-request.interface';
import { Activity } from '@shared/models/activity.model';
import { Candidate } from '@shared/models/candidate.model';
import { EmailActivity } from '@shared/models/email-activity.model';
import { Employer } from '@shared/models/employer.model';
import { Feedback } from '@shared/models/feedback.model';
import { ReferenceContact } from '@shared/models/reference-contact.model';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { Reminder } from '@shared/models/reminder.model';
import { TextActivity } from '@shared/models/text-activity.model';
import { Typeahead } from '@shared/models/typeahead.model';
import { VerificationRequest } from '@shared/models/verification-request.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService implements IPaginable, ITypeaheadable {
  public candidatesSource = new Subject();
  public candidatesCalled$ = this.candidatesSource.asObservable();

  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/candidates`
  );

  constructor(private readonly _http: HttpClient) {}

  public refresh(res: ICandidate) {
    this.candidatesSource.next(res);
  }

  public delete(id: string): Observable<ICandidate> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => Candidate.fromAPI(res)));
  }

  public find(
    id: string,
    queryParams?: { is_shorten: boolean }
  ): Observable<ICandidate> {
    let params: HttpParams = new HttpParams();

    if (!!queryParams) {
      params = params.set('is_shorten', queryParams.is_shorten.toString());
    }

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`, { params })
      .pipe(map((res: IAPIData) => Candidate.fromAPI(res)));
  }

  public get(): Observable<ICandidate[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => Candidate.fromAPIArray(res)));
  }

  public getAnalytics(q: any): Observable<any> {
    let params: HttpParams = new HttpParams();

    params = params.set('scope', q.scope);
    params = params.set('start_date', q.start_date);
    params = params.set('end_date', q.end_date);

    return this._http.get<IAPIArrayData>(`${this.ENDPOINT_V3}/analytics`, {
      params
    });
  }

  public getCandidateActivities(id: APIuuid): Observable<IActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/activities`)
      .pipe(map((res: IAPIArrayData) => Activity.fromAPIArray(res)));
  }

  public getCandidateReferenceRequests(
    id: APIuuid
  ): Observable<IReferenceRequest[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/reference_requests`)
      .pipe(map((res: IAPIArrayData) => ReferenceRequest.fromAPIArray(res)));
  }

  public getCandidateReferenceContacts(
    id: APIuuid
  ): Observable<IReferenceContact[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/reference_contacts`)
      .pipe(map((res: IAPIArrayData) => ReferenceContact.fromAPIArray(res)));
  }

  public getCandidateVerificationRequests(
    id: APIuuid
  ): Observable<IVerificationRequest[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/verification_requests`)
      .pipe(map((res: IAPIArrayData) => VerificationRequest.fromAPIArray(res)));
  }

  public getCandidateEmployers(id: APIuuid): Observable<IEmployer[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/employers`)
      .pipe(map((res: IAPIArrayData) => Employer.fromAPIArray(res)));
  }

  public getCandidateFeedbacks(id: APIuuid): Observable<IFeedback[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/feedbacks`)
      .pipe(map((res: IAPIArrayData) => Feedback.fromAPIArray(res)));
  }

  public getCandidateReminders(id: APIuuid): Observable<IReminder[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/reminders`)
      .pipe(map((res: IAPIArrayData) => Reminder.fromAPIArray(res)));
  }

  public getCandidateEmailActivities(
    id: APIuuid
  ): Observable<IEmailActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/email_activities`)
      .pipe(map((res: IAPIArrayData) => EmailActivity.fromAPIArray(res)));
  }

  public getCandidateTextActivities(id: APIuuid): Observable<ITextActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/text_activities`)
      .pipe(map((res: IAPIArrayData) => TextActivity.fromAPIArray(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    sort?: ISort,
    dates?: IFilter[]
  ): Observable<ICandidate[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (dates) {
      dates.map((f: IFilter) => {
        if (!!f['value']) {
          params = params.set(`dates[${f['key']}]`, f['value']);
        }
      });
    }

    if (sort) {
      const sortValue = sort['isReverse'] ? `-${sort['value']}` : sort['value'];
      params = params.set('sort', sortValue);
    }

    params = params.set('is_shorten', 'true');

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3, { params })
      .pipe(
        tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
        map((res: IAPIArrayData) => Candidate.fromAPIArray(res))
      );
  }

  public patch(body: IAPIData): Observable<ICandidate> {
    const id = body.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}`, body)
      .pipe(map((res: IAPIData) => Candidate.fromAPI(res)));
  }

  public post(body: IAPIData): Observable<ICandidate> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => Candidate.fromAPI(res)));
  }

  public postDocument(file: File, params: any): Observable<ICandidate> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const options = {
      params
    };

    return this._http
      .post<IAPIData>(
        `${this.ENDPOINT_V3}/${params.id}/documents`,
        formData,
        options
      )
      .pipe(map((res: IAPIData) => Candidate.fromAPI(res)));
  }

  public postInvite(id: string): Observable<any> {
    return this._http
      .post<any>(`${this.ENDPOINT_V3}/${id}/invite`, {
        params: {
          type: 'invite'
        }
      })
      .pipe((res) => res);
  }

  public searchTypeahead(search: {
    data: { search_criteria: string };
  }): Observable<ITypeahead> {
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V3}/search`, search)
      .pipe(map((res: IAPIData) => Typeahead.fromAPI(res)));
  }
}
