import {
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IEducation } from '@shared/interfaces/education.interface';
import { IVerification } from '@shared/interfaces/verification.interface';
import { Verification } from '@shared/models/verification.model';
import moment from 'moment';

export class Education extends APIResource implements IEducation {
  public static readonly TYPE: string = String('Education');

  id: APIuuid;
  type: string;
  institution: string;
  degree: string;
  subject: string;
  start_date: string;
  end_date: string;
  description: string;
  verifications: IVerification[] = [];
  is_verified?: boolean;
  is_vitay_verified?: boolean;
  vitay_verification_requested?: boolean;

  get formattedStartDate(): string {
    return moment(this.start_date).format('MMM DD, YYYY');
  }

  get formattedEndDate(): string {
    return moment(this.end_date).format('MMM DD, YYYY');
  }

  constructor(partial: Partial<Education>) {
    super();

    Object.assign(this, partial);
  }

  static fromAPIArray(res: IAPIArrayData): IEducation[] {
    return res.data.map((c: any) =>
      this.fromAPI({
        data: c,
        included: res.included
      })
    );
  }

  static fromAPI(res: IAPIData): Education {
    const data = res.data;
    const included = res.included;
    if (!(data && data.type === Education.TYPE)) {
      throw new Error(`There was a problem parsing ${Education.TYPE} API data`);
    }

    const educationExperience = new Education({
      id: data.id,
      type: data.type,
      ...data.attributes
    });

    if (included) {
      educationExperience.verifications = Verification.fromAPIArray({
        data: included.filter(
          (i: any) =>
            i.type === 'Verification' &&
            i.attributes.verifiable_type === 'Education' &&
            i.attributes.verifiable_id === educationExperience.id
        )
      });
    }

    return educationExperience;
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: Education.TYPE,
        attributes: {
          institution: this.institution,
          degree: this.degree,
          start_date: this.start_date,
          end_date: this.end_date,
          description: this.description,
          subject: this.subject
        },
        relationships: {}
      }
    } as IAPIData;

    return data;
  }
}
