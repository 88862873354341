export const candidatesStatus: {
  id: number;
  label: string;
  btnClass: string;
  class: string;
}[] = [
  {
    id: 0,
    label: 'Process initiated',
    btnClass: 'btn-light',
    class: 'lnr lnr-hourglass'
  },
  {
    id: 1,
    label: 'Waitlisted',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-hourglass v-text-warning'
  },
  {
    id: 2,
    label: 'Interview scheduled',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-calendar-check v-text-warning'
  },
  {
    id: 3,
    label: 'Rejected',
    btnClass: 'v-btn-danger',
    class: 'lnr lnr-cross v-text-danger'
  },
  {
    id: 4,
    label: 'Interviewed',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    id: 5,
    label: 'Completed',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    id: 6,
    label: 'Submitted',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    id: 7,
    label: 'Accepted',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  }
];
