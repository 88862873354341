import { IAPIRelationship } from '@shared/interfaces/api.interface';
import { ICard } from '@shared/interfaces/card.interface';
import { Customer } from '@shared/models/customer.model';

export const buildCard = (
  card: ICard,
  relationships: any,
  included: any
): any => {
  const customer =
    relationships &&
    relationships.customer &&
    included &&
    included.find(
      (i: IAPIRelationship) =>
        i.id === (relationships.customer as IAPIRelationship).id
    );

  if (customer && included) {
    card.customer = Customer.fromAPI({
      data: customer,
      included
    });
  } else if (relationships && relationships.customer) {
    card.customer = relationships.customer as IAPIRelationship;
  }

  return card;
};
