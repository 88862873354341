import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentsService } from '@references/shared/services/environments.service';
import { languages } from '@shared/helpers/languages.helper';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { UpdatesService } from '@shared/services/updates.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @SessionStorage() private hiringFirm: IHiringFirm;
  @LocalStorage() private lng: string;

  private languages: any[] = languages.map((l: any) => l.id);

  constructor(
    private readonly _environments: EnvironmentsService,
    private readonly _translate: TranslateService,
    private readonly _updates: UpdatesService
  ) {
    this._translate.addLangs(this.languages);

    // NOT REQUIRED BUT CAN BE USEFUL
    // const browserLang = this.translateService.getBrowserLang();
    // this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    const lng = this.lng || this.hiringFirm?.settings.language || 'en';

    this._environments.setLng(lng);
    this._environments.setScripts();
    this._environments.setTheme();
    this._updates.checkUpdates();
  }

  ngOnInit(): void {}
}
