import { environment } from '@environment/environment';
import {
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEducation } from '@shared/interfaces/education.interface';
import { IExperience } from '@shared/interfaces/experience.interface';
import { IProfile } from '@shared/interfaces/profile.interface';
import { ISkill } from '@shared/interfaces/skill.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Candidate } from '@shared/models/candidate.model';
import { Education } from '@shared/models/education.model';
import { Experience } from '@shared/models/experience.model';
import { Skill } from '@shared/models/skill.model';
import { User } from '@shared/models/user.model';

export class Profile extends APIResource implements IProfile {
  public static readonly TYPE: string = String('Profile');
  id: APIuuid;
  type = String('Profile');
  title: string;
  bio: string;
  job_title: string;
  city: string;
  province: string;
  country: string;
  user: IUser;
  has_user: boolean;
  experiences: IExperience[];
  educations: IEducation[];
  skill: IAPIDataObject | ISkill;
  picture: string;
  picture_url: string;
  username: string;
  is_available: boolean;
  is_active: boolean;
  candidates: ICandidate[];

  is_vitay_verified: boolean;
  vitay_verification_requested: boolean;

  constructor(partial: Partial<IProfile>) {
    super();

    Object.assign(this, partial);
  }

  get last_connected_at(): string | null {
    return this.user?.last_connected_at;
  }

  public get contactList() {
    return [
      {
        url: this.user.email ? `mailto:${this.user.email}` : null,
        icon: 'lnr-envelope',
        label: this.user.email || '-'
      },
      {
        url: this.user.phone ? `tel:${this.user.phone_number}` : null,
        icon: 'lnr-phone',
        label: this.user.phone || '-'
      },
      {
        url: null,
        icon: 'lnr-map-marker',
        label: this.location || '-'
      }
    ];
  }

  public get location(): string {
    if (!this.city && !this.province && !this.country) {
      return '-';
    }

    return (
      (this.city || '') +
      (this.city && this.province ? ', ' : '') +
      (this.province || '') +
      ((this.city && this.country) || (this.province && this.country)
        ? ', '
        : '') +
      (this.country || '')
    );
  }

  static fromAPI(res: IAPIData, userId?: string): IProfile {
    const data = res.data;

    if (!(data && data?.type === Profile.TYPE)) {
      throw new Error(`There was a problem parsing ${Profile.TYPE} API data for user ${userId || 'unknown'}`);
    }

    const profile = new Profile({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    if (included) {
      const verificationData = included.filter(
        (i: IAPIDataObject) => i.type === 'Verification'
      );

      profile.experiences = included
        .filter((i: IAPIDataObject) => i.type === 'Experience')
        .map((experienceData: IAPIDataObject) =>
          Experience.fromAPI({
            data: experienceData,
            included: verificationData
          })
        );

      profile.educations = included
        .filter((i: IAPIDataObject) => i.type === 'Education')
        .map((educationData: IAPIDataObject) =>
          Education.fromAPI({
            data: educationData,
            included: verificationData
          })
        );

      profile.skill = included
        .filter((i: IAPIDataObject) => i.type === 'Skill')
        .reduce(
          (_prev: any, current: any) =>
            Skill.fromAPI({
              data: current,
              included: verificationData
            }),
          {} as any
        );

      const userRelationship = data.relationships.user as IAPIRelationship;
      const userData =
        included &&
        included.find(
          (i: any) =>
            userRelationship &&
            userRelationship.id === i.id &&
            i.type === 'User'
        );

      if (userData) {
        profile.user = new User({
          id: userData.id,
          ...userData.attributes
        });
      }

      profile.candidates = Candidate.fromAPIArray({
        data: included.filter((i: IAPIDataObject) => i.type === 'Candidate'),
        included
      });
    }

    return profile;
  }

  public get first_name() {
    return this.user ? this.user.first_name : '-';
  }

  public get last_name() {
    return this.user ? this.user.last_name : '-';
  }

  get full_name(): string {
    return this.user?.first_name || this.user?.last_name
      ? `${this.user.first_name} ${this.user.last_name}`
      : '';
  }

  get public_url(): string {
    return `${environment.ENDPOINTS.FRONT_END.APP}/${this.public_id}`;
  }

  get public_id(): string {
    return this.username || this.id;
  }

  static fromAPIArray(res: IAPIArrayData): IProfile[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      const profile: IProfile = new Profile({
        id: data.id,
        ...data.attributes
      });

      const relationships = data?.relationships;

      const userRelationship = relationships?.user as IAPIRelationship;

      if (userRelationship) {
        const userData = included.find(
          (i: IAPIDataObject) => i.id === userRelationship.id
        );

        if (userData) {
          profile.user = new User({
            id: userData.id,
            ...userData.attributes
          });
        }

        // Work Experiences
        const experiencesRelationships =
          relationships?.experiences as IExperience[];
        profile.experiences =
          experiencesRelationships.map((w: IExperience) => {
            const weData =
              included &&
              included.find(
                (i: any) => i.type === 'Experience' && i.id === w.id
              );

            if (weData) {
              return Experience.fromAPI({
                data: weData,
                included
              });
            }
          }) || [];

        // Education
        const educationsRelationships =
          relationships?.educations as IEducation[];
        profile.educations =
          educationsRelationships.map((e: IEducation) => {
            const eeData =
              included &&
              included.find(
                (i: any) => i.type === 'Education' && i.id === e.id
              );

            if (eeData) {
              return Education.fromAPI({
                data: eeData,
                included
              });
            }
          }) || [];
      }

      return profile;
    });
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: Profile.TYPE,
        attributes: {
          bio: this.bio,
          job_title: this.job_title,
          title: this.title,
          username: this.username,
          vitay_verification_requested: this.vitay_verification_requested,
          is_available: this.is_available,
          is_vitay_verified: this.is_vitay_verified
        },
        relationships: {}
      }
    } as IAPIData;

    return data;
  }
}
