import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IAuthentication } from '@shared/interfaces/authentication.interface';
import { Authentication } from '@shared/models/authentication.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/oauth`
  );

  constructor(private readonly _http: HttpClient) {}

  public postRefresh(body: any): Observable<any> {
    return this._http
      .post(`${this.ENDPOINT_V3}/refresh`, body)
      .pipe(map((res: IAPIData) => Authentication.fromAPI(res)));
  }

  public postReset(body: any): Observable<any> {
    return this._http
      .post(`${this.ENDPOINT_V3}/reset`, body)
      .pipe(map((res: IAPIData) => res));
  }

  public patchReset(body: any): Observable<IAuthentication> {
    return this._http
      .patch(`${this.ENDPOINT_V3}/reset`, body)
      .pipe(map((res: IAPIData) => Authentication.fromAPI(res)));
  }

  public getReset(params: HttpParams): Observable<IAuthentication> {
    return this._http
      .get<any>(`${this.ENDPOINT_V3}/reset`, {
        params
      })
      .pipe(map((res: IAPIData) => Authentication.fromAPI(res)));
  }
}
