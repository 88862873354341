import {
  APIDatetime,
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IQuestionGroup } from '@shared/interfaces/question-group.interface';

export class QuestionGroup extends APIResource implements IQuestionGroup {
  public static readonly TYPE: string = String('QuestionGroup');

  id: APIuuid;
  label: string;
  is_active: boolean;
  questions_count: number;

  hiring_firm: IHiringFirm | IAPIRelationship;

  created_at: APIDatetime;
  updated_at: APIDatetime;

  constructor(partial: Partial<IQuestionGroup>) {
    super();

    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IQuestionGroup {
    const data = res.data;

    if (!(data && data.type === QuestionGroup.TYPE)) {
      throw new Error(
        `There was a problem parsing ${QuestionGroup.TYPE} API Data`
      );
    }

    return new QuestionGroup({
      id: data.id,
      ...data.attributes
    });
  }

  static fromAPIArray(res: IAPIArrayData): IQuestionGroup[] {
    const dataArray = res.data;

    return dataArray.map(
      (data: IAPIDataObject) =>
        new QuestionGroup({
          id: data.id,
          ...data.attributes
        })
    );
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: QuestionGroup.TYPE,
        attributes: {
          label: this.label,
          is_active: this.is_active,
          questions_count: this.questions_count
        },
        relationships: {
          hiring_firm: {
            id: this.hiring_firm?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;

    return data;
  }
}
