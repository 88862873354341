import { QuestionCategory, QuestionType } from '@shared/enums/question.enum';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IQuestionGroup } from '@shared/interfaces/question-group.interface';
import { IQuestion } from '@shared/interfaces/question.interface';
import { IQuestionOption } from '@shared/interfaces/question.option.interface';
import { QuestionGroup } from '@shared/models/question-group.model';

export class Question implements IQuestion {
  public static readonly TYPE: string = String('Question');

  id: APIuuid;
  type: QuestionType;

  questionable_id: string;
  questionable_type: string;
  language: string;
  value: string;

  category: QuestionCategory;
  question_group: IQuestionGroup | IAPIRelationship;

  options: IQuestionOption[];
  columns: IQuestionOption[];
  order: number;
  commentable: boolean;
  is_comment_required: boolean;

  constructor(partial: Partial<IQuestion>) {
    Object.assign(this, partial);
  }

  /**
   * @deprecated
   * @returns
   */
  get comment(): boolean {
    return this.commentable;
  }

  get categoryLabel(): string {
    switch (this.category) {
      case QuestionCategory.Techinical:
        return 'Technical';
      case QuestionCategory.Behavioral:
        return 'Behavioral';
      case QuestionCategory.Functional:
        return 'Functional';
      default:
        return '';
    }
  }

  get typeLabel(): string {
    switch (this.type) {
      case QuestionType.Text:
        return 'Text';
      case QuestionType.Rating:
        return 'Rating';
      case QuestionType.Dropdown:
        return 'Drop Down';
      case QuestionType.Radio:
        return 'Radio';
      case QuestionType.Checkbox:
        return 'Checkbox';
      case QuestionType.Slider:
        return 'Slider';
      case QuestionType.Date:
        return 'Date';
      case QuestionType.Ranking:
        return 'Ranking';
      case QuestionType.Matrix:
        return 'Matrix';
      default:
        return '-';
    }
  }
  public get apiData(): IAPIData {
    return {
      data: {
        type: Question.TYPE,
        id: this.id,
        attributes: {
          value: this.value,
          questionable_id: this.questionable_id,
          questionable_type: this.questionable_type,
          type: this.type,
          category: this.category,
          options: this.options,
          columns: this.columns,
          order: this.order,
          commentable: this.commentable,
          is_comment_required: this.is_comment_required,
          language: this.language
        },
        relationships: {
          question_group: {
            id: this.question_group?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  static fromAPIArray(res: IAPIArrayData): IQuestion[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      const q = new Question({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      const qg =
        relationships &&
        relationships.question_group &&
        included &&
        included.find(
          (i: IAPIDataObject) =>
            i.id === (relationships.question_group as IAPIRelationship).id
        );

      if (!!qg) {
        q.question_group = QuestionGroup.fromAPI({
          data: qg
        });
      }

      return q;
    });
  }

  static fromAPI(res: IAPIData): IQuestion {
    const data = res.data;

    if (!(data && data.type === Question.TYPE)) {
      throw new Error(`There was a problem parsing ${Question.TYPE} API data`);
    }

    return new Question({
      id: data.id,
      ...data.attributes
    });
  }
}
