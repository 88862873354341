export const defaultReferencesRelationships = [
  {
    label: {
      en: 'Direct manager',
      fr: 'Responsable direct',
      es: 'Responsable directo'
    },
    value: 'D'
  },
  {
    label: {
      en: 'Non-direct manager',
      fr: 'Responsable',
      es: 'Responsable'
    },
    value: 'N'
  },
  {
    label: {
      en: 'Direct report',
      fr: 'Subordonnés',
      es: 'Subordinados'
    },
    value: 'R'
  },
  {
    label: {
      en: 'Peer',
      fr: 'Collègue',
      es: 'Colega'
    },
    value: 'P'
  },
  {
    label: {
      en: 'Human resources',
      fr: 'Ressources humaines',
      es: 'Recursos humanos'
    },
    value: 'H'
  },
  {
    label: {
      en: 'Educational',
      fr: 'Enseignement',
      es: 'Educativo'
    },
    value: 'E'
  },
  {
    label: {
      en: 'Community leader',
      fr: 'Dirigeant communautaire',
      es: 'Líder de la comunidad'
    },
    value: 'C'
  }
  // {
  //   label: {
  //   en: 'Other',
  //   fr: 'Autre'
  // },
  //   value: 'O'
  // }
];

export const csvReportFormat = {
  id: 'csv',
  label: 'CSV'
};

export const reportFormats = [
  {
    id: 'pdf',
    label: 'PDF'
  },
  {
    id: 'docx',
    label: 'Word'
  }
];

export const ratings = [
  {
    label: '(5) Strongly Agree',
    value: 5
  },
  {
    label: '(4) Agree',
    value: 4
  },
  {
    label: '(3) Neutral',
    value: 3
  },
  {
    label: '(2) Disagree',
    value: 2
  },
  {
    label: '(1) Strongly Disagree',
    value: 1
  }
];

export const ratingsOn10 = [
  {
    label: '(10) Strongly Agree',
    value: 10
  },
  {
    label: '(9) Strongly Agree',
    value: 9
  },
  {
    label: '(8) Agree',
    value: 8
  },
  {
    label: '(7) Agree',
    value: 7
  },
  {
    label: '(6) Neutral',
    value: 6
  },
  {
    label: '(6) Neutral',
    value: 5
  },
  {
    label: '(2) Disagree',
    value: 4
  },
  {
    label: '(2) Disagree',
    value: 3
  },
  {
    label: '(1) Strongly Disagree',
    value: 2
  },
  {
    label: '(1) Strongly Disagree',
    value: 1
  }
];

export const verificationReasons = [
  {
    label: 'Voluntary',
    value: 'V'
  },
  {
    label: 'Involuntary',
    value: 'I'
  }
];

export const verificationEmploymentStatus = [
  {
    label: 'Permanent, full-time employee',
    value: 'PFT'
  },
  {
    label: 'Permanent, part-time employee',
    value: 'PPT'
  },
  {
    label: 'Temporary (fixed-term), full-time employee',
    value: 'T'
  },
  {
    label: 'Casual employee',
    value: 'CE'
  },
  {
    label: 'Contractor',
    value: 'C'
  }
];
