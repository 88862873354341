import {
  APIDate,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';

export class Reminder implements IReminder {
  public static readonly TYPE: string = String('Reminder');

  public id: APIuuid;
  public type: string;

  public scheduled_at: Date;
  public sent_at: Date;
  public is_active: boolean;

  public next_scheduled_at?: string;
  public latest_sent_at?: string;
  public count: number;
  public count_exceeded: boolean;
  public rate_exceeded: boolean;

  public disabled?: boolean;

  public created_at: APIDate;
  public updated_at: APIDate;

  public identifiable: IReference | IReferenceRequest;

  constructor(partial: Partial<IReminder>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IReminder | undefined {
    const data = res.data;

    const types = [Reminder.TYPE, 'TextReminder', 'EmailReminder'];

    if (!types.includes(data?.type)) {
      throw new Error(`There was a problem parsing ${Reminder.TYPE} API data`);
    }

    const reminder = new Reminder({
      id: data.id,
      ...data.attributes
    });

    return reminder;
  }

  static fromAPIArray(res: IAPIArrayData): IReminder[] {
    const dataArray = res.data || [];

    return dataArray.map((data: IAPIDataObject) => {
      const types = [Reminder.TYPE, 'TextReminder', 'EmailReminder'];

      if (!types.includes(data?.type)) {
        throw new Error(
          `There was a problem parsing ${Reminder.TYPE} API data`
        );
      }

      const reminder = new Reminder({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;
      if (relationships?.identifiable) {
        const identifiable: any = relationships.identifiable;
        reminder.identifiable = identifiable;
      }

      return reminder;
    });
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Reminder.TYPE,
        id: this.id,
        attributes: {
          scheduled_at: this.scheduled_at,
          sent_at: this.sent_at,
          is_active: this.is_active
        }
      }
    } as IAPIData;
  }
}
