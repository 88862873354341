import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { CandidatesService } from '@shared/services/candidates.service';
import { LoggerService } from '@shared/services/logger.service';
import { ReferenceRequestsService } from '@shared/services/reference-requests.service';

@Component({
  selector: 'app-sidebar-candidate-reference-requests',
  templateUrl: './sidebar.candidate.reference-requests.component.html'
})
export class SidebarCandidateReferenceRequestsComponent implements OnInit {
  @Input() public candidate: ICandidate;

  public isLoading = Boolean(true);
  public rows: IReferenceRequest[] = [];
  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.CREATED_AT',
      type: TableColType.Date,
      selector: 'created_at'
    },
    {
      headerText: 'TABLES.HEADERS.FILLED_ON',
      type: TableColType.Date,
      selector: 'filled_at'
    },
    {
      headerText: 'TABLES.HEADERS.NOTES',
      type: TableColType.Text,
      cellClassName: 'v--text-ellipsis',
      selector: 'notes',
      selectorFunction: (data: string) => data || '-',
      tooltipFunction: (data: string) => (data ? data : null)
    },
    {
      headerText: 'TABLES.HEADERS.ACTIVE',
      type: TableColType.Toggle,
      headerClassName: 'text-center',
      cellClassName: 'text-center',
      selector: 'is_active',
      selectorSwitch: (r: IReferenceRequest) => !!r.filled
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _candidates: CandidatesService,
    private readonly _logger: LoggerService,
    private readonly _referenceRequests: ReferenceRequestsService
  ) {
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  ngOnInit(): void {
    this.getReferenceRequests();
  }

  public onButtonClick(_e: Event, row: IReferenceRequest) {
    row.is_active = !row.is_active;

    const data = new ReferenceRequest(row).apiData;
    const url = `PATCH /reference_requests/${row.id}`;
    this._referenceRequests.patch(data).subscribe(
      (res: Partial<IReferenceRequest>) => {
        this._logger.info(this.constructorName, url, res);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private getReferenceRequests() {
    const url = `GET /candidates/${this.candidate.id}/reference_requests`;
    this._candidates.getCandidateReferenceRequests(this.candidate.id).subscribe(
      (res: IReferenceRequest[]) => {
        this._logger.info(this.constructorName, url, res);

        this.rows = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.isLoading = false;
      }
    );
  }
}
