import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environment/environment';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IAuthentication } from '@shared/interfaces/authentication.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { ActivitiesService } from '@shared/services/activities.service';
import { AuthService } from '@shared/services/auth.service';
import { CandidatesService } from '@shared/services/candidates.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { HiringFirmsService } from '@shared/services/hiring-firms.service';
import { LoggerService } from '@shared/services/logger.service';
import { ReferencesService } from '@shared/services/references.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { ToastService } from '@shared/services/toast.service';
import { UpdatesService } from '@shared/services/updates.service';
import { UsersService } from '@shared/services/users.service';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { forkJoin, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class ReferencesTopbarComponent implements OnInit, OnDestroy {
  @LocalStorage() public isSubAccount: boolean;
  @LocalStorage() public canManageSubAccount: boolean;

  @SessionStorage() public user: IUser;
  @SessionStorage() public settings: ISetting;
  @SessionStorage() public hiringFirm: IHiringFirm;

  public ENDPOINTS_ASSETS: string = environment.ENDPOINTS.ASSETS;

  public isNotficationsAvailable = Boolean(false);
  public isSearchbarEnabled = Boolean(false);
  public isUpdateAvailable = Boolean(false);
  public isWhiteLabelEnabled = Boolean(false);
  public isRoot = Boolean(false);

  public resourceName = String('candidates');
  public billingUrl: string;
  public service: CandidatesService | ReferencesService = this._candidates;

  public rows: IActivity[] = [];
  public apps: any[] = [];

  public companies: IHiringFirm[] = [];

  public openExportModal: Subject<boolean> = new Subject();

  public daterange: any = {
    start_date: moment()
      .subtract(1, 'week')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
  };

  private readonly constructorName: string = String(this.constructor.name);
  private updateSubscription: Subscription;
  private reloadDataSetSubscription: Subscription;

  constructor(
    private readonly _activities: ActivitiesService,
    private readonly _auth: AuthService,
    private readonly _hiringFirms: HiringFirmsService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _candidates: CandidatesService,
    private readonly _references: ReferencesService,
    private readonly _router: Router,
    private readonly _sidebar: SidebarService,
    private readonly _toast: ToastService,
    private readonly _users: UsersService,
    private readonly _updates: UpdatesService
  ) {
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);

    this._router.events.subscribe((res: Event) => {
      if (res instanceof NavigationEnd) {
        this.checkCurrentUrl();
      }
    });
  }

  ngOnInit(): void {
    this.isWhiteLabelEnabled =
      this.settings?.is_plugins_white_label_enabled || false;

    const urlUpdate = 'Update subscription';
    this.updateSubscription = this._updates.updatesCalled$.subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, urlUpdate, res);

        this.isUpdateAvailable = res;
      },
      (err: any) => this._logger.error(this.constructorName, urlUpdate, err)
    );

    const url = 'Reload sub account dataset';
    this.reloadDataSetSubscription =
      this._sidebar.reloadDatasetCalled$.subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, url, res);

          this.setApps();
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );

    this.setApps();
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
    this.reloadDataSetSubscription?.unsubscribe();
  }

  public goTo(a: any) {
    if (!a.isDisabled) {
      window.open(a.url, '_blank');
    }
  }

  public onSelectService(id: string) {
    this.resourceName = id;
    if (this.resourceName === 'candidates') {
      this.service = this._candidates;
    } else {
      this.service = this._references;
    }
  }

  public onMatOptionSelect(r: any): void {
    if (this.resourceName === 'candidates') {
      this.goToCandidate(r);
    } else {
      this.goToReference(r);
    }
  }

  public getActivities() {
    const url = `GET /activities`;

    const params = {
      ...this.daterange,
      limit: 5
    };

    this._activities.get(params).subscribe(
      (res: IActivity[]) => {
        this._logger.info(this.constructorName, url, res);
        this.rows = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public openModal() {
    this.openExportModal.next();
  }

  public trackByFn(i: any) {
    return i.id;
  }

  public reload() {
    window.location.reload();
  }

  public onChangeCompanies(id: string) {
    if (this.hiringFirm.id !== id) {
      this.hiringFirm = this.companies.find((c: IHiringFirm) => c.id === id);

      this.settings = this.hiringFirm.settings;

      const url = 'POST /oauth/refresh';
      this._auth
        .postRefresh({
          data: {
            attributes: {
              hiring_firm_id: this.hiringFirm.id
            }
          }
        })
        .subscribe(
          (res: IAuthentication) => {
            this._logger.info(this.constructorName, url, res);
            const newToken = res.token;
            if (!!newToken) {
              this._commonEnvironments.setToken('recruiter-token', newToken);

              const payload: IPayload = jwtDecode(newToken);
              this.isSubAccount = payload.is_sub_account;
              this.canManageSubAccount = !!payload.recruiter_id;

              forkJoin([
                this._users.get(),
                this._hiringFirms.getReferenceRelationships(
                  this.hiringFirm.id,
                  true
                )
              ]).subscribe(
                (resUser: any[]) => {
                  this._logger.info(this.constructorName, url, resUser);

                  this._sidebar.reloadDatasetSource.next();
                },
                (err: any) => {
                  this._logger.error(this.constructorName, url, err);
                }
              );
            }
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);

            this._toast.error('Company switch failed');
          }
        );
    }
  }

  private goToCandidate(c: ICandidate): void {
    this._router.navigate(['/candidates', c.id]);
  }

  private goToReference(r: IReference): void {
    this._router.navigate(['/leads', r.id]);
  }

  private checkCurrentUrl() {
    const url = this._router.url;
    if (url.indexOf('candidates') > -1) {
      this.onSelectService('candidates');
    } else if (url.indexOf('leads') > -1) {
      this.onSelectService('references');
    }
  }

  private getUsersCompanies() {
    const id = this.user.id;

    const url = `GET /users/${id}/hiring_firms`;
    this._users.getHiringFirms(id).subscribe(
      (res: IHiringFirm[]) => {
        this._logger.info(this.constructorName, url, res);

        this.companies = res;
      },
      (err: any) => this._logger.error(this.constructorName, url, err)
    );
  }

  private setApps() {
    this.isRoot = this.user.isRootRecruiter;

    const configurationUrl = this._commonEnvironments.APPS.find(
      (a: any) => a.label === 'configuration'
    ).url;

    this.billingUrl = `${configurationUrl}/settings/billing`;

    this.apps = this._commonEnvironments.APPS.filter(
      (a: any) => a.label !== 'references'
    );

    if (!this.isRoot) {
      this.apps = this.apps.filter((a: any) => a.label !== 'configuration');
    }

    if (
      !this.settings.is_plugins_candidate_feedback_enabled &&
      !this.settings.is_plugins_recruiter_feedback_enabled &&
      !this.settings.is_plugins_pre_screening_enabled
    ) {
      this.apps = this.apps.filter((a: any) => a.label !== 'feedback');
    }

    if (this.isWhiteLabelEnabled) {
      if (!!this.settings.white_label_custom_configuration_domain) {
        const customUrl = this.settings.white_label_custom_configuration_domain;
        this.apps = this.apps.map((a: any) =>
          a.label === 'configuration' ? {...a, url: customUrl} : a
        );
      }

      if (!!this.settings.white_label_custom_analytics_domain) {
        const customUrl = this.settings.white_label_custom_analytics_domain;
        this.apps = this.apps.map((a: any) =>
          a.label === 'analytics' ? {...a, url: customUrl} : a
        );
      }
    }

    this.getActivities();
    this.getUsersCompanies();

    this.checkCurrentUrl();
  }
}
