import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { IIntegrationsJobDiva } from '@shared/interfaces/integrations/integrations.jobdiva.interface';

export class IntegrationsJobDiva implements IIntegrationsJobDiva {
  public static readonly TYPE: string = String(
    'ExternalAuthorisations::Jobdiva'
  );

  public id: APIuuid;
  public client_id: string;
  public username: string;
  public password: string;

  constructor(partial: Partial<IIntegrationsJobDiva>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: IntegrationsJobDiva.TYPE,
        id: this.id,
        attributes: {
          client_id: this.client_id,
          username: this.username,
          password: this.password
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IIntegrationsJobDiva {
    const data = res.data;
    const types = [IntegrationsJobDiva.TYPE, 'ExternalAuthorisation'];

    if (!types.includes(data?.type)) {
      throw new Error(
        `There was a problem parsing ${IntegrationsJobDiva.TYPE} API data`
      );
    }

    return new IntegrationsJobDiva({
      id: data.id,
      ...data.attributes
    });
  }
}
