import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IConnection } from '@shared/interfaces/connection.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { User } from '@shared/models/user.model';

export class Connection implements IConnection {
  public static readonly TYPE: string = String('Connection');

  id: APIuuid;
  connected_user: IUser;
  invitation_sent: boolean;

  constructor(partial: Partial<IConnection>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IConnection {
    const data = res.data as any;
    const included = res.included as any;

    if (!(data && data.type === Connection.TYPE)) {
      throw new Error(
        `There was a problem parsing ${Connection.TYPE} API data`
      );
    }

    const connection = new Connection({
      id: data.id,
      ...data.attributes
    });

    const userData = included.find(
      (i: IAPIDataObject) =>
        i.type === 'User' && i.id === data.attributes.connected_user_id
    );

    if (userData) {
      connection.connected_user = new User({
        id: userData.id,
        ...userData.attributes
      });
    }

    return connection;
  }

  static fromAPIArray(res: IAPIArrayData): IConnection[] {
    return res.data.map((data: IAPIDataObject) =>
      this.fromAPI({
        data,
        included: res.included
      })
    );
  }
}
