import { ITypeaheadCollision } from '@shared/interfaces/typeahead.collision.interface';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';

export class Typeahead implements ITypeahead {
  collisions: ITypeaheadCollision[];

  constructor(partial: Partial<ITypeahead>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: any): ITypeahead {
    const collisions = (res.data as ITypeaheadCollision[]) || [];
    return new Typeahead({
      collisions
    });
  }
}
