import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IEmail } from '@shared/interfaces/email.interface';

export class Email implements IEmail {
  public static readonly TYPE: string = String('Email');

  id: string;
  hiring_firm: string;
  subject: string;
  content: string;
  language: string;
  title: string;
  cta: string;
  category: string;
  is_default: boolean;
  is_reminder: boolean;

  defaultLanguage = String('en');
  isSubmitting = Boolean(false);

  to?: string;

  constructor(partial: Partial<IEmail>) {
    Object.assign(this, partial);
  }

  get variables() {
    switch (this.category) {
      case 'request':
        if (!this.is_reminder) {
          return [
            'candidate_first_name',
            'candidate_full_name',
            'company_name',
            'recruiter_full_name',
            'nb_min_references'
          ];
        }

        return [
          'candidate_first_name',
          'candidate_full_name',
          'company_name',
          'recruiter_full_name'
        ];

      case 'reference':
        return [
          'candidate_first_name',
          'candidate_full_name',
          'reference_first_name',
          'reference_full_name'
        ];

      case 'candidate_reference_completed':
        return [
          'candidate_first_name',
          'candidate_full_name',
          'reference_full_name'
        ];

      case 'feedback':
        return [
          'candidate_first_name',
          'candidate_full_name',
          'company_name',
          'recruiter_full_name'
        ];

      case 'feedback_employer':
        return [
          'candidate_first_name',
          'employer_full_name',
          'candidate_full_name',
          'company_name',
          'recruiter_full_name'
        ];

      case 'recruiter_reference_completed':
        return [
          'candidate_first_name',
          'candidate_last_name',
          'candidate_email',
          'candidate_full_name',
          'reference_full_name',
          'reference_first_name',
          'reference_last_name',
          'added_by_full_name'
        ];

      default:
        return [];
    }
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: Email.TYPE,
        id: this.id,
        attributes: {
          hiring_firm: this.hiring_firm,
          title: this.title,
          cta: this.cta,
          subject: this.subject,
          content: this.content,
          category: this.category,
          is_default: this.is_default,
          is_reminder: this.is_reminder,
          language: this.language,
          to: this.to
        }
      }
    } as IAPIData;

    return data;
  }

  static fromAPI(res: IAPIData): IEmail {
    const data = res.data;

    if (!(data && data.type === Email.TYPE)) {
      throw new Error(`There was a problem parsing ${Email.TYPE} API data`);
    }

    return new Email({
      id: data.id,
      ...data.attributes
    });
  }

  static fromAPIArray(res: IAPIArrayData): IEmail[] {
    return res.data.map((c: any) =>
      this.fromAPI({
        data: c,
        included: res.included
      })
    );
  }
}
