import { IAnswerSet } from '@shared/interfaces/answer-set.interface';
import {
  APIDatetime,
  APIResource,
  APIuuid,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { Answer } from '@shared/models/answer.model';

/**
 * Answer set contains the creation date of the reference request.
 * It also maintains a sorted array of answers
 * TO DO: versioned answer set
 */
export class AnswerSet extends APIResource implements IAnswerSet {
  public static readonly TYPE: string = String('AnswerSet');

  public id: APIuuid;
  public answers: (Answer | IAPIRelationship)[];
  public created_at: APIDatetime;
  public reference_id: APIuuid;

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: AnswerSet.TYPE,
        attributes: {
          reference_id: this.reference_id
        },
        relationships: {
          answers: this.answers
        }
      }
    } as IAPIData;
    return data;
  }

  constructor(partial: Partial<IAnswerSet>) {
    super();

    Object.assign(this, partial);
  }

  public static fromAPI(answerSetInfo: IAPIData): IAnswerSet {
    const data = answerSetInfo.data;
    if (!(data && data.type === AnswerSet.TYPE)) {
      throw new Error('There was a problem parsing AnswerSet API Data');
    }

    const answerSet = new AnswerSet({
      id: data.id,
      ...data.attributes
    });

    answerSet.answers = Answer.fromAPIArray({
      data: answerSetInfo?.included.filter(
        (i: IAPIDataObject) => i.type === 'Answer'
      )
    });

    return answerSet;
  }
}
