import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appEventTracker]'
})
export class ButtonDirective {
  @Input('appEventTracker') private option: any;

  @HostListener('click', ['$event']) onClick(_$event: any) {
    const gtag = (window as any).gtag;

    if (gtag) {
      (window as any).gtag('event', 'click', {
        event_category: this.option.category,
        event_label: this.option.label
      });
    }
  }
}
