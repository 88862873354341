import { IAPIRelationship } from '@shared/interfaces/api.interface';
import { ISubscription } from '@shared/interfaces/subscription.interface';

export const buildSubscription = (
  subscription: any,
  relationships: any
): ISubscription => {
  subscription.plan = relationships.plan as IAPIRelationship;

  return subscription;
};
