import { Component, Input, OnInit } from '@angular/core';
import { QuestionType } from '@shared/enums/question.enum';
import { IAnswer } from '@shared/interfaces/answer.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { Options } from 'ng5-slider';
import { SessionStorage } from 'ngx-webstorage';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html'
})
export class AnswersComponent implements OnInit {
  @SessionStorage() public settings: ISetting;

  @Input() public title: string;
  @Input() public answers: IAnswer[] = [];
  @Input() public isEditable = Boolean(true);

  public openEditAnswerModal: Subject<any> = new Subject<any>();
  public questionType = QuestionType;

  public sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    disabled: true
  };

  constructor() {}

  @Input() public afterSubmit: any = () => {};

  ngOnInit(): void {}

  public edit(a: IAnswer) {
    this.openEditAnswerModal.next(a);
  }

  public trackByFn(i: any) {
    return i;
  }
}
