import { Component, Input, OnInit } from '@angular/core';
import { ICandidate } from '@shared/interfaces/candidate.interface';

@Component({
  selector: 'app-sidebar-candidate-about',
  templateUrl: './sidebar.candidate.about.component.html'
})
export class SidebarCandidateAboutComponent implements OnInit {
  @Input() public candidate: ICandidate;

  constructor() {}

  ngOnInit(): void {}
}
