import { Injectable } from '@angular/core';
import { AirbrakeErrorHandler } from '@shared/handlers/airbrake-error.handler';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LoggerService extends AirbrakeErrorHandler {
  constructor(private readonly _logger: NGXLogger) {
    super();
  }

  public error(type: string, request: string, err: any) {
    this._logger.error(type, `${request} failed`, err);
    this.handleError(err);
  }

  public info(type: string, request: string, res: any) {
    this._logger.info(type, request, res);
  }
}
