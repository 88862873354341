import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IPage } from '@shared/interfaces/page.interface';

export class Page implements IPage {
  public static readonly TYPE: string = String('Page');

  id: string;
  hiring_firm: string;
  category: string;
  content: string;
  cta_url: string;
  language: string;
  is_default: boolean;

  defaultLanguage = String('en');
  isSubmitting = Boolean(false);

  constructor(partial: Partial<IPage>) {
    Object.assign(this, partial);
  }

  get variables() {
    switch (this.category) {
      case 'reference':
      case 'feedback':
      case 'feedback_employer':
        return ['candidate_full_name', 'company_name'];

      case 'request':
        return ['nb_min_references'];

      default:
        return [];
    }
  }

  get options() {
    switch (this.category) {
      case 'hiring':
        return ['Staffing recruitment', 'Company career'];
      case 'careers_page':
        return ['Staffing recruitment', 'Company career'];

      default:
        return [];
    }
  }

  get label() {
    return this.category.toUpperCase();
  }

  static fromAPI(res: IAPIData): IPage {
    const data = res.data;

    if (!(data && data.type === Page.TYPE)) {
      throw new Error(`There was a problem parsing ${Page.TYPE} API data`);
    }

    return new Page({
      id: data.id,
      ...data.attributes
    });
  }

  static fromAPIArray(res: IAPIArrayData): IPage[] {
    return res.data.map((data: any) =>
      this.fromAPI({
        data,
        included: res.included
      })
    );
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: Page.TYPE,
        id: this.id,
        attributes: {
          hiring_firm: this.hiring_firm,
          category: this.category,
          content: this.content,
          cta_url: this.cta_url,
          language: this.language,
          is_default: this.is_default
        }
      }
    } as IAPIData;

    return data;
  }
}
