import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { Reference } from '@shared/models/reference.model';
import { Reminder } from '@shared/models/reminder.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/reminders`
  );

  constructor(private readonly _http: HttpClient) {}

  public get(): Observable<IReminder[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => Reminder.fromAPIArray(res)));
  }

  public getBulk(qs: any): Observable<any[]> {
    let params: HttpParams = new HttpParams();

    Object.keys(qs).map(
      (key: string) => (params = params.set(key, String(qs[key])))
    );

    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/bulk`, { params })
      .pipe(
        map((res: IAPIArrayData) => {
          const type: string = qs.type;

          let bulkArray;
          if (type === 'ReferenceRequest') {
            bulkArray = ReferenceRequest.fromAPIArray(res);
          } else {
            bulkArray = Reference.fromAPIArray(res);
          }

          return bulkArray;
        })
      );
  }

  public postBulk(body: any): Observable<IReminder[]> {
    return this._http
      .post<IAPIArrayData>(`${this.ENDPOINT_V3}/bulk`, body)
      .pipe(map((res: IAPIArrayData) => Reminder.fromAPIArray(res)));
  }

  public patch(body: IAPIData): Observable<IReminder> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => Reminder.fromAPI(res)));
  }
}
