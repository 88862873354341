import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { Feedback } from '@shared/models/feedback.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeedbacksService implements IPaginable {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/feedbacks`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: string): Observable<IFeedback> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => Feedback.fromAPI(res)));
  }

  public find(id: string): Observable<IFeedback> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => Feedback.fromAPI(res)));
  }

  public get(): Observable<IFeedback[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => Feedback.fromAPIArray(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    _?: ISort,
    dates?: IFilter[]
  ): Observable<IFeedback[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (dates) {
      dates.map((f: IFilter) => {
        if (!!f['value']) {
          params = params.set(`dates[${f['key']}]`, f['value']);
        }
      });
    }

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3, { params })
      .pipe(
        tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
        map((res: IAPIArrayData) => Feedback.fromAPIArray(res))
      );
  }

  public patch(body: IAPIData): Observable<IFeedback> {
    const id = body.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}`, body)
      .pipe(map((res: IAPIData) => Feedback.fromAPI(res)));
  }

  public post(body: IAPIData): Observable<IFeedback> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => Feedback.fromAPI(res)));
  }

  public sendFeedbackReminder(id: APIuuid): Observable<IReminder> {
    return this._http
      .post(`${environment.ENDPOINTS.BACK_END.V3}/reminders/feedbacks/${id}`, {})
      .pipe(map((res: any) => res));
  }
}
