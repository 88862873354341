import { Injectable } from '@angular/core';
import { ISetting } from '@shared/interfaces/setting.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public settings: Subject<ISetting> = new Subject<any>();

  constructor() {}

  public getSettings(): Observable<any> {
    return this.settings.asObservable();
  }

  public toggleSetting(setting: any) {
    this.settings.next(setting);
  }
}
