import {
  APIDatetime,
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IMetadatum } from '@shared/interfaces/metadatum.interface';

export class Metadatum implements IMetadatum {
  public static readonly TYPE: string = String('Metadatum');

  public id: APIuuid;
  public identifiable: APIuuid;
  public browser: string;
  public browser_version: string;
  public device: string;
  public ip_address: string;
  public os: string;
  public os_version: string;
  public user_agent: string;

  public created_at: APIDatetime;
  public updated_at: APIDatetime;

  constructor(partial: Partial<IMetadatum>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IMetadatum {
    const data = res.data;

    if (!(data && data?.type === Metadatum.TYPE)) {
      throw new Error(`There was a problem parsing ${Metadatum.TYPE} API Data`);
    }

    const metadatum: IMetadatum = new Metadatum({
      id: data.id,
      ...data.attributes
    });

    return metadatum;
  }

  static fromAPIArray(res: IAPIArrayData): IMetadatum[] {
    return res.data.map(
      (u: any) =>
        new Metadatum({
          id: u.id,
          ...u.attributes
        })
    );
  }

  public get apiData(): IAPIData {
    return {
      data: {
        id: this.id,
        type: Metadatum.TYPE,
        attributes: {
          identifiable: this.identifiable,
          browser: this.browser,
          browser_version: this.browser_version,
          device: this.device,
          ip_address: this.ip_address,
          os: this.os,
          os_version: this.os_version,
          user_agent: this.user_agent
        }
      }
    } as IAPIData;
  }
}
