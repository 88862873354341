import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IAPIArrayData } from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { Activity } from '@shared/models/activity.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService implements IPaginable {
  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/activities`
  );

  constructor(private readonly _http: HttpClient) {}

  public get(params: any = {}): Observable<IActivity[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V1, {
        params
      })
      .pipe(map((res: IAPIArrayData) => Activity.fromAPIArray(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    _sort?: ISort,
    dates?: IFilter[]
  ): Observable<IActivity[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (dates) {
      dates.map((f: IFilter, index: number) => {
        if (!!f && !!f['value'] && index < 2) {
          params = params.set(`dates[${f['key']}]`, f['value']);
        } else if (!!f && !!f['value'] && index === 2) {
          // HACK SINGLE FILTER
          // TO DO REFACTOR FROM SETFILTER TO SET FILTERS
          params = params.set(`filter[${f['key']}]`, f['value']);
        }
      });
    }

    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V1, { params })
      .pipe(
        tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
        map((res: IAPIArrayData) => Activity.fromAPIArray(res))
      );
  }
}
