import { IActivity } from '@shared/interfaces/activity.interface';
import {
  APIDatetime,
  APIuuid,
  IAPIArrayData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { Candidate } from '@shared/models/candidate.model';
import { QuestionSet } from '@shared/models/question-set.model';
import { Recruiter } from '@shared/models/recruiter.model';
import { Reference } from '@shared/models/reference.model';
import { User } from '@shared/models/user.model';

export class Activity implements IActivity {
  public static readonly TYPE: string = String('Activity');

  // Relationships
  trackable: any;
  owner: any;
  recipient?: any;

  // Attributes
  id: APIuuid;
  key: string;
  parameters: any;
  created_at: APIDatetime;

  constructor(partial: Partial<IActivity>) {
    Object.assign(this, partial);
  }

  get oldValues() {
    if (!this.parameters?.old) {
      return [];
    }

    return this.getList('Old', this.parameters?.old);
  }

  get newValues(): any {
    if (!this.parameters?.old) {
      return [];
    }

    return this.getList('New', this.parameters?.old);
  }

  get description() {
    // TO DO
    // reminders sent

    switch (this.key) {
      // References
      case 'recruiter_reference.create':
        if (this.owner instanceof Candidate) {
          // Candidate
          return `${
            !!this.owner?.name_label
              ? `Candidate <b>${this.owner.name_label}</b>`
              : 'A candidate'
          } added ${
            !!this.trackable?.name_label
              ? `<b>${this.trackable.name_label}</b> as a reference`
              : ' a reference'
          }`;
        } else {
          // Recruiter
          return `${
            !!this.owner?.name_label
              ? `Recruiter <b>${this.owner.name_label}</b>`
              : 'A recruiter'
          } added ${
            !!this.trackable?.name_label
              ? `<b>${this.trackable.name_label}</b> as a reference`
              : ' a reference'
          } for ${
            !!this.recipient?.name_label
              ? `Candidate <b>${this.recipient.name_label}</b>`
              : 'a candidate'
          }`;
        }

      case 'recruiter_reference.complete_reference':
      case 'profile_reference.complete_reference':
        return `${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b>`
            : 'Someone'
        } completed a reference for ${
          !!this.recipient?.name_label
            ? `Candidate <b>${this.recipient.name_label}</b>`
            : ' a candidate'
        }`;

      case 'recruiter_reference.update_reference':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } updated ${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b>'s details`
            : ' a reference'
        }`;

      case 'recruiter_reference.destroy':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } deleted a reference`;

      case 'recruiter_reference.referencer_contact_method_update':
        return `${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b>`
            : 'Someone'
        } updated their contact method`;

      case 'recruiter_request.complete_reference_request':
        return `${
          !!this.owner?.name_label
            ? `<b>${this.owner.name_label}</b>`
            : 'Someone'
        } completed a reference request`;

      // Candidate
      case 'candidate.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } added ${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b> as a candidate`
            : ' a candidate'
        }`;

      case 'candidate.update':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } updated ${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b>`
            : `a candidate`
        } 's details`;

      case 'candidate.update_status':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } has updated the status of ${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b>`
            : `a candidate`
        } `;

      case 'candidate.destroy':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } deleted ${
          !!this.trackable?.name_label
            ? `candidate <b>${this.trackable.name_label}</b>`
            : 'a candidate'
        }`;

      // Recruiter
      case 'recruiter.add_new':
      case 'recruiter.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } added ${
          !!this.trackable?.name_label
            ? `<b>${this.trackable.name_label}</b> as a recruiter`
            : ' a recruiter'
        }`;

      case 'recruiter.set_inactive':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } disabled ${
          !!this.trackable?.name_label
            ? `Recruiter <b>${this.trackable.name_label}'s</b>`
            : `a recruiter's`
        } account`;

      case 'recruiter.set_active':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } enabled ${
          !!this.trackable?.name_label
            ? `Recruiter <b>${this.trackable.name_label}'s</b>`
            : `a recruiter's`
        } account`;

      // Reference Requests
      case 'recruiter_request.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } sent a reference request to ${
          !!this.recipient?.name_label
            ? `Candidate <b>${this.recipient.name_label}</b>`
            : ' a candidate'
        }`;

      // Question Sets
      case 'question_set.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } added a question set ${
          !!this.trackable?.title ? `<b>${this.trackable.title}</b>` : ''
        }`;

      case 'question_set.destroy':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } deleted a question set`;

      case 'question_set.update':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } updated ${
          !!this.trackable?.title
            ? `<b>question set ${this.trackable.title}</b>`
            : 'a question set'
        } `;

      case 'profile_reference.create':
        return `${
          !!this.owner?.name_label
            ? `Candidate <b>${this.owner.name_label}</b>`
            : 'A candidate'
        } added a new reference${
          !!this.trackable?.name_label
            ? `: <b>${this.trackable.name_label}</b>`
            : ''
        }`;

      // Feedback
      case 'feedback.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } sent a feedback request to ${
          !!this.recipient?.name_label
            ? `Candidate <b>${this.recipient.name_label}</b>`
            : ' a candidate'
        }`;

      case 'feedback.external_create':
        return `Feedback created from ${
          !!this.parameters.external_authorization_type 
            ? `<b>${this.parameters.external_authorization_type}</b>` 
            : 'an'
        } integration <br/> Candidate: ${
          !!this.recipient?.name_label
            ? `<b>${this.recipient.name_label}</b>`
            : 'Not assigned'
        }; Assigned Recruiter: ${
          !!this.owner?.name_label
            ? `<b>${this.owner.name_label}</b>`
            : 'Not assigned'
        }; Scheduled Date: ${
          !!this.parameters.scheduled_date 
            ? `<b>${this.parameters.scheduled_date}</b>` 
            : ''
        }`;

      case 'feedback.complete_feedback':
        return `${
          !!this.owner?.name_label
            ? `<b>${this.owner.name_label}</b>`
            : 'Someone'
        } completed a feedback about ${
          !!this.recipient?.name_label
            ? `Recruiter <b>${this.recipient.name_label}</b>`
            : ' a recruiter'
        }`;

      case 'pre_screening.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } sent a pre-screening request to ${
          !!this.recipient?.name_label
            ? `Candidate <b>${this.recipient.name_label}</b>`
            : ' a candidate'
        }`;

      case 'pre_screening.complete_pre_screening':
        return `${
          !!this.owner?.name_label
            ? `<b>${this.owner.name_label}</b>`
            : 'Someone'
        } completed a pre-screening request`;

      case 'background_checking.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } sent a background checking to ${
          !!this.recipient?.name_label
            ? `Candidate <b>${this.recipient.name_label}</b>`
            : ' a candidate'
        }`;

      case 'background_checking.complete_background_checking':
        return `${
          !!this.owner?.name_label
            ? `<b>${this.owner.name_label}</b>`
            : 'Someone'
        } completed a background checking submitted by ${
          !!this.recipient?.name_label
            ? `Recruiter <b>${this.recipient.name_label}</b>`
            : ' a recruiter'
        }`;

      case 'verification_request.create':
        return `${
          !!this.owner?.name_label
            ? `Recruiter <b>${this.owner.name_label}</b>`
            : 'A recruiter'
        } sent a verification request to ${
          !!this.recipient?.name_label
            ? `Candidate <b>${this.recipient.name_label}</b>`
            : ' a candidate'
        }`;

      case 'verification_request.complete_verification_request':
        return `${
          !!this.owner?.name_label
            ? `<b>${this.owner.name_label}</b>`
            : 'Someone'
        } completed a verification request submitted by ${
          !!this.recipient?.name_label
            ? `Recruiter <b>${this.recipient.name_label}</b>`
            : ' a recruiter'
        }`;

      // Users
      case 'user.login':
        return `${
          !!this.trackable?.full_name
            ? `<b>${this.trackable.full_name}</b>`
            : 'Someone'
        } logged in`;

      case 'user.logout':
        return `${
          !!this.trackable?.full_name
            ? `<b>${this.trackable.full_name}</b>`
            : 'Someone'
        } logged out`;

      case 'user.failed_attempt':
        return `${
          !!this.trackable?.full_name
            ? `<b>${this.trackable.full_name}</b>`
            : 'Someone'
        } attempted to log in with wrong password`;

      case 'user.locked_out':
        return `${
          !!this.trackable?.full_name
            ? `<b>${this.trackable.full_name}</b>`
            : 'Someone'
        } has been locked out`;

      case 'user.password_updated':
        return `${
          !!this.trackable?.full_name
            ? `<b>${this.trackable.full_name}</b>`
            : 'Someone'
        } has updated their password`;

      default:
        return '-';
    }
  }

  public static fromAPIArray(res: IAPIArrayData): IActivity[] {
    const dataArray = res.data || [];
    const included = res.included as any;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data?.type === Activity.TYPE)) {
        throw new Error(
          `There was a problem parsing ${Activity.TYPE} API data`
        );
      }

      const activity = new Activity({
        id: data.id,
        ...data.attributes
      });

      const relationships = data?.relationships as any;
      const ownerRelationship = relationships.owner as IAPIRelationship;
      const trackableRelationship = relationships.trackable as IAPIRelationship;
      const recipientRelationship = relationships.recipient as IAPIRelationship;

      const ownerData = included.find(
        (i: IAPIDataObject) => i.id === ownerRelationship.id
      );

      const trackableData = included.find(
        (i: IAPIDataObject) => i.id === trackableRelationship.id
      );

      activity.owner = this.initRelatedData(ownerData, included);
      activity.trackable = this.initRelatedData(trackableData, included);

      if (recipientRelationship) {
        const recipientData = included.find(
          (i: IAPIDataObject) =>
            i.type === recipientRelationship.type &&
            i.id === recipientRelationship.id
        );

        activity.recipient = this.initRelatedData(recipientData, included);
      }

      return activity;
    });
  }

  public static initRelatedData(data: any, included: any[] = []): any {
    if (!data) {
      return null;
    }

    switch (data.type) {
      case 'Recruiter':
        return Recruiter.fromAPI({
          data,
          included
        });

      case 'Candidate':
        return new Candidate({
          id: data.id,
          ...data.attributes
        });

      case 'RecruiterReference':
      case 'Reference':
        return new Reference({
          id: data.id,
          ...data.attributes
        });

      case 'QuestionSet':
        return new QuestionSet({
          id: data.id,
          ...data.attributes
        });

      case 'User':
        return new User({
          id: data.id,
          ...data.attributes
        });

      default:
        return null;
    }
  }

  private getList(type: string, obj: any) {
    const parsed = JSON.parse(obj);

    const entries: string[] = [];
    Object.entries(parsed).map((key) => {
      entries.push(`${type} <b>${key[0]}</b> value: <b>${key[1]}</b>`);
    });

    return entries;
  }
}
