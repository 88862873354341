import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html'
})
export class EmptyComponent {
  @Input() public rows: any[] = [];
  @Input() public type: string;

  constructor() {}
}
