import { replaceAll } from '@shared/helpers/utils.helper';
import {
  APIDate,
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Candidate } from '@shared/models/candidate.model';
import { HiringFirm } from '@shared/models/hiring-firm.model';
import { Page } from '@shared/models/page.model';
import { QuestionSet } from '@shared/models/question-set.model';
import { Setting } from '@shared/models/setting.model';
import { User } from '@shared/models/user.model';

export class ReferenceRequest extends APIResource implements IReferenceRequest {
  public static readonly TYPE: string = String('ReferenceRequest');

  public static readonly TYPES: string[] = [
    'ReferenceRequest',
    'ProfileRequest',
    'RecruiterRequest'
  ];

  public id: APIuuid;
  public question_set: IQuestionSet;
  public candidate?: ICandidate;
  public request_by?: IRecruiter;
  public hiringFirm?: IHiringFirm;
  public min_manager_references: number;
  public notes: string;
  public language: string;
  public filled?: boolean;
  public reminder: IReminder;
  public settings: ISetting;
  public is_active: boolean;
  public is_manager_only: boolean;
  public created_at: APIDate;
  public updated_at: APIDate;
  public user: IUser;
  public pages: IPage[];
  public scheduled_date: APIDate;

  constructor(partial: Partial<IReferenceRequest>) {
    super();

    Object.assign(this, partial);
  }

  get label() {
    return this.question_set.title;
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: ReferenceRequest.TYPE,
        attributes: {
          min_manager_references: this.min_manager_references,
          is_manager_only: this.is_manager_only,
          notes: this.notes,
          is_active: this.is_active,
          language: this.language,
          scheduled_date: this.scheduled_date
        },
        relationships: {
          question_set: this.question_set?.apiRelationship,
          candidate: this.candidate?.apiRelationship,
          request_by: this.request_by?.apiRelationship
        }
      }
    } as IAPIData;

    if (this.id) {
      data.data.id = this.id;
    }

    return data;
  }

  static fromAPI(res: IAPIData): IReferenceRequest {
    const data = res.data;
    const included = res.included;

    if (!(data && ReferenceRequest.TYPES.indexOf(data.type) !== -1)) {
      throw new Error('There was a problem parsing ReferenceRequest API data');
    }

    const referenceRequest = new ReferenceRequest({
      id: data.id,
      ...data.attributes
    });

    const relationships = data.relationships;

    if (data.type === 'QuestionSet') {
      referenceRequest.question_set = QuestionSet.fromAPI({
        data:
          included &&
          included.find(
            (i: IAPIDataObject) =>
              i.id === (relationships.question_set as IAPIRelationship).id
          ),
        included
      });
    }

    if (data.type === 'ReferenceRequest' || data.type === 'RecruiterRequest') {
      referenceRequest.candidate = Candidate.fromAPI({
        data:
          included &&
          included.find(
            (i: IAPIDataObject) =>
              i.id === (relationships.candidate as IAPIRelationship).id
          ),
        included
      });

      referenceRequest.hiringFirm = HiringFirm.fromAPI({
        data:
          included &&
          included.find(
            (i: IAPIDataObject) =>
              i.id === (relationships.hiring_firm as IAPIRelationship).id
          ),
        included
      });

      if (relationships.pages !== undefined) {
        referenceRequest.pages = Page.fromAPIArray({
          data:
            ((included as any) &&
              (included as any)?.filter(
                (i: IAPIDataObject) => i.type === 'Page'
              )) ||
            ([] as IAPIRelationship[]),
          included
        });

        if (referenceRequest.pages) {
          const nbMinReferences =
            referenceRequest.min_manager_references > 0
              ? referenceRequest.min_manager_references
              : 2;

          const companyName = referenceRequest.hiringFirm.name || 'Vitay.io';
          referenceRequest.pages.map((p: IPage) => {
            p.content = replaceAll(p.content, {
              nb_min_references: nbMinReferences,
              company_name: companyName
            });
          });
        }
      }

      if (relationships.settings !== undefined) {
        referenceRequest.settings = Setting.fromAPI({
          data:
            included &&
            included.find(
              (i: IAPIDataObject) =>
                i.id === (relationships.settings as IAPIRelationship).id
            ),
          included
        });
      }

      if (relationships.user) {
        referenceRequest.user = User.fromAPI({
          data:
            included &&
            included.find(
              (i: IAPIDataObject) =>
                i.id === (relationships.user as IAPIRelationship).id
            ),
          included
        });
      }
    }

    return referenceRequest;
  }

  static fromAPIArray(res: IAPIArrayData): IReferenceRequest[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      const referenceRequest = new ReferenceRequest({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;
      if (relationships && relationships.question_set) {
        referenceRequest.question_set = QuestionSet.fromAPI({
          data:
            included &&
            included.find(
              (i: IAPIDataObject) =>
                i.id === (relationships.question_set as IAPIRelationship).id
            ),
          included
        });
      }

      return referenceRequest;
    });
  }
}
