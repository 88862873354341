import { environment } from '@environment/environment';
import { IIntegration } from '@shared/interfaces/integration.interface';

const assetsUrl = `${environment.ENDPOINTS.ASSETS}/images/integrations`;

export const externalAuthorizations: IIntegration[] = [
  // {
  //   id: 'avionte',
  //   label: 'avionte',
  //   isAuthorized: false,
  //   isLoading: false,
  //   isSetup: false,
  //   isAvailable: false,
  //   logo: `${assetsUrl}/avionte.png`
  // },
  {
    id: 'bullhorn',
    label: 'bullhorn',
    isAuthorized: false,
    isLoading: false,
    isSetup: false,
    isAvailable: true,
    logo: `${assetsUrl}/bullhorn.png`
  },
  {
    id: 'erecruit',
    label: 'erecruit',
    isAuthorized: false,
    isLoading: false,
    isSetup: false,
    isAvailable: true,
    logo: `${assetsUrl}/erecruit.png`
  },
  // {
  //   id: 'icims',
  //   label: 'icims',
  //   isAuthorized: false,
  //   isLoading: false,
  //   isSetup: false,
  //   isAvailable: false,
  //   logo: `${assetsUrl}/icims.png`
  // },
  {
    id: 'jobadder',
    label: 'jobadder',
    isAuthorized: false,
    isLoading: false,
    isSetup: false,
    isAvailable: true,
    logo: `${assetsUrl}/jobadder.png`
  },
  {
    id: 'jobdiva',
    label: 'jobdiva',
    isAuthorized: false,
    isLoading: false,
    isSetup: false,
    isAvailable: true,
    logo: `${assetsUrl}/jobdiva.png`
  },
  // {
  //   id: 'salesforce',
  //   label: 'salesforce',
  //   isAuthorized: false,
  //   isLoading: false,
  //   isSetup: false,
  //   isAvailable: true,
  //   logo: `${assetsUrl}/salesforce.png`
  // },
  // {
  //   id: 'salesforce_isv',
  //   label: `salesforce <span class='text-uppercase'>ISV</span>`,
  //   isAuthorized: false,
  //   isLoading: false,
  //   isSetup: false,
  //   isAvailable: true,
  //   logo: `${assetsUrl}/salesforce.png`
  // },
  // {
  //   id: 'taleo',
  //   label: 'taleo',
  //   isAuthorized: false,
  //   isLoading: false,
  //   isSetup: false,
  //   isAvailable: false,
  //   logo: `${assetsUrl}/taleo.png`
  // },
  {
    id: 'target_recruit',
    label: 'target recruit',
    isAuthorized: false,
    isLoading: false,
    isSetup: false,
    isAvailable: true,
    logo: `${assetsUrl}/target-recruit.png`
  },

  {
    id: 'greenhouse',
    label: 'greenhouse',
    isAuthorized: false,
    isLoading: false,
    isSetup: false,
    isAvailable: true,
    logo: `http://127.0.0.1:5000/assets/greenhouse.png`
  }
];
