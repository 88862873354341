import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IActivity } from '@shared/interfaces/activity.interface';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IConnection } from '@shared/interfaces/connection.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Activity } from '@shared/models/activity.model';
import { Connection } from '@shared/models/connection.model';
import { HiringFirm } from '@shared/models/hiring-firm.model';
import { User } from '@shared/models/user.model';
import { SessionStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  @SessionStorage() public user: IUser;

  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/users`
  );

  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/users`
  );

  constructor(private readonly _http: HttpClient) {}

  public getHiringFirms(id: string): Observable<IHiringFirm[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/hiring_firms`)
      .pipe(map((res: IAPIArrayData) => HiringFirm.fromAPIArray(res)));
  }

  public getMyself() {
    return this._http
      .get<IAPIData>(`${environment.ENDPOINTS.BACK_END.V1}/me`)
      .pipe(map((res: IAPIData) => User.fromAPI(res)));
  }

  public get(body?: any): Observable<any> {
    return this._http
      .get<IAPIData>(`${environment.ENDPOINTS.BACK_END.V1}/me`, {
        params: body
      })
      .pipe(map((res: IAPIData) => (this.user = User.fromAPI(res))));
  }

  public getConnections(id: string): Observable<IConnection[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V1}/${id}/connections`)
      .pipe(map((res: IAPIArrayData) => Connection.fromAPIArray(res)));
  }

  public getUserActivities(id: APIuuid): Observable<IActivity[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/activities`)
      .pipe(map((res: IAPIArrayData) => Activity.fromAPIArray(res)));
  }

  public patch(user: any): Observable<IUser> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${user.data.id}`, user)
      .pipe(map((res: IAPIData) => User.fromAPI(res)));
  }

  public patchRoles(id: string, body: any): Observable<IUser> {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}/roles`, body)
      .pipe(map((res: IAPIData) => User.fromAPI(res)));
  }

  public resolve(): Observable<User> {
    return this.get();
  }

  public sendConnectionInvitation(id: APIuuid) {
    return this._http.get(
      `${environment.ENDPOINTS.BACK_END.V1}/connections/${id}/invite`
    );
  }
}
