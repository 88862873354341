import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { TableColType } from '@shared/enums/table.enum';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { LoggerService } from '@shared/services/logger.service';
import { RemindersService } from '@shared/services/reminders.service';
import { ToastService } from '@shared/services/toast.service';
import moment from 'moment';

@Component({
  selector: 'app-modals-reminders',
  templateUrl: './modals.reminders.component.html'
})
export class ModalsRemindersComponent
  extends ModalDirective<any>
  implements OnInit
{
  public title = String('MODALS.REMINDERS.TITLE');
  public description = String('MODALS.REMINDERS.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public isButtonsDisabled = Boolean(false);
  public isConfirm = Boolean(false);
  public type = String('');

  public bulk: (IReferenceRequest | IReference)[] = [];

  public cols: Partial<ITableCol>[] = [
    {
      cellClassName: 'v-text--highlight',
      type: TableColType.Text,
      selector: 'label'
    }
  ];

  public rows: any[] = [
    {
      label: 'Send reference request reminders to candidates',
      type: 'ReferenceRequest'
    },
    {
      label: 'Send references reminders to references',
      type: 'RecruiterReference'
    }
  ];

  public entry: FormGroup;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _reminders: RemindersService,
    private readonly _fb: FormBuilder,
    private readonly _toast: ToastService
  ) {
    super();

    this.onRowClick = this.onRowClick.bind(this);
  }

  ngOnInit(): void {
    this.createForm();
  }

  public onDismiss() {
    this.isConfirm = false;
    this.entry.reset();
  }

  public toggleConfirm() {
    this.isConfirm = !this.isConfirm;
  }

  public onSubmit(): void {
    const values = {
      data: this.bulk.map((i: IReference | IReferenceRequest) => ({
        id: i.id,
        type: i.apiData.data.type
      }))
    };

    const url = 'POST /reminders/bulk';
    this._reminders.postBulk(values).subscribe(
      (res: IReminder[]) => {
        this._logger.info(this.constructorName, url, res);
        this._toast.success('Reminders sent');

        this.onDismiss();
        this.resetModal(res);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this._toast.success('Reminders failed');
      }
    );
  }

  public onRowClick(r: any) {
    this.type = r.type;
    this.isConfirm = true;

    const daterange = {
      start_date: moment()
        .subtract(15, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment()
        .subtract(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    };

    this.entry.patchValue({
      type: this.type,
      start_date: daterange.start_date,
      end_date: daterange.end_date
    });

    this.getReminders();
  }

  protected createForm() {
    this.entry = this._fb.group({
      type: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]]
    });
  }

  private getReminders() {
    const url = 'GET /reminders/bulk';
    const values = this.entry.value;
    this._reminders.getBulk(values).subscribe(
      (res: any[]) => {
        this._logger.info(this.constructorName, url, res);

        this.bulk = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
