import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IFrameService {
  public isIframe = Boolean(window !== window.parent);

  private iframeServiceSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  constructor(private readonly _http: HttpClient) {}

  public getIFrameService(): Observable<string> {
    return this.iframeServiceSubject.asObservable();
  }

  public setIFrameService(service: string): void {
    this.iframeServiceSubject.next(service);
  }

  public loadBullhornCandidate(type: string, id: string): Observable<any> {
    return this._http.get(
      `${environment.SERVER_ENDPOINT}/api/iframe/bullhorn`,
      {
        params: {
          entity_type: type,
          entity_id: id
        }
      }
    );
  }

  public loadJobadderCandidate(type: string, id: string): Observable<any> {
    return this._http.get(
      `${environment.SERVER_ENDPOINT}/api/iframe/jobadder`,
      {
        params: {
          entity_type: type,
          entity_id: id
        }
      }
    );
  }

  public loadTempworksCandidate(type: string, id: string): Observable<any> {
    return this._http.get(
      `${environment.SERVER_ENDPOINT}/api/iframe/tempworks`,
      {
        params: {
          entity_type: type,
          entity_id: id
        }
      }
    );
  }
}
