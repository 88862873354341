import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import {
  QuestionCategories,
  QuestionType,
  QuestionTypes
} from '@shared/enums/question.enum';
import { IQuestionGroup } from '@shared/interfaces/question-group.interface';
import { IQuestionable } from '@shared/interfaces/questionable.interface';
import { IQuestion } from '@shared/interfaces/question.interface';
import { Question } from '@shared/models/question.model';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionGroupsService } from '@shared/services/question-groups.service';
import { QuestionsService } from '@shared/services/questions.service';
import { ToastService } from '@shared/services/toast.service';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { LabelValue } from '@shared/interfaces/form.interface';

@Component({
  selector: 'app-modals-questions',
  templateUrl: './modals.questions.component.html'
})
export class ModalsQuestionsComponent
  extends ModalDirective<IQuestion>
  implements OnInit, OnDestroy
{
  @Input() public questionable: IQuestionable;
  @Input() public language = String('en');

  public isButtonsDisabled = Boolean(false);
  public isPreview = Boolean(false);
  public isOptionsAvailable = Boolean(false);
  public isColumnsAvailable = Boolean(false);
  public isAddColumnAvailable = Boolean(true);

  public title = String('MODALS.QUESTIONS.ADD.TITLE');
  public deleteKey: string;
  public submitKey = String('BUTTONS.SAVE');
  public entry: FormGroup;

  public questionTypes = QuestionTypes;
  public questionCategories = QuestionCategories;
  public questionType = QuestionType;
  public questionGroups: IQuestionGroup[] = [];

  public defaultRatingLabel = 'Lowest/Highest';
  public ratingLabel = 'Lowest/Highest';
  public questionRatingPairs: LabelValue[] = [
    {
      label: 'Poor/Great',
      value: 'Poor/Great'
    },
    {
      label: 'Never/Always',
      value: 'Never/Always'
    },
    {
      label: 'Dissatisfied/Satisfied',
      value: 'Dissatisfied/Satisfied'
    },
    {
      label: 'Disagree/Agree',
      value: 'Disagree/Agree'
    },
    {
      label: 'Unsatisfactory/Outstanding',
      value: 'Unsatisfactory/Outstanding'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);
  private questionTypeSubscription: Subscription;
  private subs = new Subscription();
  private questionOptions: { id: string; order: number }[] = [];

  constructor(
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _questions: QuestionsService,
    private readonly _questionGroups: QuestionGroupsService,
    private readonly _toast: ToastService,
    private readonly _dragulaService: DragulaService
  ) {
    super();
    this.createItem = this.createItem.bind(this);

    this.subs.add(
      this._dragulaService.drop('QUESTION-OPTIONS').subscribe(({ target }) => {
        this.questionOptions = [];
        const options = target.getElementsByClassName('question-option');

        for (let i = 0, len = options.length; i < len; i++) {
          this.questionOptions.push({ id: options[i].id, order: i + 1 });
        }
      })
    );
  }

  public get formArrayOptions(): FormArray {
    return this.entry.get('options') as FormArray;
  }

  public get formArrayColumns(): FormArray {
    return this.entry.get('columns') as FormArray;
  }

  ngOnInit(): void {
    this.openModal.subscribe((q: IQuestion) => {
      this.getQuestionGroups();

      this.isPreview = false;

      if (q) {
        this.deleteKey = 'BUTTONS.DELETE';
        this.title = 'MODALS.QUESTIONS.EDIT.TITLE';

        this.entry.patchValue(q);

        if (q.options) {
          q.options.map((o: any) => this.addItem('options', o));

          this.isOptionsAvailable = true;
          this.entry.patchValue({
            ratingLabel: q.options[0].value
          });
        }

        if (q.columns) {
          q.columns.map((c: any) => this.addItem('columns', c));
        }

        this.checkColumnsOptions(q.type);
      }

      this.entry.patchValue({
        language: this.language
      });
    });

    this.createForm();
  }

  ngOnDestroy(): void {
    this.questionTypeSubscription?.unsubscribe();
  }

  public changeType() {
    const type = this.entry.get('type').value;
    const optionsField = this.formArrayOptions as FormArray;
    const columnsField = this.formArrayColumns as FormArray;

    // get rid of options
    optionsField.clear();
    optionsField.clearValidators();

    columnsField.clear();
    columnsField.clearValidators();

    this.checkColumnsOptions(type);

    if (this.isOptionsAvailable) {
      [0, 1].map(() => {
        this.addItem('options');
      });

      optionsField.setValidators([
        Validators.required,
        Validators.minLength(2)
      ]);
    }

    if (type === QuestionType.Matrix) {
      [0, 1, 2, 3, 4].map(() => {
        this.addItem('columns');
      });

      optionsField.setValidators([
        Validators.required,
        Validators.minLength(1)
      ]);

      columnsField.setValidators([Validators.required]);
    }

    optionsField.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    columnsField.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });

    const commentableField = this.entry.get('commentable');
    if (type === QuestionType.Text) {
      commentableField.patchValue(false);
    }
  }

  public changeRatingLabel() {
    const ratingLabel =
      this.entry.get('ratingLabel').value || this.defaultRatingLabel;
    this.deleteItem('options', 0);
    this.addItem('options', { value: ratingLabel });
  }

  public whenModalClose(type: string): void {
    this.errors = [];
    if (type === 'SUBMIT') {
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete(): void {
    this.errors = [];
    const id = this.entry.get('id').value;

    if (id) {
      const url = `DELETE /questions/${id}`;
      this._questions.delete(id).subscribe(
        (res: IQuestion) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success('Question deleted');
          this.resetModal();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.errors = err;
        }
      );
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }) {
    let counter = 0;

    if (this.questionOptions.length === 0) {
      value.options.forEach((formQuestion: any, i: number) => {
        formQuestion.order = i + 1;
        counter += 1;
      });
    } else {
      value.options.forEach((formQuestion: any) => {
        this.questionOptions.forEach((question) => {
          if (formQuestion.id === question.id) {
            formQuestion.order = question.order;
          }
          counter += 1;
        });
      });
    }

    if (counter === 1 && value.type !== this.questionType.Rating) {
      return;
    }

    if (valid) {
      if (value.type === this.questionType.Rating && !value.options.length) {
        value.options = [
          {
            id: '',
            value: this.defaultRatingLabel,
            warning: '',
            weight: 1,
            order: 1
          }
        ];
      }
      this.errors = [];
      this.isButtonsDisabled = true;
      this.entry.disable();

      const id = value.id;
      const data = new Question(value).apiData;
      this.questionTypeSubscription?.unsubscribe();
      this.entry.disable();

      if (id) {
        this.patchQuestion(data);
      } else {
        this.postQuestion(data);
      }
    }
  }

  public resetForm() {
    this.entry.enable();
    super.resetForm();
  }

  public onDismiss() {
    this.errors = [];
    this.entry.enable();
    this.isOptionsAvailable = false;
    this.isColumnsAvailable = false;
    super.onDismiss();
  }

  public addItem(type: string, o?: any) {
    const options = this.entry.get(type) as FormArray;

    // Check if the type is 'columns' and limit the number to 5
    if (type === 'columns' && options.length >= 5) {
      this._toast.error('Maximum of 5 columns allowed');
      return;
    }

    options.push(this.createItem(o));
  }

  public deleteItem(type: string, i: number) {
    const options = this.entry.get(type) as FormArray;
    options.removeAt(i);
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      questionable_id: [this.questionable?.id, [Validators.required]],
      questionable_type: ['QuestionSet', [Validators.required]], // TODO make it dynamic
      language: [this.language || 'en', [Validators.required]],
      value: ['', [Validators.required]],
      type: ['', [Validators.required]],
      category: ['', [Validators.required]],
      options: this._fb.array([]),
      columns: this._fb.array([]),
      order: [this.questionable?.questions?.length, [Validators.required]],
      commentable: [false, [Validators.required]],
      is_comment_required: [false, [Validators.required]],
      question_group: this._fb.group({
        id: ['']
      }),
      ratingLabel: [this.ratingLabel || '']
    });
  }

  private checkColumnsOptions(type: string) {
    this.isOptionsAvailable =
      type === QuestionType.Dropdown ||
      type === QuestionType.Radio ||
      type === QuestionType.Checkbox ||
      type === QuestionType.Ranking ||
      type === QuestionType.Matrix;

    this.isColumnsAvailable = type === QuestionType.Matrix;
  }

  private createItem(o?: any) {
    return this._fb.group({
      id: [o?.id || '', []],
      value: [o?.value || '', [Validators.required]],
      warning: [o?.warning || ''],
      weight: [o?.weight || this.entry.get('columns').value.length + 1],
      order: [o?.order || '']
    });
  }

  private patchQuestion(data: any) {
    const id = data.data.id;
    const url = `PATCH / questions / ${id}`;

    this._questions.patch(data).subscribe(
      (res: IQuestion) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal(res);
        this._toast.success('Question updated');
        this.entry.enable();
        this.isButtonsDisabled = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.entry.enable();
        this.isButtonsDisabled = false;
      }
    );
  }

  private postQuestion(data: any) {
    const id = data.data.id;
    const url = `POST /questions/${id}`;

    this._questions.post(data).subscribe(
      (res: IQuestion) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal(res);
        this._toast.success('Question added'); // TO DO: Toast translate
        this.entry.enable();
        this.isButtonsDisabled = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.entry.enable();
        this.isButtonsDisabled = false;
      }
    );
  }

  private getQuestionGroups() {
    const url = `GET /question_groups`;
    this._questionGroups.get().subscribe(
      (res: IQuestionGroup[]) => {
        this._logger.info(this.constructorName, url, res);
        this.questionGroups = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
      }
    );
  }
}
