import { buildCustomer } from '@shared/builders/customer.helper';
import {
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { ICard } from '@shared/interfaces/card.interface';
import { ICustomer } from '@shared/interfaces/customer.interface';

export class Customer implements ICustomer {
  public static readonly TYPE: string = String('Customer');

  id: string;
  hiring_firm_id: string;
  default_source: string;
  status: string;
  is_modal_trial_ended_enabled: boolean;

  cards: ICard[];

  constructor(partial: Partial<ICustomer>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): ICustomer {
    const data = res.data;

    if (!(data && data.type === Customer.TYPE)) {
      throw new Error(`There was a problem parsing ${Customer.TYPE} API data`);
    }

    const customer = new Customer({
      id: data.id,
      ...data.attributes
    });

    return buildCustomer(customer, data.relationships, res.included);
  }

  static fromAPIArray(res: IAPIArrayData): ICustomer[] {
    return res.data.map((c: IAPIDataObject) =>
      this.fromAPI({
        data: c,
        included: res.included
      })
    );
  }
}
