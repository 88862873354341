import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { EmailValidation } from '@shared/models/email-validation.model';
import { EmailValidationService } from '@shared/services/email-validation.service';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  switchMap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailValidityValidator {
  constructor(private readonly _emailValidation: EmailValidationService) {}

  get required(): ValidatorFn {
    return (control: AbstractControl) => {
      this._emailValidation.resetEmailWarnings();
      return !!control.value ? null : { required: true };
    };
  }

  get validatesAsync(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      this._emailValidation.resetEmailWarnings();
      return control.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(() => this._emailValidation.validateEmail(control.value)),
        map((validation: EmailValidation) =>
          validation.is_valid ? null : { InvalidEmail: true }
        ),
        first()
      );
    };
  }
}
