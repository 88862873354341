import { buildAddress } from '@shared/builders/address.helper';
import { IAddress } from '@shared/interfaces/address.interface';
import {
  APIDatetime,
  IAPIArrayData,
  IAPIData,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IReference } from '@shared/interfaces/reference.interface';

export class Address implements IAddress {
  public static readonly TYPE: string = String('Address');

  public id: string;
  public unit: string;
  public line1: string;
  public country: string;
  public city: string;
  public state: string;
  public postal_code: string;

  public identifiable: Partial<IReference | IEmployer>;

  public created_at: APIDatetime;
  public updated_at: APIDatetime;

  public get apiRelationship(): IAPIRelationship {
    return {
      type: Address.TYPE,
      id: this.id
    };
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: Address.TYPE,
        id: this.id,
        attributes: {
          unit: this.unit,
          line1: this.line1,
          country: this.country,
          city: this.city,
          state: this.state,
          postal_code: this.postal_code
        },
        relationships: {
          identifiable: this.identifiable?.apiRelationship
        }
      }
    } as IAPIData;

    return data;
  }

  constructor(partial: Partial<IAddress>) {
    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): IAddress {
    const data = res.data;

    if (!(data && data.type === Address.TYPE)) {
      throw new Error(`There was a problem parsing ${Address.TYPE} API data`);
    }

    const address = new Address({
      id: data.id,
      ...data.attributes
    });

    return buildAddress(address, data.relationships, res.included);
  }

  public static fromAPIArray(res: IAPIArrayData): IAddress[] {
    return res.data.map((c: any) =>
      this.fromAPI({
        data: c,
        included: res.included
      })
    );
  }
}
