import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { TableColType } from '@shared/enums/table.enum';
import { IDocument } from '@shared/interfaces/document.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { DocumentsService } from '@shared/services/documents.service';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { ToastService } from '@shared/services/toast.service';
import FileSaver from 'file-saver';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-modals-import',
  templateUrl: './modals.import.component.html'
})
export class ModalsImportComponent
  extends ModalDirective<any>
  implements OnInit
{
  @Input() public type: string;

  public entry: FormGroup;
  public title = String('MODALS.IMPORT.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');
  public isButtonsDisabled = Boolean(false);
  public isChecked = Boolean(false);
  public isSubmitAvailable = Boolean(false);
  public items: IQuestionSet[] = [];
  public document: IDocument;
  public file: File;
  public params: any = {};

  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.ERRORS_DETAILS',
      type: TableColType.Text,
      cellClassName: 'text-danger small',
      selector: 'detail'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _documents: DocumentsService
  ) {
    super();
  }

  public get dropZoneLabel(): string {
    return this.isChecked
      ? this.file.name
      : `Drop .csv file for ${this.type} here`;
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe(() => {
      this.entry.patchValue({
        type: this.type
      });

      this.getQuestionSets();
    });
  }

  public onDismiss() {
    this.errors = [];
    this.entry.enable();

    this.resetAll();
  }

  public downloadTemplate() {
    const template = `templates_${this.type}`;
    const url = `GET /documents?type=${template}`;
    this._documents
      .get({
        type: template,
        endpoint: 'csv'
      })
      .subscribe(
        (res: Blob) => {
          this._logger.info(this.constructorName, url, res);

          FileSaver.saveAs(res, template);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
        }
      );
  }

  public onFileDrop(files: NgxFileDropEntry[]) {
    this.errors = [];
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const size = file.size / 1000000;

          if (size > 15) {
            return this._toast.error('File is too big');
          }

          this.file = file;

          const url = 'POST /documents/csv';
          this._documents
            .post(file, {
              ...this.entry.value,
              is_check: true
            })
            .subscribe(
              (res: IDocument) => {
                this._logger.info(this.constructorName, url, res);

                this.document = res;

                if (
                  this.document.statistics.new_entries === 0 &&
                  this.document.statistics.duplicate_entries === 0 &&
                  this.document.errors?.length > 0
                ) {
                  this._toast.error(
                    'Check failed. Please review your CSV file format'
                  );
                } else if (this.document.errors?.length === 0) {
                  this.params = {
                    ...this.document.statistics,
                    type: this.type
                  };
                  this.isChecked = true;

                  if (this.document.statistics.new_entries > 0) {
                    this.isSubmitAvailable = true;
                  }
                }

                this.errors = this.document.errors.slice(0, 5);
                this.isButtonsDisabled = false;
              },
              (err: any) => {
                this._logger.error(this.constructorName, url, err);

                this._toast.error(err[0].detail);
                this.isChecked = false;
                this.isButtonsDisabled = false;
                this.isSubmitAvailable = false;
              }
            );
        });
      } else {
        this._toast.error('Not a CSV file');
      }
    }
  }

  public onSubmit({
    value,
    valid
  }: {
    value: FormGroup;
    valid: boolean;
  }): void {
    if (valid) {
      this.isButtonsDisabled = true;

      const url = 'POST /documents/uploads';
      this._documents.post(this.file, value).subscribe(
        (res: IDocument) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success('Candidates added');
          this.isButtonsDisabled = false;

          this.onDismiss();
          this.resetModal(res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this._toast.error(err[0].detail);
          this.isChecked = false;
          this.isButtonsDisabled = false;
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      endpoint: ['csv', [Validators.required]],
      type: ['', [Validators.required]],
      question_set_id: [''],
      headers: [true, [Validators.required, Validators.pattern('true')]]
    });
  }

  private getQuestionSets() {
    this.items = [];

    const filters = {
      page_size: 50
    };

    const url = 'GET /question_sets';
    this._questionSets.get(filters).subscribe(
      (res: IQuestionSet[]) => {
        this._logger.info(this.constructorName, url, res);
        this.items = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private resetAll() {
    this.entry.enable();

    this.file = null;
    this.isButtonsDisabled = false;
    this.isChecked = false;
    this.isSubmitAvailable = false;
  }
}
