import { buildPreScreening } from '@shared/builders/pre-screening.helper';
import { IAnswerSet } from '@shared/interfaces/answer-set.interface';
import {
  APIDatetime,
  APIResource,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { IPreScreening } from '@shared/interfaces/pre-screening.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';

export class PreScreening extends APIResource implements IPreScreening {
  public static readonly TYPE: string = String('PreScreening');

  // Relationships
  public candidate: ICandidate;
  public question_set: IQuestionSet;
  public requested_by: IRecruiter;
  public answer_set: IAnswerSet;

  public id: string;
  public notes: string;

  public is_reminders_enabled: boolean;
  public reminder: IReminder;
  public filled_at: APIDatetime;
  public created_at: APIDatetime;
  public updated_at: APIDatetime;
  public pages: IPage[];
  public score: number;

  constructor(partial: Partial<IPreScreening>) {
    super();

    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: PreScreening.TYPE,
        id: this.id,
        attributes: {
          filled_at: this.filled_at,
          notes: this.notes
        },
        relationships: {
          candidate: {
            id: this.candidate?.id
          } as IAPIRelationship,
          question_set: {
            id: this.question_set?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IPreScreening {
    const data = res.data;

    if (!(data && data.type === PreScreening.TYPE)) {
      throw new Error(
        `There was a problem parsing ${PreScreening.TYPE} API data`
      );
    }

    const preScreening: IPreScreening = new PreScreening({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    const relationships = data.relationships;

    return buildPreScreening(preScreening, relationships, included);
  }

  public static fromAPIArray(res: IAPIArrayData): IPreScreening[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === PreScreening.TYPE)) {
        throw new Error(
          `There was a problem parsing ${PreScreening.TYPE} API data`
        );
      }

      const preScreening = new PreScreening({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      return buildPreScreening(preScreening, relationships, included);
    });
  }
}
