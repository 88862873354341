import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment/environment';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { IProfile } from '@shared/interfaces/profile.interface';
import { Document } from '@shared/models/document.model';
import { Profile } from '@shared/models/profile.model';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/documents`
  );

  constructor(private readonly _http: HttpClient) {}

  public post(file: File, params: any): Observable<IDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file, uuid());

    const options = {
      params
    };

    let url = this.ENDPOINT_V3;
    if (params.endpoint) {
      url += `/${params.endpoint}`;
    }

    return this._http
      .post(url, formData, options)
      .pipe(map((res: IAPIData) => Document.fromAPI(res)));
  }

  public uploadFromGoogle(id: string, body: FormData): Observable<IProfile> {
    return this._http
      .post(`${environment.ENDPOINTS.BACK_END.V2}/google/${id}/upload`, body)
      .pipe(map((res: IAPIData) => Profile.fromAPI(res)));
  }

  public uploadFromLinkedIn(id: string, body: FormData): Observable<IProfile> {
    return this._http
      .post(`${environment.ENDPOINTS.BACK_END.V1}/profiles/${id}/upload`, body)
      .pipe(map((res: IAPIData) => Profile.fromAPI(res)));
  }

  // TO DO CLEAN THAT
  public get(qs: any): Observable<Blob> {
    let params: Params = new HttpParams();

    [
      'fields',
      'filter',
      'dates',
      'type',
      'id',
      'include_contact',
      'logo_url',
      'question_set_id',
      'customer_id'
    ].forEach((v: string) => {
      if (v === 'filter' && qs[v]) {
        params = params.set(`filter[${qs.type}]`, String(qs.filter.value));
      } else if (v === 'dates' && qs[v]) {
        params = params.set(
          'dates[start_date]',
          String(qs.dates['start_date'])
        );
        params = params.set('dates[end_date]', String(qs.dates['end_date']));
      } else if (qs[v]) {
        params = params.set(v, String(qs[v]));
      }
    });

    let url = this.ENDPOINT_V3;
    if (qs.endpoint) {
      url += `/${qs.endpoint}`;
    }

    return this._http
      .get(url, {
        responseType: 'blob',
        params
      })
      .pipe(
        map((res: Blob) => res),
        retry(3)
      );
  }

  public delete(id: string): Observable<any> {
    return this._http.delete(`${this.ENDPOINT_V3}/${id}`);
  }
}
