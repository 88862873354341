import {
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IBackgroundChecking } from '@shared/interfaces/background-checking.interface';
import { Candidate } from '@shared/models/candidate.model';
import { Recruiter } from '@shared/models/recruiter.model';

export const buildBackgroundChecking = (
  backgroundChecking: IBackgroundChecking,
  relationships: any,
  included: any
): any => {
  const candidate =
    relationships.candidate &&
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.candidate as IAPIRelationship).id
    );

  if (candidate) {
    backgroundChecking.candidate = Candidate.fromAPI({
      data: candidate,
      included
    });
  }

  const requestedBy =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.requested_by as IAPIRelationship).id
    );

  if (!!requestedBy) {
    backgroundChecking.requested_by = Recruiter.fromAPI({
      data: requestedBy,
      included
    });
  }

  return backgroundChecking;
};
