import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICountry } from '@shared/interfaces/country.interface';
import { Country } from '@shared/models/country.model';
import { SessionStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  @SessionStorage() private countries: ICountry[];

  constructor(private readonly _http: HttpClient) {}

  public get(): Observable<ICountry[]> {
    return this._http
      .get('/assets/data/countries.json')
      .pipe(
        map(
          (res: ICountry[]) =>
            (this.countries = res.map((c: ICountry) => new Country(c)))
        )
      );
  }

  public resolve(): Observable<ICountry[]> {
    if (!this.countries || this.countries?.length === 0) {
      return this.get();
    }

    return;
  }
}
