import { IAnalytics } from '@shared/interfaces/analytics.interface';
import { IAPIData } from '@shared/interfaces/api.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import moment from 'moment';

export class Analytics implements IAnalytics {
  public static readonly TYPES: string[] = [
    'AdminAnalytic',
    'HiringFirmAnalytic',
    'RecruiterAnalytic'
  ];

  id: number;

  // Question sets
  total_question_sets: number;
  qs_per_references_count: any;

  // References
  references: IReference[];
  total_references: number;
  total_references_completed: number;
  total_reference_requests: number;
  total_reference_requests_completed: number;
  avg_completion_time: number;
  branches_breakdown: any[];

  // Feedbacls
  total_feedbacks_completed: number;
  total_feedbacks: number;
  avg_feedback_completion_time: number;
  feedbacks_completed_by_date: any[];
  feedbacks_by_date: any[];

  // Leads
  total_candidates: number;
  total_leads_not_defined: number;
  total_leads_candidates: number;
  total_leads_clients: number;
  leads_not_defined_count_by_date: number;
  leads_candidates_count_by_date: number;
  leads_clients_count_by_date: number;

  // Pre screenings
  total_pre_screenings_completed: number;
  avg_pre_screening_completion_time: number;
  total_pre_screenings: number;
  pre_screenings_completed_by_date: any[];
  pre_screenings_by_date: any[];

  // Metadata
  total_devices: {
    total_phones: number;
    total_desktops: number;
    total_unknown: number;
  };

  // Admin
  total_companies?: number;
  total_profiles_active?: number;
  total_profiles?: number;
  total_verification_requests?: number;
  profiles_count_by_date?: any[];
  candidates_count_by_date?: any[];
  references_count_by_date?: any[];
  references_completed_count_by_date?: any[];

  recruiters: IRecruiter[];

  constructor(partial: Partial<IAnalytics>) {
    Object.assign(this, partial);
  }

  get qsMostUsed(): string {
    return Object.keys(this.qs_per_references_count)[0];
  }

  get referencesRequestsCompletedOnSendTotal() {
    let percent = 0;
    if (this.total_reference_requests > 0) {
      percent = Number(
        (
          this.total_reference_requests_completed /
          this.total_reference_requests
        ).toFixed(2)
      );
    }

    return {
      value: `${this.total_reference_requests_completed} / ${this.total_reference_requests}`,
      percent
    };
  }

  get referencesCompletedOnSendTotal() {
    let percent = 0;
    if (this.total_references > 0) {
      percent = Number(
        (this.total_references_completed / this.total_references).toFixed(2)
      );
    }

    return {
      value: `${this.total_references_completed} / ${this.total_references}`,
      percent
    };
  }

  get profilesActiveOnInativeTotal() {
    let percent = 0;
    if (this.total_profiles > 0) {
      percent = Number(
        (this.total_profiles_active / this.total_profiles).toFixed(2)
      );
    }

    return {
      value: `${this.total_profiles_active} / ${this.total_profiles}`,
      percent
    };
  }

  get avgCompletionTime() {
    const avg = this.avg_completion_time;
    return moment.duration(avg, 'second').humanize();
  }

  static fromAPI(res: IAPIData): IAnalytics {
    const data = res.data;

    if (!(data && this.TYPES.includes(data.type))) {
      throw new Error(`There was a problem parsing Analytic API Data`);
    }

    return new Analytics({
      // id: data.id,
      ...data.attributes
    });
  }
}
