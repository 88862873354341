import { IAPIRelationship } from '@shared/interfaces/api.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { Address } from '@shared/models/address.model';
import { Metadatum } from '@shared/models/metadatum.model';

export const buildEmployer = (
  employer: IEmployer,
  relationships: any,
  included: any
): IEmployer => {
  // employer.references = Reference.fromAPIArray({
  //   data:
  //     (included &&
  //       included.filter((i) => i.type === 'RecruiterReference' || i.type === 'ProfileReference')) ||
  //     ([] as IAPIRelationship[]),
  //   included,
  //   candidate
  // });

  const metadatum =
    relationships.metadatum &&
    included &&
    included.find(
      (i: IAPIRelationship) =>
        i.id === (relationships.metadatum as IAPIRelationship).id
    );

  if (!!metadatum) {
    employer.metadatum = Metadatum.fromAPI({
      data: metadatum,
      included
    });
  }

  const address =
    relationships.address &&
    included &&
    included.find(
      (i: IAPIRelationship) =>
        i.id === (relationships.address as IAPIRelationship).id
    );

  if (!!address) {
    employer.address = Address.fromAPI({
      data: address,
      included
    });
  }

  return employer;
};
