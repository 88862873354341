import { buildEmployer } from '@shared/builders/employer.helper';
import { phoneFormat } from '@shared/builders/phone-formart.helper';
import { IAddress } from '@shared/interfaces/address.interface';
import {
  APIDatetime,
  APIResource,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IMetadatum } from '@shared/interfaces/metadatum.interface';
import { PhoneNumber } from 'libphonenumber-js';

export class Employer extends APIResource implements IEmployer {
  public static readonly TYPE: string = String('Employer');

  id: string;
  first_name: string;
  last_name: string;
  email: string;
  score: number;

  start_date: string;
  end_date: string;
  specialty: string;
  candidate_position: string;

  phone: PhoneNumber;
  country_code: string;

  created_at: APIDatetime;
  updated_at: APIDatetime;

  // relationships
  address?: IAddress;
  candidate?: ICandidate;
  hiring_firm?: IHiringFirm;
  metadatum: IMetadatum;

  public get full_name(): string {
    return this.first_name || this.last_name
      ? `${this.first_name} ${this.last_name}`
      : '';
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  public get apiRelationship(): IAPIRelationship {
    return {
      type: Employer.TYPE,
      id: this.id
    };
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Employer.TYPE,
        id: this.id,
        attributes: {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          country_code: this.country_code,
          start_date: this.start_date,
          end_date: this.end_date,
          candidate_position: this.candidate_position,
          specialty: this.specialty
        },
        relationships: {
          address: {
            id: this.address?.id
          } as IAPIRelationship,
          candidate: {
            id: this.candidate?.id
          } as IAPIRelationship,
          hiring_firm: {
            id: this.hiring_firm?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  constructor(partial: Partial<IEmployer>) {
    super();

    Object.assign(this, partial);
  }

  public static fromAPI(res: IAPIData): IEmployer {
    const data = res.data;

    if (!(data && data.type === Employer.TYPE)) {
      throw new Error(`There was a problem parsing ${Employer.TYPE} API data`);
    }

    const employer = new Employer({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    const relationships = data.relationships;

    return buildEmployer(employer, relationships, included);
  }

  public static fromAPIArray(res: IAPIArrayData): IEmployer[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Employer.TYPE)) {
        throw new Error(
          `There was a problem parsing ${Employer.TYPE} API data`
        );
      }

      const employer = new Employer({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      return buildEmployer(employer, relationships, included);
    });
  }
}
