import { Component, Input, OnInit } from '@angular/core';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { ITab } from '@shared/interfaces/tab.interface';
import { FeedbacksService } from '@shared/services/feedbacks.service';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';

@Component({
  selector: 'app-sidebar-feedback',
  templateUrl: './sidebar.feedback.component.html'
})
export class SidebarFeedbackComponent implements OnInit {
  @Input() private id: string;

  public feedback: IFeedback;
  public isLoading = Boolean(true);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _feedbacks: FeedbacksService,
    private readonly _sidebar: SidebarService
  ) {}

  ngOnInit(): void {
    this.getFeedback();
  }

  public close() {
    this._sidebar.closeSidebar();
  }

  public trackByFn(i: ITab): string {
    return i.label;
  }

  private getFeedback() {
    const url = `GET /feedbacks/${this.id}`;
    this._feedbacks.find(this.id).subscribe(
      (res: IFeedback) => {
        this._logger.info(this.constructorName, url, res);

        this.feedback = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoading = false;
      }
    );
  }
}
