import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { externalAuthorizations } from '@shared/helpers/integrations.helper';
import { IIntegration } from '@shared/interfaces/integration.interface';
import { Integration } from '@shared/models/integration.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsAuthorizationsService {
  public externalAuthorisations$: BehaviorSubject<
    IIntegration[]
  > = new BehaviorSubject<IIntegration[]>([]);

  constructor(private readonly _http: HttpClient) {}

  public get(): Observable<IIntegration[]> {
    return this._http
      .get<{ attributes: { label: string } }[]>(
        `${environment.ENDPOINTS.BACK_END.V2}/external_authorizations`
      )
      .pipe(
        map((data: any) =>
          data.data.reduce((memo: any, value: any) => {
            memo.push(
              new Integration({
                id: value.attributes.label,
                label: externalAuthorizations.find(
                  (ea) => ea.id === value.attributes.label
                ).label,
                isAuthorized: true,
                isLoading: false
              })
            );

            return memo;
          }, [])
        ),
        tap((value: IIntegration[]) => this.externalAuthorisations$.next(value))
      );
  }
}
