import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { ITextActivity } from '@shared/interfaces/text-activity.interface';
import { CandidatesService } from '@shared/services/candidates.service';
import { LoggerService } from '@shared/services/logger.service';

@Component({
  selector: 'app-sidebar-candidate-texts',
  templateUrl: './sidebar.candidate.texts.component.html'
})
export class SidebarCandidateTextsComponent implements OnInit {
  @Input() public candidate: ICandidate;

  public isLoading = Boolean(true);

  public rows: ITextActivity[] = [];
  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.STATUS',
      type: TableColType.Icon,
      cellClassName: 'text-capitalize',
      selector: 'label'
    },
    {
      headerText: 'TABLES.HEADERS.PHONE_NUMBER',
      type: TableColType.Text,
      selector: 'phone_number'
    },
    {
      headerText: 'TABLES.HEADERS.DESCRIPTION',
      cellClassName: 'text-wrap',
      type: TableColType.Text,
      selector: 'error_message',
      selectorFunction: (data: string) => data || '-'
    },
    {
      headerText: 'TABLES.HEADERS.BODY',
      type: TableColType.Text,
      selector: 'body'
    },
    {
      headerText: 'TABLES.HEADERS.FROM',
      type: TableColType.Text,
      selector: 'from'
    },
    {
      headerText: 'TABLES.HEADERS.DATE',
      type: TableColType.Ago,
      selector: 'created_at'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _candidates: CandidatesService,
    private readonly _logger: LoggerService
  ) {}

  ngOnInit(): void {
    this.getTextActivity();
  }

  public getTextActivity() {
    const phone = this.candidate.phone;

    if (!!phone) {
      const url = `GET /candidate/${this.candidate.id}/text_activities`;
      this._candidates.getCandidateTextActivities(this.candidate.id).subscribe(
        (res: ITextActivity[]) => {
          this._logger.info(this.constructorName, url, res);

          this.rows = res;
          this.isLoading = false;
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  public trackByFn(i: ITextActivity): string {
    return i.id;
  }
}
