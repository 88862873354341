export type APIuuid = string;
export type APIDatetime = string;
export type APIDate = string;
export type APIEmail = string;
export type APIText = string;

export interface IAPIRelationship {
  type: string;
  id: APIuuid;
}

export interface IAPIDataObject {
  type: string;
  attributes: any;
  relationships?: { [key: string]: IAPIRelationship[] | IAPIRelationship };
  id?: APIuuid;
}

export interface IAPIErrorObject {
  status?: number;
  title?: string;
  detail?: string;
}

export interface IAPIData {
  data: IAPIDataObject;
  included?: IAPIDataObject[];
  token?: string;
}

export interface IAPIArrayData {
  data: IAPIDataObject[];
  included?: IAPIDataObject[];
  pagination?: any;
}

export abstract class APIResource {
  public id: APIuuid;
  public type: string;

  public get apiRelationship(): IAPIRelationship {
    return {
      type: this.type,
      id: this.id
    } as IAPIRelationship;
  }

  public abstract get apiData(): IAPIData;
}
