import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { LoggerService } from '@shared/services/logger.service';
import { ReferencesService } from '@shared/services/references.service';
import { ToastService } from '@shared/services/toast.service';
import moment from 'moment';

import { SessionStorage } from 'ngx-webstorage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar-reference-question-set-alerts',
  templateUrl: './sidebar.reference.question-set.alerts.component.html'
})
export class SidebarReferenceQuestionSetAlertsComponent
  implements OnInit, OnDestroy
{
  @Input() public reference: IReference;

  @SessionStorage() public settings: ISetting;

  public title = String('REFERENCE.TITLE');
  public error: IAPIErrorObject;
  public sharingWarning: string;
  public answers: any[] = [];

  public isApproving = Boolean(false);
  public isPending = Boolean(false);
  public isReminderDisabled = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  private referencesRefreshSubscription: Subscription;

  constructor(
    private readonly _logger: LoggerService,
    private readonly _references: ReferencesService,
    private readonly _toast: ToastService
  ) {}

  ngOnInit(): void {
    const url = 'Reference refresh';
    this.referencesRefreshSubscription =
      this._references.referencesCalled$.subscribe(
        (res: IReference) => {
          this._logger.info(this.constructorName, url, res);

          if (res) {
            this.reference = res;
            this.answers = this.reference?.answer_set?.answers || [];
            this.setPermissionWarning();
          }
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );

    this.isPending = this.reference?.is_pending && !this.reference?.filled_at;
    this.answers = this.reference?.answer_set?.answers || [];

    this.setPermissionWarning();
  }

  ngOnDestroy(): void {
    this.referencesRefreshSubscription?.unsubscribe();
  }

  public setPermissionWarning(): void {
    const candidatePermission = this.reference.permission_share_candidate;
    const clientsPermission = this.reference.permission_share_clients;
    const warningPathPrefix = 'REFERENCE.PERMISSION.WARNING.';

    if (!candidatePermission && !clientsPermission) {
      this.sharingWarning = warningPathPrefix + 'BOTH';
    } else if (!candidatePermission) {
      this.sharingWarning = warningPathPrefix + 'CANDIDATE';
    } else if (!clientsPermission) {
      this.sharingWarning = warningPathPrefix + 'CLIENTS';
    } else {
      this.sharingWarning = '';
    }
  }

  public approveReference() {
    this.isApproving = true;

    const url = `Approve ${this.reference.id}`;
    this._references.approveReference(this.reference.id).subscribe(
      (res: IReference) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reference has been approved');
        this.reference = res;
        this.isPending = false;

        this.isApproving = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.error = err[0];
        this.isApproving = false;
      }
    );
  }

  public sendReferenceReminder() {
    this.isReminderDisabled = true;

    const url = `GET /reminders/references/${this.reference.id}`;
    this._references.sendReferenceReminder(this.reference.id).subscribe(
      (res: Partial<IReminder>) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reminder sent');

        this.reference.reminder = {
          ...this.reference.reminder,
          latest_sent_at: moment().format(),
          rate_exceeded: true
        };
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._toast.error(err[0].detail);
      }
    );
  }
}
