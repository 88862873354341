import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IQuestionGroup } from '@shared/interfaces/question-group.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { QuestionGroup } from '@shared/models/question-group.model';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionGroupsService } from '@shared/services/question-groups.service';
import { ToastService } from '@shared/services/toast.service';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-question-groups',
  templateUrl: './modals.question-groups.component.html'
})
export class ModalsQuestionGroupsComponent
  extends ModalDirective<IQuestionGroup>
  implements OnInit {
  @SessionStorage() private hiringFirm: IHiringFirm;

  public title = String('MODALS.QUESTION_GROUPS.ADD.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');
  public errors: IAPIErrorObject[] = [];

  public deleteKey: string;

  public entry: FormGroup;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _questionGroups: QuestionGroupsService,
    private readonly _toast: ToastService
  ) {
    super();

    this.whenModalClose = this.whenModalClose.bind(this);
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((qg: IQuestionSet) => {
      this.deleteKey = null;

      if (qg) {
        this.title = 'MODALS.QUESTION_GROUPS.EDIT.TITLE';
        this.submitKey = 'BUTTONS.SAVE';
        this.deleteKey = 'BUTTONS.DELETE';

        this.entry.patchValue({
          ...qg
        });
      }

      this.entry.patchValue({
        hiring_firm: this.hiringFirm
      });
    });
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.onSubmit(this.entry);
    } else if (type === 'DELETE') {
      this.delete(this.entry);
    }
  }

  public onDismiss() {
    this.entry.reset();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();
      this.errors = [];

      let url = `POST /question-groups`;
      let method = 'post';

      const id = value.id;

      if (!!id) {
        url = `PATCH /question-groups/${id}`;
        method = 'patch';
      }

      const data = new QuestionGroup(value).apiData;
      this._questionGroups[method](data).subscribe(
        (res: IQuestionGroup) => {
          this._logger.info(this.constructorName, url, res);

          this.entry.enable();
          this.resetModal(res);

          let messsage = 'Question group added';
          if (!!id) {
            messsage = 'Question group updated';
          }

          this._toast.success(messsage);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
          this.errors = err;
          this.entry.enable();
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', [Validators.required]],
      hiring_firm: this._fb.group({
        id: ['', [Validators.required]]
      })
    });
  }

  private delete({ value, valid }: { value: any; valid: boolean }) {
    if (valid) {
      const id = value.id;
      const url = `DELETE /question-groups/${id}`;

      this._questionGroups.delete(id).subscribe(
        (res: IQuestionGroup) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal(res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
          this.errors = err;
        }
      );
    }
  }
}
