import { replaceAll } from '@shared/helpers/utils.helper';
import {
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { AnswerSet } from '@shared/models/answer-set.model';
import { Candidate } from '@shared/models/candidate.model';
import { Employer } from '@shared/models/employer.model';
import { Page } from '@shared/models/page.model';
import { QuestionSet } from '@shared/models/question-set.model';
import { Recruiter } from '@shared/models/recruiter.model';
import { Setting } from '@shared/models/setting.model';

export const buildFeedback = (
  feedback: IFeedback,
  relationships: any,
  included: any
): any => {
  const identifiable =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.identifiable as IAPIRelationship).id
    );

  if (identifiable) {
    const type = identifiable.type;
    switch (type) {
      case 'Candidate':
        feedback.identifiable = Candidate.fromAPI({
          data: identifiable,
          included
        });
        break;

      case 'Employer':
        feedback.identifiable = Employer.fromAPI({
          data: identifiable,
          included
        });
        break;

      case 'Recruiter':
        feedback.identifiable = Recruiter.fromAPI({
          data: identifiable,
          included
        });
        break;
    }
  }

  if (relationships.recipient) {
    const recipient =
      included &&
      included.find(
        (i: IAPIDataObject) =>
          i.id === (relationships.recipient as IAPIRelationship).id
      );

    if (recipient) {
      const type = recipient.type;
      switch (type) {
        case 'Candidate':
          feedback.recipient = Candidate.fromAPI({
            data: recipient,
            included
          });
          break;
      }
    }
  }

  const qs =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.question_set as IAPIRelationship).id
    );

  if (!!qs) {
    feedback.question_set = QuestionSet.fromAPI({
      data: qs,
      included
    });
  }

  const candidate =
    relationships.candidate &&
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.candidate as IAPIRelationship).id
    );

  if (!!candidate) {
    feedback.candidate = Candidate.fromAPI({
      data: candidate,
      included
    });
  }

  const requestedBy =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.requested_by as IAPIRelationship).id
    );

  if (!!requestedBy) {
    feedback.requested_by = Recruiter.fromAPI({
      data: requestedBy,
      included
    });
  }

  const settings =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.settings as IAPIRelationship).id
    );

  if (!!settings) {
    feedback.settings = Setting.fromAPI({
      data: settings,
      included
    });
  }

  const as =
    relationships.answer_set &&
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.answer_set as IAPIRelationship).id
    );

  feedback.answer_set =
    as && feedback.filled_at
      ? AnswerSet.fromAPI({
          data: as,
          included
        })
      : undefined;

  if (relationships.pages !== undefined) {
    feedback.pages = Page.fromAPIArray({
      data:
        (included &&
          included.filter((i: IAPIDataObject) => i.type === 'Page')) ||
        ([] as IAPIRelationship[]),
      included
    });

    if (feedback.pages) {
      feedback.pages.map((p: IPage) => {
        p.content = replaceAll(p.content, {
          candidate_full_name: (feedback.candidate as ICandidate)?.full_name,
          candidate_first_name: (feedback.candidate as ICandidate)?.first_name,
          company_name: (feedback.requested_by.hiring_firm as IHiringFirm)?.name
        });
      });
    }
  }

  return feedback;
};
