import * as Airbrake from '@airbrake/browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { environment } from '@environment/environment';
import { IPayload } from '@shared/interfaces/payload.interface';
import jwtDecode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';

export class AirbrakeErrorHandler implements ErrorHandler {
  @LocalStorage() public token: string;

  public airbrake: any;

  private readonly environement: string = String(environment.ENVIRONMENT);

  constructor() {
    let TYPE = String('REFERENCES');
    const href = window.location.href;

    if (href.indexOf(':4000') > -1 || href.indexOf('admin.') > -1) {
      TYPE = 'ADMIN';
    } else if (href.indexOf(':4100') > -1 || href.indexOf('analytics.') > -1) {
      TYPE = 'ANALYTICS';
    } else if (href.indexOf(':4200') > -1 || href.indexOf('app.') > -1) {
      TYPE = 'APP';
    } else if (
      href.indexOf(':4300') > -1 ||
      href.indexOf('configuration.') > -1
    ) {
      TYPE = 'CONFIGURATION';
    } else if (href.indexOf(':4400') > -1 || href.indexOf('feedback.') > -1) {
      TYPE = 'FEEDBACK';
    } else if (href.indexOf(':4600') > -1 || href.indexOf('signup.') > -1) {
      TYPE = 'SIGNUP';
    }

    const AIRBRAKE = (environment as any).AIRBRAKE[TYPE];
    this.airbrake = new Airbrake.Notifier({
      environment: this.environement,
      projectId: AIRBRAKE.PROJECT_ID,
      projectKey: AIRBRAKE.PROJECT_KEY
    });

    const filters = [
      /loading chunk/gi,
      /expected expression/gi,
      /already sent/gi,
      /already linked/gi,
      /already associated/gi,
      /already exists/gi,
      /already been filled/gi,
      /been taken/gi,
      /password is invalid/gi,
      /must be different from/gi,
      /unexpected token/gi,
      /cyclic structure/gi,
      /circular structure/gi,
      /invalid credentials/gi,
      /not a valid phone number/gi,
      /object.entries/gi,
      /assets\/i18n/gi,
      /unable to get property/gi,
      /Illegal invocation/gi,
      /Object Not Found Matching/gi,
      /Could not load/gi
    ];

    this.airbrake.addFilter((notice: any) => {
      const userAgent = notice.context.userAgent;

      // Not loggin anything coming from Edge
      if (userAgent.indexOf('Edge') > -1) {
        return null;
      }

      notice.context.version = environment.VERSION;

      if (!!this.token) {
        const payload: IPayload = jwtDecode(this.token);
        notice.context.user_id = payload.sub;
      }

      const message = JSON.stringify(notice.errors[0].message);

      for (const f of filters) {
        if (f.test(message)) {
          return null;
        }
      }

      return notice;
    });
  }

  handleError(err: any[] | Error | HttpErrorResponse): void {
    let error: any[] | Error | HttpErrorResponse | string = err;

    if (err instanceof Error && !!err.message) {
      error = err.message;
    } else if (err instanceof Array) {
      error = (err[0] || []).detail || JSON.stringify(err[0]);
    }

    console.error(err);

    if (this.environement !== 'development') {
      this.airbrake.notify(error);
    }
  }
}
