import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { ICountry } from '@shared/interfaces/country.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { Employer } from '@shared/models/employer.model';
import { Feedback } from '@shared/models/feedback.model';
import { CandidatesService } from '@shared/services/candidates.service';
import { EmployersService } from '@shared/services/employers.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { FeedbacksService } from '@shared/services/feedbacks.service';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { ToastService } from '@shared/services/toast.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import jwtDecode from 'jwt-decode';
import { SessionStorage } from 'ngx-webstorage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modals-feedback-requests',
  templateUrl: './modals.feedback-requests.component.html'
})
export class ModalsFeedbackRequestsComponent
  extends ModalDirective<any>
  implements OnInit, OnDestroy
{
  @SessionStorage() public hiringFirm: IHiringFirm;
  @SessionStorage() private countries: ICountry[];
  @SessionStorage() private settings: ISetting;

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    showWeekNumbers: false,
    adaptivePosition: true
  };

  public title = String('MODALS.FEEDBACK_REQUESTS.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');

  public items: IQuestionSet[] = [];
  public questionSets: IQuestionSet[] = [];
  public errors: any = [];

  public entry: FormGroup;
  public employer: IEmployer;
  public employers: IEmployer[];

  public isButtonsDisabled = Boolean(false);
  public isScheduleFeedbacksEnabled = Boolean(false);

  public types: { label: string; value: string }[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  private payload: IPayload;

  private candidate: ICandidate;
  private watcher: Subscription;


  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _candidates: CandidatesService,
    private readonly _employers: EmployersService,
    private readonly _fb: FormBuilder,
    private readonly _feedbacks: FeedbacksService,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((c: ICandidate) => {
      this.isScheduleFeedbacksEnabled =
        this.settings.is_employer_feedbacks_scheduled_enabled;
      if (this.isScheduleFeedbacksEnabled) {
        this.bsConfig.minDate = new Date();
      }

      const token = this._commonEnvironments.getToken();
      this.payload = jwtDecode(token);

      this.watcher = this.entry.get('type').valueChanges.subscribe(() => {
        this.errors = [];
        this.items = [];

        const type = this.entry.get('type').value;

        if (type === 'Employer') {
          this.getCandidateEmployers();
        }

        this.setValidators();
      });

      const country = this.countries.find(
        (cc: ICountry) =>
          cc.phoneCode === c?.country_code ||
          cc.initial === this.hiringFirm?.country
      );

      if (!!country) {
        this.entry.get('employer').patchValue({
          country_code: country?.phoneCode,
          countryInitial: country?.initial
        });
      }

      if (!!c) {
        this.candidate = c;
        this.entry.patchValue({ candidate: c.id });
      }

      this.getQuestionSets();
      this.setValidators();
    });

    if (this.settings.is_plugins_candidate_feedback_enabled) {
      this.types.push(
        {
          label: 'Candidate',
          value: 'Candidate'
        },
        {
          label: 'Employer',
          value: 'Employer'
        }
      );
    }

    if (this.settings.is_plugins_recruiter_feedback_enabled) {
      this.types.push({
        label: 'Recruiter',
        value: 'Recruiter'
      });
    }

    this.createForm();
  }

  ngOnDestroy(): void {
    this.watcher?.unsubscribe();
  }

  public onDismiss() {
    this.errors = [];

    this.entry.enable();
    this.resetForm();
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.errors = [];
      this.isButtonsDisabled = true;

      const type: string = this.entry.get('type').value;

      if (type === 'Employer') {
        const employerEmail = value.employer.email;

        this.employer = this.employers.find(
          (e: IEmployer) => e.email === employerEmail
        );

        if (!!this.employer) {
          this.postFeedback(value);
        } else {
          this.postEmployer(value);
        }

        //
      } else {
        this.postFeedback(value);
      }
    }
  }

  protected createForm() {
    let type = 'Candidate';
    if (
      !this.settings.is_plugins_candidate_feedback_enabled &&
      this.settings.is_plugins_recruiter_feedback_enabled
    ) {
      type = 'Recruiter';
    }

    this.entry = this._fb.group({
      type: [type, Validators.required],
      candidate: ['', [Validators.required]],
      language: ['en', [Validators.required]],
      question_set: ['', [Validators.required]],
      scheduled_date: [''],
      notes: [''],
      employer: this._fb.group({
        first_name: ['', []],
        last_name: ['', []],
        email: ['', []],
        phone: ['', [NumbersOnlyValidator.NumbersOnly]],
        countryInitial: [''],
        country_code: ['']
      })
    });
  }

  private getQuestionSets() {
    this.items = [];
    const filters = {
      page_size: 50
    };

    const url = String('GET /question_sets');
    this._questionSets.get(filters).subscribe(
      (res: IQuestionSet[]) => {
        this._logger.info(this.constructorName, url, res);

        this.questionSets = res;
        this.filterQuestionSets();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private setValidators() {
    const employerFormGroup = this.entry.get('employer') as FormGroup;

    const firstName = employerFormGroup['controls']['first_name'];
    const lastName = employerFormGroup['controls']['last_name'];
    const email = employerFormGroup['controls']['email'];

    const type: string = this.entry.get('type').value;

    switch (type) {
      case 'Employer':
        firstName.setValidators([Validators.required]);
        lastName.setValidators([Validators.required]);
        email.setValidators([Validators.required, Validators.email]);
        // email.setAsyncValidators([this._emailValidityValidator.validates]);
        break;

      case 'Candidate':
      case 'Recruiter':
      case 'Feedback':
        firstName.setValidators([]);
        lastName.setValidators([]);
        email.setValidators([]);
        break;
    }

    this.entry.patchValue({
      question_set: ''
    });

    const questionSet = this.entry.get('question_set');
    questionSet.setValidators([Validators.required]);

    questionSet.markAsUntouched();
    questionSet.updateValueAndValidity();

    firstName.updateValueAndValidity();
    lastName.updateValueAndValidity();
    email.updateValueAndValidity();

    this.filterQuestionSets();
  }

  private filterQuestionSets() {
    const type: string = this.entry.get('type').value;

    this.items = this.questionSets.filter(
      (qs: IQuestionSet) => qs.identifiable_type === type
    );
  }

  private postEmployer(value: any) {
    this.employer = null;

    const employer = new Employer({
      first_name: value.employer.first_name,
      last_name: value.employer.last_name,
      email: value.employer.email,
      phone: value.employer.phone,
      country_code: value.employer.country_code,
      candidate: this.candidate
    });

    const url = String('POST /employers');
    this._employers.post(employer.apiData).subscribe(
      (res: IEmployer) => {
        this._logger.info(this.constructorName, url, res);
        this.employer = res;

        this.postFeedback(value);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }

  private postFeedback(value: any) {
    const type: string = this.entry.get('type').value;

    const feedback = new Feedback({
      scheduled_date: value.scheduled_date,
      notes: value.notes,
      candidate:
        type === 'Candidate' || type === 'Recruiter' ? this.candidate : null,
      employer: type === 'Employer' ? this.employer : null,
      recruiter:
        type === 'Recruiter' ? { id: this.payload.recruiter_id } : null,
      question_set: this.items.find(
        (qs: IQuestionSet) => qs.id === value.question_set
      )
    });

    const url = 'POST /feedbacks';
    this._feedbacks.post(feedback.apiData).subscribe(
      (res: IFeedback) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Feedback requested');

        this.isButtonsDisabled = false;
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }

  private getCandidateEmployers() {
    const url = `GET /feedbacks/${this.candidate.id}/employers`;
    this._candidates.getCandidateEmployers(this.candidate.id).subscribe(
      (res: Partial<IEmployer[]>) => {
        this._logger.info(this.constructorName, url, res);
        this.employers = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
