import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appHighlightOnScroll]'
})
export class HighlightOnScrollDirective implements OnInit {
  constructor(
    @Inject(DOCUMENT) private readonly _document: any,
    private renderer: Renderer2,
    private elem: ElementRef
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    const cards = this._document.getElementsByClassName(
      'v-card--highlightable'
    );

    for (const card of cards) {
      card.classList.remove('v-card--highlighted');
    }

    this.renderer.addClass(this.elem.nativeElement, 'v-card--highlighted');
  }

  ngOnInit() {
    this.renderer.addClass(this.elem.nativeElement, 'v-card--highlightable');
  }

  // ISSUES WITH MATRIX
  // @HostListener('click') onClick() {
  //   this.elem.nativeElement.scrollIntoView({
  //     block: 'center',
  //     behavior: 'smooth'
  //   });
  // }
}
