import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this._commonEnvironments.getToken();
    const isCountriesReq = request.url.indexOf('restcountries.eu') > -1;
    const isIPAddressReq = request.url.indexOf('ipinfo.io') > -1;

    const accept = {
      Accept: 'application/json'
    };

    const contentType = {
      'Content-Type': 'application/json'
    };

    if (isIPAddressReq) {
      token = environment.IP_INFO;
    }

    if (!!token && !isCountriesReq && !isIPAddressReq) {
      const authorization = {
        Authorization: `Bearer ${token}`
      };

      request =
        request.url.endsWith('/logo') ||
        request.url.endsWith('/upload') ||
        request.url.endsWith('/pictures') ||
        request.url.indexOf('/documents') > -1
          ? request.clone({
              setHeaders: {
                ...accept,
                ...authorization
              }
            })
          : request.clone({
              setHeaders: {
                ...accept,
                ...contentType,
                ...authorization
              }
            });
    } else if (isCountriesReq) {
      // Get list countries
      request = request.clone();
    } else if (isIPAddressReq) {
      const authorization = {
        Authorization: `Bearer ${token}`
      };

      request = request.clone({
        setHeaders: {
          ...accept,
          ...authorization
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          ...accept,
          ...contentType
        }
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>): any => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }),
      // TO DO FIX IGNORE ERROR
      // AFTER ANGULAR 10 UPDATE
      // @ts-ignore
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          // TO DO: WAITING ERROR HANDLER TO PUT BACK THE 500 CATCH
          // BY DEFAULT, API RETURNS 500. I CAUGHT THE 500 IF TOKEN IS INCORRECT
          // AND NEED TO BE REFRESHED

          // if (err.status === 401 || err.status === 500) {

          const status = err?.status;

          if (status === 401) {
            this._commonEnvironments.cleanStorage();
            this._router.navigate(['/login']);
          }

          return throwError(err?.error?.errors || JSON.stringify(err));
        }
      })
    );
  }
}
