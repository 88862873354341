import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import {
  AutoRemindersSetting,
  DMARCSetting,
  FeatureFlag,
  FeatureFlagKeys,
  ISetting
} from '@shared/interfaces/setting.interface';

export class Setting implements ISetting {
  public static readonly TYPE: string = String('Setting');

  id: APIuuid;
  hiring_firm_id: APIuuid;
  is_reports_downloadable: boolean;
  languages: string;
  language: string;
  is_references_consent_enabled: boolean;
  is_reference_score_enabled: boolean;
  is_consent_enabled_to_client: boolean;
  is_consent_enabled_to_candidate: boolean;
  is_call_back_enabled: boolean;
  colors_primary: string;
  colors_secondary: string;
  support_email_from: string;
  support_email_name: string;
  explainer_video_link: string;
  user_guide_link: string;
  new_recruiters_security_statement: string;
  timezone: string;
  date_format: string;
  pdf_template: string;
  font_family: string;
  default_filters_timeframe: number;
  lead_generation_type: string;

  hide_reference_job_title_input: boolean;
  hide_reference_specialty_input: boolean;

  is_deep_analytics: boolean;
  is_app_vitay_cta_enabled: boolean;
  is_text_messages_enabled: boolean;
  is_reference_is_declinable_enabled: boolean;
  is_demo_enabled: boolean;
  is_candidate_ownership_updatable: boolean;
  is_candidate_authorization_enabled: boolean;
  is_requested_recruiter_from_email: boolean;

  is_support_email_to_assigned_enabled: boolean;
  is_webhook_enabled: boolean;
  is_secondary_id_enabled: boolean;

  is_candidate_references_csv_enabled: boolean;
  is_docx_enabled: boolean;
  is_reference_dates_enabled: boolean;
  is_reference_candidate_position: boolean;
  is_candidate_job_title_enabled: boolean;
  is_reference_address_enabled: boolean;
  is_reference_address_required: boolean;
  is_thank_you_email_enabled: boolean;
  is_pdf_watermark_enabled: boolean;
  is_question_set_template_enabled: boolean;
  is_sentiment_analysis_enabled: boolean;
  is_sub_hiring_firm_enabled: boolean;
  is_recruiter_approval_required: boolean;
  is_all_toogle_enabled: boolean;
  is_mfa_enabled: boolean;

  is_branding_emails_from_enabled: boolean;
  is_branding_emails_name_enabled: boolean;
  branding_email_from: string;
  branding_email_name: string;

  is_banner_enabled: boolean;
  banner_text: string;
  is_idle_enabled: string;
  idle_timeout: number;
  schedule_references: boolean;
  rating_scale: number;
  auto_reminders_rate_hours: number;

  webhook_url: string;
  max_integrations: number;
  domain_name: string;
  white_label_custom_configuration_domain: string;
  white_label_custom_analytics_domain: string;

  is_billing_sub_accounts_linked_enabled: boolean;

  is_auto_reminders_enabled: boolean;
  is_phone_number_lookup_enabled: boolean;
  is_attach_pdf_report_recruiter_email_enabled: boolean;
  is_monthly_usage_report_enabled: boolean;
  usage_report_emails_list: string;

  is_candidate_email_required: boolean;
  is_reference_email_required: boolean;
  is_references_specialty_required: boolean;
  is_force_users_password_update_enabled: boolean;
  is_disable_inactive_users_enabled: boolean;

  is_plugins_white_label_enabled: boolean;
  is_plugins_private_label_enabled: boolean;
  is_plugins_candidate_feedback_enabled: boolean;
  is_plugins_recruiter_feedback_enabled: boolean;
  is_plugins_ats_enabled: boolean;
  is_plugins_background_checking_enabled: boolean;
  is_plugins_multi_languages_enabled: boolean;
  is_plugins_job_board_enabled: boolean;
  is_plugins_references_checking_enabled: boolean;
  is_plugins_candidate_signature_enabled: boolean;
  is_plugins_recruiter_signature_enabled: boolean;
  is_plugins_reference_signature_enabled: boolean;
  is_plugins_employment_verifications_enabled: boolean;
  is_plugins_sentiment_analysis_enabled: boolean;
  is_plugins_video_enabled: boolean;
  is_plugins_pre_screening_enabled: boolean;
  is_plugins_performances_dashboard_enabled: boolean;
  is_sso_enabled: boolean;

  is_employer_feedbacks_dates_enabled: boolean;
  is_employer_feedbacks_address_enabled: boolean;
  is_employer_feedbacks_address_required: boolean;
  is_employer_feedbacks_candidate_position_enabled: boolean;
  is_employer_feedbacks_specialty_enabled: boolean;
  is_employer_feedbacks_scheduled_enabled: boolean;

  is_notify_candidate_reference_requests_completed_enabled: boolean;
  is_notify_candidate_reference_pending_enabled: boolean;
  is_notify_candidate_reference_completed_enabled: boolean;
  is_notify_candidate_reference_added_enabled: boolean;
  is_notify_candidate_reference_email_activities_enabled: boolean;
  is_notify_email_activities_enabled: boolean;
  is_notify_text_activities_enabled: boolean;
  is_notify_recruiters_ip_address_enabled: boolean;

  review_candidate_references: boolean;
  require_candidate_permission: boolean;
  thank_you_cta: boolean;
  min_reference_count: number;
  usage_limit: number;
  usage_start_date: string;
  usage_end_date: string;

  // this is the v2 plugins attribute
  // going forward all new plugins should be defined here
  plugins_json: {
    validity_screening_plugin: boolean;
  };

  feature_flags_json: FeatureFlag;

  settings_json: {
    dmarc: DMARCSetting[];
    auto_reminders: AutoRemindersSetting;
  };

  constructor(partial: Partial<ISetting>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: Setting.TYPE,
        attributes: {
          hiring_firm_id: this.hiring_firm_id,
          language: this.language,
          languages: this.languages,
          colors_primary: this.colors_primary,
          colors_secondary: this.colors_secondary,
          support_email_from: this.support_email_from,
          support_email_name: this.support_email_name,

          is_branding_emails_from_enabled: this.is_branding_emails_from_enabled,
          is_branding_emails_name_enabled: this.is_branding_emails_name_enabled,

          branding_email_from: this.branding_email_from,
          branding_email_name: this.branding_email_name,

          explainer_video_link: this.explainer_video_link,
          user_guide_link: this.user_guide_link,
          new_recruiters_security_statement:
            this.new_recruiters_security_statement,
          timezone: this.timezone,
          date_format: this.date_format,
          pdf_template: this.pdf_template,
          font_family: this.font_family,
          default_filters_timeframe: this.default_filters_timeframe,
          lead_generation_type: this.lead_generation_type,

          hide_reference_job_title_input: this.hide_reference_job_title_input,
          hide_reference_specialty_input: this.hide_reference_specialty_input,

          is_banner_enabled: this.is_banner_enabled,
          is_secondary_id_enabled: this.is_secondary_id_enabled,
          banner_text: this.banner_text,
          is_idle_enabled: this.is_idle_enabled,
          idle_timeout: this.idle_timeout,
          schedule_references: this.schedule_references,
          is_recruiter_approval_required: this.is_recruiter_approval_required,
          is_mfa_enabled: this.is_mfa_enabled,
          is_requested_recruiter_from_email:
            this.is_requested_recruiter_from_email,
          is_phone_number_lookup_enabled: this.is_phone_number_lookup_enabled,
          is_force_users_password_update_enabled:
            this.is_force_users_password_update_enabled,
          is_disable_inactive_users_enabled:
            this.is_disable_inactive_users_enabled,

          is_all_toogle_enabled: this.is_all_toogle_enabled,
          is_candidate_email_required: this.is_candidate_email_required,
          is_reference_email_required: this.is_reference_email_required,
          is_reference_score_enabled: this.is_reference_score_enabled,
          is_references_consent_enabled: this.is_references_consent_enabled,
          is_consent_enabled_to_client: this.is_consent_enabled_to_client,
          is_consent_enabled_to_candidate: this.is_consent_enabled_to_candidate,
          is_call_back_enabled: this.is_call_back_enabled,
          is_references_specialty_required:
            this.is_references_specialty_required,
          is_reports_downloadable: this.is_reports_downloadable,
          is_notify_candidate_reference_requests_completed_enabled:
            this.is_notify_candidate_reference_requests_completed_enabled,
          is_notify_candidate_reference_pending_enabled:
            this.is_notify_candidate_reference_pending_enabled,
          is_notify_candidate_reference_completed_enabled:
            this.is_notify_candidate_reference_completed_enabled,
          is_notify_recruiters_ip_address_enabled:
            this.is_notify_recruiters_ip_address_enabled,
          is_notify_email_activities_enabled:
            this.is_notify_email_activities_enabled,
          is_attach_pdf_report_recruiter_email_enabled:
            this.is_attach_pdf_report_recruiter_email_enabled,
          is_notify_text_activities_enabled:
            this.is_notify_text_activities_enabled,
          is_notify_candidate_reference_added_enabled:
            this.is_notify_candidate_reference_added_enabled,
          is_notify_candidate_reference_email_activities_enabled:
            this.is_notify_candidate_reference_email_activities_enabled,
          is_plugins_white_label_enabled: this.is_plugins_white_label_enabled,
          is_plugins_private_label_enabled:
            this.is_plugins_private_label_enabled,
          is_app_vitay_cta_enabled: this.is_app_vitay_cta_enabled,
          is_text_messages_enabled: this.is_text_messages_enabled,
          is_plugins_multi_languages_enabled:
            this.is_plugins_multi_languages_enabled,
          is_plugins_candidate_feedback_enabled:
            this.is_plugins_candidate_feedback_enabled,
          is_auto_reminders_enabled: this.is_auto_reminders_enabled,
          is_reference_is_declinable_enabled:
            this.is_reference_is_declinable_enabled,
          is_plugins_ats_enabled: this.is_plugins_ats_enabled,
          is_plugins_background_checking_enabled:
            this.is_plugins_background_checking_enabled,
          is_plugins_recruiter_feedback_enabled:
            this.is_plugins_recruiter_feedback_enabled,
          is_deep_analytics: this.is_deep_analytics,
          is_plugins_job_board_enabled: this.is_plugins_job_board_enabled,
          is_plugins_references_checking_enabled:
            this.is_plugins_references_checking_enabled,
          is_demo_enabled: this.is_demo_enabled,
          is_candidate_ownership_updatable:
            this.is_candidate_ownership_updatable,
          is_candidate_authorization_enabled:
            this.is_candidate_authorization_enabled,
          is_support_email_to_assigned_enabled:
            this.is_support_email_to_assigned_enabled,
          is_webhook_enabled: this.is_webhook_enabled,
          is_plugins_video_enabled: this.is_plugins_video_enabled,
          is_plugins_pre_screening_enabled:
            this.is_plugins_pre_screening_enabled,
          is_candidate_references_csv_enabled:
            this.is_candidate_references_csv_enabled,
          is_docx_enabled: this.is_docx_enabled,
          is_reference_dates_enabled: this.is_reference_dates_enabled,
          is_reference_candidate_position: this.is_reference_candidate_position,
          is_candidate_job_title_enabled: this.is_candidate_job_title_enabled,
          is_thank_you_email_enabled: this.is_thank_you_email_enabled,
          is_reference_address_enabled: this.is_reference_address_enabled,
          is_reference_address_required: this.is_reference_address_required,
          is_pdf_watermark_enabled: this.is_pdf_watermark_enabled,
          is_question_set_template_enabled:
            this.is_question_set_template_enabled,
          is_sentiment_analysis_enabled: this.is_sentiment_analysis_enabled,
          is_sub_hiring_firm_enabled: this.is_sub_hiring_firm_enabled,
          is_billing_sub_accounts_linked_enabled:
            this.is_billing_sub_accounts_linked_enabled,
          is_plugins_candidate_signature_enabled:
            this.is_plugins_candidate_signature_enabled,
          is_plugins_recruiter_signature_enabled:
            this.is_plugins_recruiter_signature_enabled,
          is_plugins_performances_dashboard_enabled:
            this.is_plugins_performances_dashboard_enabled,
            is_sso_enabled: this.is_sso_enabled,
          is_plugins_reference_signature_enabled:
            this.is_plugins_reference_signature_enabled,
          is_plugins_employment_verifications_enabled:
            this.is_plugins_employment_verifications_enabled,
          is_plugins_sentiment_analysis_enabled:
            this.is_plugins_sentiment_analysis_enabled,
          is_employer_feedbacks_dates_enabled:
            this.is_employer_feedbacks_dates_enabled,
          is_employer_feedbacks_address_enabled:
            this.is_employer_feedbacks_address_enabled,
          is_employer_feedbacks_address_required:
            this.is_employer_feedbacks_address_required,
          is_employer_feedbacks_candidate_position_enabled:
            this.is_employer_feedbacks_candidate_position_enabled,
          is_employer_feedbacks_specialty_enabled:
            this.is_employer_feedbacks_specialty_enabled,
          is_employer_feedbacks_scheduled_enabled:
            this.is_employer_feedbacks_scheduled_enabled,
          webhook_url: this.webhook_url,
          max_integrations: this.max_integrations,
          domain_name: this.domain_name,
          white_label_custom_configuration_domain:
            this.white_label_custom_configuration_domain,
          white_label_custom_analytics_domain:
            this.white_label_custom_analytics_domain,
          rating_scale: this.rating_scale,
          auto_reminders_rate_hours: this.auto_reminders_rate_hours,

          // former hf settings
          review_candidate_references: this.review_candidate_references,
          require_candidate_permission: this.require_candidate_permission,
          min_reference_count: this.min_reference_count,
          thank_you_cta: this.thank_you_cta,
          usage_limit: this.usage_limit,
          usage_start_date: this.usage_start_date,
          usage_end_date: this.usage_end_date,
          is_monthly_usage_report_enabled: this.is_monthly_usage_report_enabled,
          usage_report_emails_list: this.usage_report_emails_list,

          validity_screening_plugin:
            this.plugins_json.validity_screening_plugin,
          rick_roll_feature_flag:
            this.feature_flags_json.rick_roll_feature_flag,
          super_duper_feature_feature_flag:
            this.feature_flags_json.super_duper_feature_feature_flag,
          pre_screen_feedback_feature_flag:
            this.feature_flags_json.pre_screen_feedback_feature_flag,
          auto_reminders_setting: this.settings_json.auto_reminders
        }
      }
    } as IAPIData;

    return data;
  }

  static fromAPIArray(res: IAPIArrayData): ISetting[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Setting.TYPE)) {
        throw new Error(`There was a problem parsing ${Setting.TYPE} API data`);
      }

      const setting = new Setting({
        id: data.id,
        ...data.attributes
      });

      return setting;
    });
  }

  static fromAPI(res: IAPIData): ISetting {
    const data = res.data;

    if (!(data && data.type === Setting.TYPE)) {
      throw new Error(`There was a problem parsing ${Setting.TYPE} API data`);
    }

    return new Setting({
      id: data.id,
      ...data.attributes
    });
  }

  public hasFeatureFlag(featureName: FeatureFlagKeys) {
    return !!this.feature_flags_json[featureName];
  }
}
