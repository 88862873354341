import {
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { Application } from '@shared/models/application.model';
import { BackgroundChecking } from '@shared/models/background-checking';
import { Employer } from '@shared/models/employer.model';
import { Feedback } from '@shared/models/feedback.model';
import { Job } from '@shared/models/job.model';
import { Metadatum } from '@shared/models/metadatum.model';
import { PreScreening } from '@shared/models/pre-screening.model';
import { Profile } from '@shared/models/profile.model';
import { Recruiter } from '@shared/models/recruiter.model';
import { ReferenceContact } from '@shared/models/reference-contact.model';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { Reference } from '@shared/models/reference.model';
import { VerificationRequest } from '@shared/models/verification-request.model';

export const buildCandidate = (
  candidate: ICandidate,
  relationships: any,
  included: any
): any => {
  const references =
    (included &&
      included.filter(
        (i: IAPIDataObject) =>
          i.type === 'RecruiterReference' || i.type === 'ProfileReference'
      )) ||
    ([] as IAPIRelationship[]);

  if (!!references) {
    candidate.references =
      Reference.fromAPIArray({
        data: references || [],
        included,
        candidate
      } as any) || [];
  }

  candidate.requests =
    ReferenceRequest.fromAPIArray({
      data:
        (included &&
          included.filter(
            (i: IAPIDataObject) => i.type === 'RecruiterRequest'
          )) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  candidate.verificationRequests =
    VerificationRequest.fromAPIArray({
      data:
        (included &&
          included.filter(
            (i: IAPIDataObject) => i.type === 'VerificationRequest'
          )) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  candidate.feedbacks =
    Feedback.fromAPIArray({
      data:
        (included &&
          included.filter((i: IAPIDataObject) => i.type === Feedback.TYPE)) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  candidate.pre_screenings =
    PreScreening.fromAPIArray({
      data:
        (included &&
          included.filter(
            (i: IAPIDataObject) => i.type === PreScreening.TYPE
          )) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  candidate.applications =
    Application.fromAPIArray({
      data:
        (included &&
          included.filter(
            (i: IAPIDataObject) => i.type === Application.TYPE
          )) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  candidate.jobs =
    Job.fromAPIArray({
      data:
        (included &&
          included.filter((i: IAPIDataObject) => i.type === Job.TYPE)) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  candidate.employers =
    Employer.fromAPIArray({
      data:
        (included &&
          included.filter((i: IAPIDataObject) => i.type === Employer.TYPE)) ||
        ([] as IAPIRelationship[]),
      included
    }) || [];

  const profile =
    included && included.find((i: IAPIRelationship) => i.type === 'Profile');
  if (profile) {
    candidate.profile = Profile.fromAPI({
      data: profile,
      included
    });
  }

  const recruiter =
    included &&
    included.find(
      (i: IAPIRelationship) =>
        i.id === (relationships.added_by as IAPIRelationship).id
    );

  if (recruiter) {
    candidate.added_by = Recruiter.fromAPI({
      data: recruiter,
      included
    });
  }

  const metadatum =
    relationships.metadatum &&
    included &&
    included.find(
      (i: IAPIRelationship) =>
        i.id === (relationships.metadatum as IAPIRelationship).id
    );

  if (metadatum) {
    candidate.metadatum = Metadatum.fromAPI({
      data: metadatum,
      included
    });
  }

  candidate.background_checkings = BackgroundChecking.fromAPIArray({
    data:
      (included &&
        included.filter(
          (i: IAPIDataObject) => i.type === BackgroundChecking.TYPE
        )) ||
      ([] as IAPIRelationship[]),
    included
  });

  candidate.reference_contacts = ReferenceContact.fromAPIArray({
    data:
      (included &&
        included.filter(
          (i: IAPIDataObject) => i.type === ReferenceContact.TYPE
        )) ||
      ([] as IAPIRelationship[]),
    included
  });

  return candidate;
};
