export enum QuestionCategory {
  Techinical = 'T',
  Functional = 'F',
  Behavioral = 'B',
  Undefined = 'U'
}

export const QuestionCategories = [
  {
    key: QuestionCategory.Behavioral,
    text: 'Behavioral'
  },
  {
    key: QuestionCategory.Functional,
    text: 'Functional'
  },
  {
    key: QuestionCategory.Techinical,
    text: 'Technical'
  }
];

export enum QuestionType {
  Text = 'T',
  Rating = 'R',
  Dropdown = 'O',
  Undefined = 'U',
  Radio = 'D',
  Checkbox = 'C',
  Matrix = 'M',
  Slider = 'S',
  Ranking = 'K',
  Date = 'A'
}

export const QuestionTypes = [
  {
    key: QuestionType.Dropdown,
    text: 'Dropdown'
  },
  {
    key: QuestionType.Radio,
    text: 'Radio'
  },
  {
    key: QuestionType.Rating,
    text: 'Rating'
  },
  {
    key: QuestionType.Checkbox,
    text: 'Checkbox'
  },
  {
    key: QuestionType.Text,
    text: 'Text'
  },
  {
    key: QuestionType.Matrix,
    text: 'Matrix/Rating Scale'
  },
  {
    key: QuestionType.Slider,
    text: 'Slider'
  },
  {
    key: QuestionType.Ranking,
    text: 'Ranking'
  },
  {
    key: QuestionType.Date,
    text: 'Date'
  }
];
