import { Component, Injector, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailValidationWarning } from '@shared/models/email-validation.model';
import { EmailValidationService } from '@shared/services/email-validation.service';

@Component({
  selector: 'app-forms-form-group',
  templateUrl: './form.form-group.component.html'
})
export class FormFormGroupComponent implements OnInit {
  @Input() public value: any;
  @Input() public fieldName = String('');
  @Input() public fieldLabel = String('');
  @Input() public isRequired = Boolean(true);
  @Input() public isFloatingLabel = Boolean(true);
  @Input() public hasExtraEmailValidation = Boolean(false);
  @Input() public errors: { label: string; field: string }[] = [
    { field: 'required', label: 'FORMS.ERRORS.REQUIRED' },
    { field: 'email', label: 'FORMS.ERRORS.FORMAT_EMAIL' },
    { field: 'NumbersOnly', label: 'FORMS.ERRORS.FORMAT_PHONE_NUMBER' },
    { field: 'MatchPassword', label: 'FORMS.ERRORS.MATCH_PASSWORD' },
    { field: 'loginFailed', label: 'FORMS.ERRORS.LOGIN_FAILED' },
    { field: 'registerFailed', label: 'FORMS.ERRORS.DUPLICATE_EMAIL.' },
    { field: 'emailDuplicationError', label: 'FORMS.ERRORS.UNIQUENESS_EMAIL' },
    { field: 'CheckURL', label: 'FORMS.ERRORS.FORMAT_URL' },
    { field: 'maxlength', label: 'FORMS.ERRORS.TOO_LONG' },
    { field: 'WorkEmailOnly', label: 'FORMS.ERRORS.WORK_EMAIL_ONLY' },
    { field: 'InvalidEmail', label: 'FORMS.ERRORS.FORMAT_EMAIL' },
    { field: 'DuplicateReference', label: 'FORMS.ERRORS.DUPLICATE_REFERENCE' },
    { field: 'PasswordComplexity', label: 'FORMS.ERRORS.PASSWORD_COMPLEXITY' },
    {
      field: 'phoneDuplicationError',
      label: 'FORMS.ERRORS.UNIQUENESS_PHONE_NUMBER'
    }
  ];

  public emailValidationWarning$: Observable<EmailValidationWarning>;
  private emailValidationService: EmailValidationService;

  constructor(private _injector: Injector) {}

  ngOnInit(): void {
    if (this.hasExtraEmailValidation){
      this.emailValidationService = this._injector.get<any>(EmailValidationService);
      this.emailValidationWarning$ = this.emailValidationService.emailValidationWarning$;
    }
  }
}
