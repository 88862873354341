import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData
} from '@shared/interfaces/api.interface';
import { IBackgroundChecking } from '@shared/interfaces/background-checking.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { BackgroundChecking } from '@shared/models/background-checking';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackgroundCheckingsService implements IPaginable {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/background_checkings`
  );

  constructor(private readonly _http: HttpClient) {}

  public delete(id: string): Observable<IBackgroundChecking> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => BackgroundChecking.fromAPI(res)));
  }

  public find(id: string): Observable<IBackgroundChecking> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => BackgroundChecking.fromAPI(res)));
  }

  public get(): Observable<IBackgroundChecking[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => BackgroundChecking.fromAPIArray(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter
  ): Observable<IBackgroundChecking[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V3, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => BackgroundChecking.fromAPIArray(res))
    );
  }

  public patch(body: IAPIData): Observable<IBackgroundChecking> {
    const id = body.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}`, body)
      .pipe(map((res: IAPIData) => BackgroundChecking.fromAPI(res)));
  }

  public post(body: IAPIData): Observable<IBackgroundChecking> {
    return this._http
      .post<IAPIData>(this.ENDPOINT_V3, body)
      .pipe(map((res: IAPIData) => BackgroundChecking.fromAPI(res)));
  }

  public postDocument(
    file: File,
    params: any
  ): Observable<IBackgroundChecking> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const options = {
      params
    };

    return this._http
      .post<IAPIData>(
        `${this.ENDPOINT_V3}/${params.id}/documents`,
        formData,
        options
      )
      .pipe(map((res: IAPIData) => BackgroundChecking.fromAPI(res)));
  }

  public getDocument(id: string): any {
    return this._http
      .get(`${this.ENDPOINT_V3}/${id}/documents`, {
        responseType: 'blob'
      })
      .pipe(map((res: any) => res));
  }

  public sendBackgroundCheckingReminder(id: APIuuid): Observable<IReminder> {
    return this._http
      .post(
        `${environment.ENDPOINTS.BACK_END.V3}/reminders/background_checkings/${id}`,
        {}
      )
      .pipe(map((res: any) => res));
  }
}
