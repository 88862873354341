import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IFrameService } from '@shared/services/iframe.service';
import { CookieService } from 'ngx-cookie-service';
import {
  LocalStorage,
  LocalStorageService,
  SessionStorageService
} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CommonEnvironmentsService {
  @LocalStorage() public token: string;

  public readonly IS_PRODUCTION: boolean = Boolean(environment.IS_PRODUCTION);
  public readonly VERSION: string = String(environment.VERSION);
  public readonly ENDPOINTS_SOCKET: string = String(
    environment.ENDPOINTS.SOCKET
  );

  public readonly ENDPOINTS_ASSETS: string = String(
    environment.ENDPOINTS.ASSETS
  );

  public readonly ENDPOINTS_APP: string = String(
    environment.ENDPOINTS.FRONT_END.APP
  );

  public readonly ENDPOINTS_REFERENCES: string = String(
    environment.ENDPOINTS.FRONT_END.REFERENCES
  );

  public APPS: any = [
    {
      src: `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-orange.svg`,
      label: 'analytics',
      url: this.getDomainUrl(environment.ENDPOINTS.FRONT_END.ANALYTICS)
    },
    {
      src: `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-turquoise.svg`,
      label: 'feedback',
      url: this.getDomainUrl(environment.ENDPOINTS.FRONT_END.FEEDBACK)
    },
    {
      src: `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-green.svg`,
      label: 'references',
      url: this.getDomainUrl(environment.ENDPOINTS.FRONT_END.REFERENCES)
    },
    // {
    //   src: `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-purple.svg`,
    //   label: 'jobs',
    //   url: environment.ENDPOINTS.FRONT_END.JOBS
    // },
    {
      src: `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-blue.svg`,
      label: 'configuration',
      url: this.getDomainUrl(environment.ENDPOINTS.FRONT_END.CONFIGURATION)
    }
  ];

  constructor(
    private readonly _cookies: CookieService,
    private readonly _ls: LocalStorageService,
    private readonly _iframe: IFrameService,
    private readonly _ss: SessionStorageService
  ) {}

  public getToken(token?: string) {
    if (this._iframe.isIframe) {
      return this.token;
    }

    if (!!!token) {
      const href = window.location.href;
      token = 'candidate-token';

      if (href.indexOf(':4000') > -1 || href.indexOf('admin.') > -1) {
        token = 'admin-token';
      } else if (
        // Analytics
        href.indexOf(':4100') > -1 ||
        href.indexOf('analytics.') > -1 ||
        // Configuration
        href.indexOf(':4300') > -1 ||
        href.indexOf('configuration.') > -1 ||
        // Feedback
        href.indexOf(':4400') > -1 ||
        href.indexOf('feedback.') > -1 ||
        // References
        href.indexOf(':4500') > -1 ||
        href.indexOf('references.') > -1 ||
        // Sign Up
        href.indexOf(':4600') > -1 ||
        href.indexOf('signup.') > -1
      ) {
        token = 'recruiter-token';
      }
    }

    return this._cookies.get(token);
  }

  public setToken(label: string, token: string, isLax: boolean = true) {
    if (this._iframe.isIframe) {
      return (this.token = token);
    }

    const hostname = window.location.hostname;

    let domain = 'vitay.io';
    if (!isLax) {
      domain = hostname;
    } else if (hostname.indexOf('vitay.co.uk') > -1) {
      domain = 'vitay.co.uk';
    } else if (environment.ENVIRONMENT !== 'development' || label === 'mfa') {
      const splitted = hostname.split('.');

      if (
        hostname.indexOf('probitypeople') > -1 ||
        hostname.indexOf('mie') > -1
      ) {
        domain = `${splitted[splitted.length - 3]}.${
          splitted[splitted.length - 2]
        }.${splitted[splitted.length - 1]}`;
      } else {
        domain = `${splitted[splitted.length - 2]}.${
          splitted[splitted.length - 1]
        }`;
      }
    }

    // Required for iframe
    if (label !== 'mfa') {
      this.token = token;
    }

    this._cookies.set(label, token, {
      expires: 15,
      path: '/',
      sameSite: isLax ? 'Lax' : 'Strict',
      // Not working with secure
      // secure: true,
      domain
    });
  }

  public getHostname(): string {
    // TODO: consider removing this logic, seems unecessary
    const index = environment.ENVIRONMENT === 'dev' ? 2 : 1;
    return window.location.hostname.split('.')[index] || 'vitay';
  }

  public cleanStorage() {
    this._ls.clear();

    this._ss.clear();
    // this._ss.clear('user');
    // this._ss.clear('hiringfirm');
    // this._ss.clear('referencerelationships');
    // this._ss.clear('settings');

    this._cookies.deleteAll('/');
  }

  private getDomainUrl(url: string) {
    if (environment.ENVIRONMENT === 'production') {
      const hostname = window.location.hostname;
      const splitted = hostname.split('.');
      const domain = `${splitted[splitted.length - 2]}.${
        splitted[splitted.length - 1]
      }`;

      const isWhiteLabeled = ['vitay', 'mintzscreeningservices'].includes(
        splitted[splitted.length - 2]
      );

      if (isWhiteLabeled) {
        url = url.replace(/vitay.(io|ca)/gi, domain);
      }
    }

    return url;
  }
}
