import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ISort } from '@shared/interfaces/sort.interface';
import { IVerificationRequest } from '@shared/interfaces/verification-request.interface';
import { VerificationRequest } from '@shared/models/verification-request.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerificationRequestsService {
  public refreshSource = new Subject();

  public refreshCalled$ = this.refreshSource.asObservable();

  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/verification_requests`
  );

  constructor(private readonly _http: HttpClient) {}

  public refresh() {
    this.refreshSource.next();
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter,
    sort?: ISort
  ): Observable<IVerificationRequest[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    if (sort) {
      const sortValue = sort['isReverse'] ? `-${sort['value']}` : sort['value'];
      params = params.set('sort', sortValue);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V3, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => VerificationRequest.fromAPIArray(res))
    );
  }

  public get(): Observable<IVerificationRequest[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V3)
      .pipe(map((res: IAPIArrayData) => VerificationRequest.fromAPIArray(res)));
  }

  public patch(body: IAPIData): Observable<IVerificationRequest> {
    const id = body.data.id;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${id}`, body)
      .pipe(map((res: IAPIData) => VerificationRequest.fromAPI(res)));
  }

  public post(body: IAPIData): Observable<IVerificationRequest> {
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V3}`, body)
      .pipe(map((res: IAPIData) => VerificationRequest.fromAPI(res)));
  }

  public sendReminder(id: string) {
    return this._http
      .post<any>(
        `${environment.ENDPOINTS.BACK_END.V3}/reminders/verification_requests/${id}`,
        {}
      )
      .pipe(map((res: any) => res));
  }
}
