import { replaceAll } from '@shared/helpers/utils.helper';
import {
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { IPreScreening } from '@shared/interfaces/pre-screening.interface';
import { AnswerSet } from '@shared/models/answer-set.model';
import { Candidate } from '@shared/models/candidate.model';
import { Page } from '@shared/models/page.model';
import { QuestionSet } from '@shared/models/question-set.model';
import { Recruiter } from '@shared/models/recruiter.model';

export const buildPreScreening = (
  preScreening: IPreScreening,
  relationships: any,
  included: any
): any => {
  const candidate =
    relationships.candidate &&
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.candidate as IAPIRelationship).id
    );

  if (candidate) {
    preScreening.candidate = Candidate.fromAPI({
      data: candidate,
      included
    });
  }

  const qs =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.question_set as IAPIRelationship).id
    );

  if (qs) {
    preScreening.question_set = QuestionSet.fromAPI({
      data: qs,
      included
    });
  }

  const requestedBy =
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.requested_by as IAPIRelationship).id
    );

  if (requestedBy) {
    preScreening.requested_by = Recruiter.fromAPI({
      data: requestedBy,
      included
    });
  }

  const as =
    relationships.answer_set &&
    included &&
    included.find(
      (i: IAPIDataObject) =>
        i.id === (relationships.answer_set as IAPIRelationship).id
    );

  preScreening.answer_set =
    as && preScreening.filled_at
      ? AnswerSet.fromAPI({
          data: as,
          included
        })
      : undefined;

  if (relationships.pages !== undefined) {
    preScreening.pages = Page.fromAPIArray({
      data:
        (included &&
          included.filter((i: IAPIDataObject) => i.type === 'Page')) ||
        ([] as IAPIRelationship[]),
      included
    });

    if (preScreening.pages) {
      preScreening.pages.map((p: IPage) => {
        p.content = replaceAll(p.content, {
          company_name: (preScreening.requested_by.hiring_firm as IHiringFirm)
            .name
        });
      });
    }
  }

  return preScreening;
};
