import {
  APIDatetime,
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IQuestion } from '@shared/interfaces/question.interface';
import { Question } from '@shared/models/question.model';

export class QuestionSet extends APIResource implements IQuestionSet {
  public static readonly TYPE: string = String('QuestionSet');

  id: APIuuid;
  title: string;
  language: string;
  template: string;
  is_editable: boolean;
  is_default: boolean;
  is_selectable: boolean;

  type = String('QuestionSet');
  identifiable_type = String('Reference');
  questions: (IQuestion | IAPIRelationship)[];

  hiring_firm_id: string;
  created_at: APIDatetime;
  updated_at: APIDatetime;

  constructor(partial: Partial<IQuestionSet>) {
    super();

    Object.assign(this, partial);
  }

  public get pillsType(): any {
    let className;

    switch (this.identifiable_type) {
      case 'Reference':
        className = 'v-btn-primary';
        break;

      case 'Employer':
        className = 'v-btn-success';
        break;

      case 'Recruiter':
        className = 'v-btn-warning';
        break;

      case 'Candidate':
      default:
        className = 'v-btn-secondary';
        break;
    }

    return {
      label: this.identifiable_type,
      class: className
    };
  }

  public get pillsIsDefault(): any {
    let label;
    let className;

    if (this.is_default) {
      label = 'Highlight';
      className = 'v-btn-warning';
    }

    return {
      label,
      class: className
    };
  }

  public get isNotSelectable(): boolean {
    return !this.is_selectable;
  }

  public get questions_count(): number {
    return this.questions.length;
  }

  public get relationship(): IAPIRelationship {
    return {
      type: QuestionSet.TYPE,
      id: this.id
    };
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: QuestionSet.TYPE,
        attributes: {
          title: this.title,
          identifiable_type: this.identifiable_type,
          hiring_firm_id: this.hiring_firm_id,
          is_editable: this.is_editable,
          is_default: this.is_default
        },
        relationships: {
          questions: this.questions
        }
      }
    } as IAPIData;

    return data;
  }

  static fromAPI(res: IAPIData): IQuestionSet {
    const data = res.data;
    const included = res.included;

    if (!(data && data.type === QuestionSet.TYPE)) {
      throw new Error(
        `There was a problem parsing ${QuestionSet.TYPE} API Data`
      );
    }

    const qs = new QuestionSet({
      id: data.id,
      ...data.attributes
    });

    if (included) {
      qs.questions = Question.fromAPIArray({
        data: included.filter((i: any) => i.type === 'Question'),
        included: included.filter((i: any) => i.type === 'QuestionGroup')
      });
    }

    // TO DO: match relationship with included
    return qs;
  }

  static fromAPIArray(res: IAPIArrayData): IQuestionSet[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      const qs = new QuestionSet({
        id: data.id,
        ...data.attributes
      });

      qs.questions = data.relationships.questions as IAPIRelationship[];

      return qs;
    });
  }

  public sortQuestions() {
    this.questions.sort((q1: Question, q2: Question) =>
      q1.order < q2.order ? -1 : q1.order > q2.order ? 1 : 0
    );
  }

  public sortOptions() {
    this.questions.forEach(function(question: Question) {
      if (question['options']) {
        question['options'] = question['options'].sort((a, b) => a.order - b.order);
      }
    });
  }
}
