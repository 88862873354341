import { buildCard } from '@shared/builders/card.helper';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { ICard } from '@shared/interfaces/card.interface';
import { ICustomer } from '@shared/interfaces/customer.interface';

export class Card implements ICard {
  public static readonly TYPE: string = String('Card');

  public id: string;
  public name: string;
  public number: number;
  public last4: number;
  public token: string;
  public customer: Partial<ICustomer>;

  public address_city: string;
  public address_country: string;
  public address_line1: string;
  public address_line2: string;
  public address_state: string;
  public address_zip: string;
  public brand: string;
  public country: string;
  public exp_month: number;
  public exp_year: number;
  public funding: string;
  public is_default: boolean;

  constructor(partial: Partial<ICard>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: Card.TYPE,
        id: this.id,
        attributes: {
          name: this.name,
          number: this.number,
          token: this.token
        },
        relationships: {
          customer: {
            type: 'Customer',
            id: this.customer && this.customer.id
          }
        }
      }
    } as IAPIData;

    return data;
  }

  public get hiddenNumber(): string {
    return this.last4 ? `**** ${this.last4}` : '';
  }

  public get expired() {
    return `${this.exp_month} / ${this.exp_year}`;
  }

  public static fromAPI(res: IAPIData): ICard {
    const data = res.data;

    if (!(data && data.type === Card.TYPE)) {
      throw new Error(`There was a problem parsing ${Card.TYPE} API data`);
    }

    const card = new Card({
      id: data.id,
      ...data.attributes
    });

    return buildCard(card, data.relationships, res.included);
  }

  public static fromAPIArray(res: IAPIArrayData): ICard[] {
    return res.data.map((c: any) =>
      this.fromAPI({
        data: c,
        included: res.included
      })
    );
  }
}
