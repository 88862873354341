import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { languages } from '@shared/helpers/languages.helper';
import { IAPIData } from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReferenceRequest } from '@shared/interfaces/reference-request.interface';
import { ReferenceRequest } from '@shared/models/reference-request.model';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { RecruitersService } from '@shared/services/recruiters.service';
import { ReferencesService } from '@shared/services/references.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { ISetting } from '@shared/interfaces/setting.interface';

@Component({
  selector: 'app-modals-reference-requests',
  templateUrl: './modals.reference-requests.component.html'
})
export class ModalsReferenceRequestsComponent
  extends ModalDirective<IReferenceRequest>
  implements OnInit
{
  @LocalStorage() public isSubAccount: boolean;

  @SessionStorage() public hiringFirm: IHiringFirm;
  @SessionStorage() public settings: ISetting;

  public title = String('MODALS.REFERENCE_REQUESTS.TITLE');
  public description = String('MODALS.REFERENCE_REQUESTS.DESCRIPTION');
  public candidate: ICandidate;
  public isScheduleReferencesEnabled = Boolean(false);
  public items: IQuestionSet[] = [];
  public entry: FormGroup;
  public errors: any = [];
  public recruiters: IRecruiter[] = [];
  public minReferencesCount = Number(0);
  public isButtonsDisabled = Boolean(false);
  public isMultiLanguagesEnable = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public referenceRequestWasSent = Boolean(false);

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    showWeekNumbers: false,
    adaptivePosition: true
  };

  public params: any = {
    type: 'candidate'
  };

  public languages: any[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _references: ReferencesService,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService,
    private readonly _recruiters: RecruitersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((c: ICandidate) => {
      this.candidate = c;

      const settings = this.hiringFirm.settings;
      this.referenceRequestWasSent = c.reference_requests_status.total > 0;
      this.minReferencesCount = settings.min_reference_count;
      this.isMultiLanguagesEnable = settings.is_plugins_multi_languages_enabled;
      this.isScheduleReferencesEnabled = this.settings.schedule_references;

      if (this.isScheduleReferencesEnabled) {
        this.bsConfig.minDate = new Date();
      }

      this.languages = languages.filter(
        (l: any) => settings.languages.indexOf(l.id) > -1
      );

      const token = this._commonEnvironments.getToken();
      const payload: IPayload = jwtDecode(token);

      this.entry.patchValue({
        candidate: c.id,
        request_by: payload.recruiter_id,
        min_manager_references: this.referenceRequestWasSent ? 1 : this.minReferencesCount
      });

      this.getQuestionSets();
      this.getRecruiters();
    });

    this.createForm();
  }

  public addOneWeek(date: any) {
    date = new Date(date);
    return new Date(date.setDate(date.getDate() + 7));
  }

  public onDismiss() {
    this.entry.enable();
    this.errors = [];

    this.resetForm();
  }

  public onSubmit({ value }: { value: any }): void {
    this.errors = [];
    this.isButtonsDisabled = true;

    const rr = new ReferenceRequest({
      candidate: this.candidate,
      question_set: this.items.find(
        (qs: IQuestionSet) => qs.id === value.question_set
      ),
      request_by: this.recruiters.find(
        (r: IRecruiter) => r.id === value.request_by
      ),
      notes: value.notes,
      min_manager_references: value.min_manager_references,
      is_manager_only: value.is_manager_only,
      language: value.language,
      scheduled_date: value.scheduled_date
    });

    const url = 'POST /reference_requests';
    this._references.postRequest(rr).subscribe(
      (res: IAPIData) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Reference requested');
        this.isButtonsDisabled = false;

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        // TO DO: WAITING FOR PROPER HANDLING FROM API
        this.errors = err;
        this.isButtonsDisabled = false;
      }
    );
  }

  protected createForm() {
    this.entry = this._fb.group({
      candidate: ['', [Validators.required]],
      language: ['en', [Validators.required]],
      question_set: ['', [Validators.required]],
      min_manager_references: [null, [Validators.required]],
      request_by: ['', [Validators.required]],
      is_manager_only: [false],
      notes: [''],
      scheduled_date: ['']
    });
  }

  private getQuestionSets() {
    this.items = [];

    const filters = {
      page_size: 50,
      filter: {
        key: 'qs',
        value: 'scope:reference'
      }
    };

    const url = 'GET /question_sets';
    this._questionSets.get(filters).subscribe(
      (res: IQuestionSet[]) => {
        this._logger.info(this.constructorName, url, res);
        this.items = res;

        const defaultQs = this.items.find((qs: IQuestionSet) => qs.is_default);

        if (!!defaultQs) {
          this.entry.patchValue({ question_set: defaultQs.id });
        }
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private getRecruiters() {
    this.recruiters = [];

    const filter: any = {
      page_size: 250,
      filter: {
        key: 'is_active',
        value: true
      }
    };

    const url = String('GET /recruiters');
    this._recruiters.get(filter).subscribe(
      (res: IRecruiter[]) => {
        this._logger.info(this.constructorName, url, res);

        this.recruiters = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
