import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';
import { ITypeaheadable } from '@shared/interfaces/typeaheadable.interface';
import { LoggerService } from '@shared/services/logger.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html'
})
export class TypeaheadComponent {
  private static readonly DELAY = 500;

  @Input() public isBorder = Boolean(false);
  @Input() public isAdvancedSearchEnable = Boolean(false);
  @Input() public service: ITypeaheadable;
  @Input() public resourceName: string;
  @Input() public onMatOptionSelect: any;
  @Input() public onGetValueFromAttributes: any;
  @Output()
  public selectService: EventEmitter<string> = new EventEmitter<string>();

  public noResult = Boolean(false);
  public search: Observable<
    {
      record: any;
      value: any;
    }[]
  >;

  public asyncSelected: string;

  public options: any[] = [];

  public params: { type: string } = { type: 'candidates' };

  public types: { id: string; label: string }[] = [
    {
      id: 'candidates',
      label: 'Search candidates'
    },
    {
      id: 'references',
      label: 'Search references'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(private readonly _logger: LoggerService) {
    this.onEvery = this.onEvery.bind(this);

    this.search = new Observable((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      debounceTime(TypeaheadComponent.DELAY) as any,
      switchMap((searchCriteria: string) => {
        const url = `POST ${this.resourceName}/search`;
        return this.service
          .searchTypeahead({
            data: {
              search_criteria: searchCriteria
            }
          })
          .pipe(
            map((res: ITypeahead) => {
              this._logger.info(this.constructorName, url, res);

              this.options = res.collisions as any;

              return this.options.map(this.onEvery);
            }),
            catchError((err: any) => {
              this._logger.error(this.constructorName, url, err);

              return [];
            })
          );
      })
    );
  }

  public updateSearch(id: string) {
    this.asyncSelected = '';
    this.selectService.emit(id);
  }

  public typeaheadNoResults(event: boolean) {
    this.noResult = event;
  }

  public handleSelect(event: TypeaheadMatch) {
    if (typeof this.onMatOptionSelect === 'function') {
      this.asyncSelected = event.item.value;
      this.onMatOptionSelect(event.item.record);
    }
  }

  public onEvery(option: any) {
    const value = this.onGetValueFromAttributes
      ? this.onGetValueFromAttributes(option.attributes)
      : `${option.attributes.first_name} ${option.attributes.last_name}`;

    return {
      record: option,
      value
    };
  }
}
