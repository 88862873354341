import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PHONE_NUMBERS_REGEX } from '@shared/helpers/utils.helper';

export class NumbersOnlyValidator {
  static NumbersOnly(AC: AbstractControl): ValidationErrors | null {
    if (AC.value && !AC.value.match(PHONE_NUMBERS_REGEX)) {
      return {
        NumbersOnly: true
      };
    }

    return null;
  }
}
