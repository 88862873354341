import {
  APIuuid,
  IAPIData,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IDecline } from '@shared/interfaces/decline.interface';

export class Decline implements IDecline {
  public static readonly TYPE: string = String('Decline');

  public id: APIuuid;
  public is_declined: boolean;
  public comment: string;
  public phone: string;
  public country_code: string;

  constructor(partial: Partial<IDecline>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Decline.TYPE,
        id: this.id,
        attributes: {
          is_declined: this.is_declined,
          comment: this.comment,
          phone: this.phone,
          country_code: this.country_code
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IDecline {
    const data = res.data;
    if (!(data && data.type === Decline.TYPE)) {
      throw new Error(`There was a problem parsing ${Decline.TYPE} API data`);
    }

    const decline = new Decline({
      id: data.id,
      ...data.attributes
    });

    return decline;
  }
}
