import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { ToastService } from '@shared/services/toast.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit {
  @Input() public cols: ITableCol[];
  @Input() public rows: any[][];
  @Input() public rowActions: any[] = [];
  @Input() public onButtonClick: (e: Event, r: any) => void;
  @Input() public onHeaderClick: (col: any) => void;
  @Input() public onRowClick: (arg0: any, arg1?: any) => void;

  public TableColType = TableColType;

  constructor(
    private readonly _clipboardService: ClipboardService,
    private readonly _toast: ToastService
  ) {}

  ngOnInit(): void {}

  public getReminderStatus(col: any, row: any) {
    const isToComplete =
      col.headerText === 'TABLES.HEADERS.COMPLETED_AT' &&
      this.processRow(col, row) === '-';
    const reminder = row.reminder;

    const isDeclined =
      col.headerText === 'TABLES.HEADERS.COMPLETED_AT' && row.decline;
    const isDisabled =
      col.headerText === 'TABLES.HEADERS.COMPLETED_AT' &&
      row.is_reminders_enabled === false;
    let status = 0;

    if (isDisabled) {
      return 4;
    } else if (isDeclined) {
      return 3;
    } else if (!isToComplete) {
      return status; // Completed
    } else if (
      reminder &&
      reminder.latest_sent_at &&
      ((reminder && reminder.rate_exceeded) ||
        (reminder && reminder.count_exceeded))
    ) {
      status = 2; // Sent
    } else {
      status = 1; // Send
    }

    return status;
  }

  public processBadge(col: any, row: any) {
    return !col.selectorBadge
      .split('.')
      .reduce((prev: any, current: any) => prev[current], row);
  }

  public processBadgeText(col: any) {
    return col.selectorBadgeText ? col.selectorBadgeText : 'Inactive';
  }

  public processRow(col: any, row: any) {
    const cell = col.selector
      .split('.')
      .reduce((prev: any, current: any) => prev[current], row);
    return col.selectorFunction ? col.selectorFunction(cell) : cell;
  }

  public processTooltip(col: any, row: any) {
    const cell = col.selector
      .split('.')
      .reduce((prev: any, current: any) => prev[current], row);
    return col.tooltipFunction ? col.tooltipFunction(cell) : null;
  }

  public processSwitch(col: any, row: any) {
    return col.selectorSwitch ? col.selectorSwitch(row) : false;
  }

  public trackByFn(_i: number, r: any): string {
    return r.id;
  }

  public copyToClipboard(e: Event, text: string) {
    e.stopPropagation();

    this._clipboardService.copy(text);
    this._toast.success('Copy to clipboard');
  }

  public mailTo(e: Event, email: string) {
    e.stopPropagation();

    window.open(`mailto:${email}`);
  }
}
