import {
  IIntegration,
  IntegrationId
} from '@shared/interfaces/integration.interface';

export class Integration implements IIntegration {
  public id: IntegrationId;
  public label: string;
  public logo: string;
  public isLoading: boolean;
  public isAuthorized: boolean;
  public isAvailable: boolean;
  public isSetup: boolean;

  constructor(partial: Partial<IIntegration>) {
    Object.assign(this, partial);
  }
}
