import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { Reminder } from '@shared/models/reminder.model';
import { CandidatesService } from '@shared/services/candidates.service';
import { LoggerService } from '@shared/services/logger.service';
import { RemindersService } from '@shared/services/reminders.service';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-sidebar-candidate-reminders',
  templateUrl: './sidebar.candidate.reminders.component.html'
})
export class SidebarCandidateRemindersComponent implements OnInit {
  @Input() public candidate: ICandidate;

  @SessionStorage() public settings: ISetting;

  public isLoading = Boolean(true);

  public rows: IReminder[] = [];
  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.TYPE',
      type: TableColType.Text,
      selector: 'type'
    },
    {
      headerText: 'TABLES.HEADERS.CREATED_AT',
      type: TableColType.Date,
      selector: 'created_at'
    },
    {
      headerText: 'TABLES.HEADERS.SCHEDULED_AT',
      type: TableColType.Date,
      selector: 'scheduled_at'
    },
    {
      headerText: 'TABLES.HEADERS.SENT_AT',
      type: TableColType.Date,
      selector: 'sent_at'
    },
    {
      headerText: 'TABLES.HEADERS.ACTIVE',
      type: TableColType.Toggle,
      headerClassName: 'text-center',
      cellClassName: 'text-center',
      selector: 'is_active',
      selectorSwitch: (r: IReminder) => !!r.sent_at
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _candidates: CandidatesService,
    private readonly _logger: LoggerService,

    private readonly _reminders: RemindersService
  ) {
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  ngOnInit(): void {
    this.getReminders();
  }

  public getReminders() {
    const url = `GET /candidates/${this.candidate.id}/reminders`;
    this._candidates.getCandidateReminders(this.candidate.id).subscribe(
      (res: IReminder[]) => {
        this._logger.info(this.constructorName, url, res);

        this.rows = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoading = false;
      }
    );
  }

  public trackByFn(i: IReminder): string {
    return i.id;
  }

  public onButtonClick(_e: Event, r: IReminder) {
    r.is_active = !r.is_active;

    const data = new Reminder(r).apiData;
    const url = `PATCH /reminders/${this.candidate.id}`;
    this._reminders.patch(data).subscribe(
      (res: Partial<IReminder>) => {
        this._logger.info(this.constructorName, url, res);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public patch(type: string) {
    this.candidate[type] = !this.candidate[type];
    const data = this.candidate.apiData;

    const url = `PATCH /candidate/${this.candidate.id}`;
    this._candidates.patch(data).subscribe(
      (res: ICandidate) => {
        this._logger.info(this.constructorName, url, res);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
