import { buildBackgroundChecking } from '@shared/builders/background-checking.helper';
import {
  backgroundCheckCategories,
  backgroundCheckingStatus,
  Providers
} from '@shared/helpers/background-checking.helper';
import {
  APIDatetime,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IBackgroundChecking } from '@shared/interfaces/background-checking.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';

export class BackgroundChecking implements IBackgroundChecking {
  public static readonly TYPE: string = String('BackgroundChecking');

  // Relationships
  public candidate: ICandidate;
  public requested_by: IRecruiter;
  public recruiter: IRecruiter;

  public id: string;
  public category: string;
  public provider: Providers;
  public client_reference_code: string;
  public is_active: boolean;
  public status: string;
  public result: string;
  public notes: string;

  public filled_at: APIDatetime;
  public created_at: APIDatetime;
  public updated_at: APIDatetime;

  constructor(partial: Partial<IBackgroundChecking>) {
    Object.assign(this, partial);
  }

  public get category_label(): string {
    return (
      backgroundCheckCategories[this.provider].find(
        (c: any) => c.id === this.category
      )?.label || '-'
    );
  }

  public get status_values(): any {
    let index = 0;

    switch (this.status) {
      case 'INPROGRESS':
        index = 1;
        break;

      case 'COMPLETED':
        index = 2;
        if (this.result === 'clear') {
          index = 3;
        } else if (this.result === 'not_clear') {
          index = 4;
        }
        break;

      default:
        index = 0;
        break;
    }

    return backgroundCheckingStatus[index];
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: BackgroundChecking.TYPE,
        id: this.id,
        attributes: {
          category: this.category,
          provider: this.provider,
          filled_at: this.filled_at
        },
        relationships: {
          candidate: {
            id: this.candidate?.id
          } as IAPIRelationship,
          recruiter: {
            id: this.recruiter?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IBackgroundChecking {
    const data = res.data;

    if (!(data && data.type === BackgroundChecking.TYPE)) {
      throw new Error(
        `There was a problem parsing ${BackgroundChecking.TYPE} API data`
      );
    }

    const bc = new BackgroundChecking({
      id: data.id,
      ...data.attributes
    });

    const included = res.included;
    const relationships = data.relationships;

    return buildBackgroundChecking(bc, relationships, included);
  }

  public static fromAPIArray(res: IAPIArrayData): IBackgroundChecking[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === BackgroundChecking.TYPE)) {
        throw new Error(
          `There was a problem parsing ${BackgroundChecking.TYPE} API data`
        );
      }

      const bc = new BackgroundChecking({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      return buildBackgroundChecking(bc, relationships, included);
    });
  }
}
