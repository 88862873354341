import { AbstractControl, Validators } from '@angular/forms';
import { NumbersOnlyValidator } from '@shared/validators/numbersOnly.validators';
import { UniquenessValidator } from '@shared/validators/uniqueness.validators';

export class PhoneEmailRequiredValidator {
  static DynamicRequired(
    phoneFieldName: string,
    emailFieldName: string,
    isUniquenessRequired?: boolean
  ) {
    return (AC: AbstractControl) => {
      const emailControl = AC.get(emailFieldName);
      const phoneControl = AC.get(phoneFieldName);

      if (emailControl && phoneControl) {
        const phoneValue = phoneControl.value;
        const emailValue = emailControl.value;

        if (phoneValue && !emailValue) {
          emailControl.setValidators([
            Validators.email,
            UniquenessValidator.field(emailFieldName, isUniquenessRequired)
          ]);

          phoneControl.setValidators([
            NumbersOnlyValidator.NumbersOnly,
            Validators.required,
            UniquenessValidator.field(phoneFieldName, isUniquenessRequired)
          ]);
        } else if (!phoneValue && emailValue) {
          emailControl.setValidators([
            Validators.email,
            Validators.required,
            UniquenessValidator.field(emailFieldName, isUniquenessRequired)
          ]);

          phoneControl.setValidators([
            NumbersOnlyValidator.NumbersOnly,
            UniquenessValidator.field(phoneFieldName, isUniquenessRequired)
          ]);
        } else {
          emailControl.setValidators([
            Validators.email,
            Validators.required,
            UniquenessValidator.field(emailFieldName, isUniquenessRequired)
          ]);

          phoneControl.setValidators([
            NumbersOnlyValidator.NumbersOnly,
            Validators.required,
            UniquenessValidator.field(phoneFieldName, isUniquenessRequired)
          ]);
        }

        // NOT WORKING. SHOULD IMPELEMENT LATER
        // emailControl.setAsyncValidators(EmailValidityValidator.validateEmail());

        emailControl.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false
        });

        phoneControl.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false
        });
      }
    };
  }
}
