import { Component, OnInit } from '@angular/core';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { LoggerService } from '@shared/services/logger.service';
import { ReferencesService } from '@shared/services/references.service';
import { ToastService } from '@shared/services/toast.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-reference-notification',
  templateUrl: './modals.reference-notification.component.html'
})
export class ModalsReferenceNotificationComponent
  extends ModalDirective<IReference>
  implements OnInit
{
  public isButtonsDisabled = Boolean(false);
  public reference: IReference;

  public error: IAPIErrorObject;

  public title = String('MODALS.REFERENCE_NOTIFICATON.TITLE');
  public description = String('MODALS.REFERENCE_NOTIFICATON.DESCRIPTION');
  public submitKey = String('BUTTONS.SUBMIT');

  public closeModal: Subject<boolean> = new Subject<boolean>();

  public languages: any[] = [];
  public language = String('en');

  public isMultiLanguagesEnable = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _references: ReferencesService,
    private readonly _toast: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((res: { reference: IReference }) => {
      this.reference = res.reference;
    });
  }

  public onSubmit(): void {}

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      const url = `Approve ${this.reference.id}`;
      this._references.approveReference(this.reference.id).subscribe(
        (res: IReference) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success('Reference has been approved');
          this.reference = res;
          this.closeModal.next();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.error = err[0];
        }
      );
    }
  }

  protected createForm(): void {}
}
