import { Component, OnInit } from '@angular/core';
import { ModalDirective } from '@shared/directives/modal.directive';
import { CommonEnvironmentsService } from '@shared/services/environments.service';

@Component({
  selector: 'app-modals-trials',
  templateUrl: './modals.trials.component.html'
})
export class ModalsTrialsComponent
  extends ModalDirective<any>
  implements OnInit
{
  public title = String('MODALS.TRIALS.TITLE');
  public description = String('MODALS.TRIALS.DESCRIPTION');

  constructor(private readonly _commonEnvironments: CommonEnvironmentsService) {
    super();
  }

  ngOnInit(): void {}

  public onSubmit(): void {}

  public goToConfiguration() {
    const configurationURL = this._commonEnvironments.APPS.find(
      (a: any) => a.label === 'configuration'
    ).url;

    window.location.href = `${configurationURL}/settings/billing`;
  }

  protected createForm() {}
}
