import {
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IText } from '@shared/interfaces/text.interface';

export class Text implements IText {
  public static readonly TYPE: string = String('Text');

  id: string;
  hiring_firm: string;
  content: string;
  language: string;
  category: string;
  is_reminder: boolean;
  is_default: boolean;

  defaultLanguage: string = String('en');
  isSubmitting: boolean = Boolean(false);

  to?: string;

  get variables() {
    switch (this.category) {
      case 'request':
        return [
          'candidate_first_name',
          'recruiter_full_name',
          'hiring_firm_name'
        ];

      case 'reference':
        return [
          'reference_full_name',
          'reference_first_name',
          'candidate_full_name',
          'hiring_firm_name'
        ];

      default:
        return [];
    }
  }

  constructor(partial: Partial<IText>) {
    Object.assign(this, partial);
  }

  static fromAPI(res: IAPIData): IText {
    const data = res.data;
    if (!(data && data.type === Text.TYPE)) {
      throw new Error(`There was a problem parsing ${Text.TYPE} API data`);
    }

    return new Text({
      id: data.id,
      ...data.attributes
    });
  }

  static fromAPIArray(res: IAPIArrayData): IText[] {
    return res.data.map((data: IAPIDataObject) =>
      this.fromAPI({
        data,
        included: res.included
      })
    );
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        type: Text.TYPE,
        id: this.id,
        attributes: {
          hiring_firm: this.hiring_firm,
          content: this.content,
          category: this.category,
          language: this.language,
          is_reminder: this.is_reminder,
          is_default: this.is_default,
          to: this.to
        }
      }
    } as IAPIData;

    return data;
  }
}
