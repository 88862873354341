import * as fuzzy from 'fuzzy/lib/fuzzy';

export const PHONE_NUMBERS_REGEX = /^[0-9]*$/;
export const WORK_EMAILS_REGEX =
  /^[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](g(oogle)?mail|hotmail|yahoo|aol|tutanota|proton)/;

export const dateFormats = [
  {
    label: '01/01/20',
    value: '%d/%m/%y'
  },
  {
    label: 'Jan 01, 2020',
    value: '%b %d, %Y'
  },
  {
    label: 'Jan 01, 2020 09:35pm',
    value: '%b %d, %Y %I:%M%p'
  },
  {
    label: 'Jan 01, 2020 21:35',
    value: '%b %d, %Y %H:%M'
  }
];

export const fuzzyObjectFilter = (s: any, objects: any, fields: any) =>
  objects.filter((obj: any) =>
    fields.some((field: string) =>
      obj[field] ? fuzzy.test(s, obj[field]) : false
    )
  );

export const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    const body = document.getElementsByTagName('app-root')[0];
    body.innerHTML = `<div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card v-card mt-4">
              <div class="card-body">
                <h5 class="card-title">Localstorage not available</h5>
                <p>Please activate the local storage or try with another browser.</p>
              </div>
            </div>
          </div>
        </div>
       </div>`;
    return false;
  }
};

export const fromFilterToString = (
  value: string,
  relationships: string[],
  contactTypes: string[],
  recruiters: string[],
  candidateStatuses?: string[],
  referenceStatuses?: string[],
): string => {
  let filterString = value.indexOf('all') > -1 ? 'scope:all' : 'scope:my';
  if (relationships && relationships.length > 0) {
    filterString += `+relationships:${relationships.join(',')}`;
  }

  if (contactTypes?.length > 0) {
    filterString += `+contact_types:${contactTypes.join(',')}`;
  }

  if (recruiters?.length > 0) {
    filterString += `+recruiters:${recruiters.join(',')}`;
  }

  if (referenceStatuses?.length > 0) {
    filterString += `+reference_statuses:${referenceStatuses.join(',')}`;
  }

  if (candidateStatuses?.length > 0) {
    filterString += `+candidate_statuses:${candidateStatuses.join(',')}`;
  }

  return filterString;
};

export const replaceAll = (str: string, obj: any) => {
  const re = RegExp('\\{\\{(' + Object.keys(obj).join('|') + ')\\}\\}', 'g');
  return str.replace(re, (_a: string, b: string) => obj[b]);
};

export const getQueryParams = (url: string) => {
  const urlParams = url && url.split('?')[1];

  if (!urlParams) {
    return {};
  }

  const params: any = urlParams.split('&');

  let pair = null;
  const queryParams: Record<string, string> = {};

  params.forEach((param: string) => {
    pair = param.split('=');
    queryParams[pair[0] as string] = pair[1] as string;
  });

  return queryParams;
};

export const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgba(${r}, ${g}, ${b})`;
  }
};

export const shadeColor = (color: string, percent: any) => {
  let R: number = parseInt(color.substring(1, 3), 16);
  let G: any = parseInt(color.substring(3, 5), 16);
  let B: any = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString(), 10);
  G = parseInt(((G * (100 + percent)) / 100).toString(), 10);
  B = parseInt(((B * (100 + percent)) / 100).toString(), 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const getSentimentScore = (score: number) => {
  let label = '';
  let className = '';
  let color = '';

  if (score < 0) {
    label = 'Negative';
    className = 'v-text-danger';
    color = '#ff2721';
  } else if (score >= 0 && score < 0.3) {
    label = 'Neutral';
    className = 'text-muted';
    color = '#FFB921';
  } else if (score > 0.3) {
    label = 'Positive';
    className = 'v-text-success';
    color = '#21CE99';
  }

  return {
    score,
    label,
    color,
    className
  };
};

export const median = (arr: number[]): number => {
  const mid = Math.floor(arr.length / 2);
  const nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const isDomainNameValid = (domain: string) => {
  const regex = /^(?!:\/\/)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  return regex.test(domain);
};

