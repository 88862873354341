import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { languages } from '@shared/helpers/languages.helper';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IQuestion } from '@shared/interfaces/question.interface';
import { QuestionSet } from '@shared/models/question-set.model';
import { LoggerService } from '@shared/services/logger.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { ToastService } from '@shared/services/toast.service';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-question-sets',
  templateUrl: './modals.question-sets.component.html'
})
export class ModalsQuestionSetsComponent
  extends ModalDirective<IQuestionSet>
  implements OnInit
{
  @SessionStorage() private hiringFirm: IHiringFirm;

  public entry: FormGroup;
  public questions: IQuestion[];
  public sortableQuestions: IQuestion[];
  public errors: any = [];

  public isFeedbackEnabled = Boolean(false);
  public isMultiLanguagesEnabled = Boolean(false);

  public title = String('MODALS.QUESTION_SETS.ADD.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');

  public types: { label: string; value: string }[] = [
    {
      label: 'Reference',
      value: 'Reference'
    }
  ];

  public languages: any[] = languages;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _router: Router,
    private readonly _logger: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((qs: IQuestionSet) => {
      const settings = this.hiringFirm.settings;
      this.isFeedbackEnabled =
        settings.is_plugins_candidate_feedback_enabled ||
        settings.is_plugins_recruiter_feedback_enabled ||
        settings.is_plugins_pre_screening_enabled;

      this.isMultiLanguagesEnabled =
        settings.is_plugins_multi_languages_enabled;

      if (settings.is_plugins_candidate_feedback_enabled) {
        this.types.push(
          {
            label: 'Candidates',
            value: 'Candidate'
          },
          {
            label: 'Employers',
            value: 'Employer'
          }
        );
      }

      if (settings.is_plugins_pre_screening_enabled) {
        this.types.push({
          label: 'Pre-screening',
          value: 'PreScreening'
        });
      }

      if (settings.is_plugins_recruiter_feedback_enabled) {
        this.types.push({
          label: 'Recruiters',
          value: 'Recruiter'
        });
      }

      if (!!qs) {
        this.questions = qs.questions as IQuestion[];
        this.sortableQuestions = this.questions.filter(
          (q: IQuestion) => q.language === qs.language
        );

        this.entry.patchValue(qs);
        this.title = 'MODALS.QUESTION_SETS.EDIT.TITLE';
        this.submitKey = 'BUTTONS.SAVE';
      } else {
        this.entry.patchValue({ language: 'en' });
      }

      this.entry.patchValue({
        hiring_firm_id: this.hiringFirm.id
      });
    });
  }

  public onDismiss() {
    this.entry.reset();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();
      this.errors = [];

      if (value.id) {
        this.patch(value);
      } else {
        this.post(value);
      }
    }
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    }
  }

  public updateLanguage() {
    if (this.questions?.length > 0) {
      const lng = this.entry.get('language').value;
      this.sortableQuestions = this.questions.filter(
        (q: IQuestion) => q.language === lng
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      hiring_firm_id: ['', [Validators.required]],
      title: ['', [Validators.required, Validators.maxLength(255)]],
      language: ['', [Validators.required]],
      identifiable_type: ['Reference', [Validators.required]],
      questions: ''
    });
  }

  private patch(value: IQuestionSet) {
    value.questions = this.sortableQuestions;
    value.questions.forEach((question, index) => {
      (question as IQuestion).order = index;
    });

    const qs = new QuestionSet(value).apiData;
    const id = qs.data.id;
    const url = `PATCH /question-sets/${id}`;
    this._questionSets.patch(qs).subscribe(
      (res: IQuestionSet) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.enable();
        this.resetModal(res);
        this._toast.success('Question set updated');
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.entry.enable();
      }
    );
  }

  private post(value: any) {
    const qs = new QuestionSet(value).apiData;
    const url = `POST /question-sets`;
    this._questionSets.post(qs).subscribe(
      (res: IQuestionSet) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.enable();
        this.resetModal(res);

        this._toast.success('Question set added');
        this._router.navigate(['/question-sets', res.id]);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.errors = err;
        this.entry.enable();
      }
    );
  }
}
