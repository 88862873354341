import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IPreScreening } from '@shared/interfaces/pre-screening.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { PreScreening } from '@shared/models/pre-screening.model';
import { LoggerService } from '@shared/services/logger.service';
import { PreScreeningsService } from '@shared/services/pre-screenings.service';
import { QuestionSetsService } from '@shared/services/question-sets.service';
import { ToastService } from '@shared/services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modals-pre-screening-requests',
  templateUrl: './modals.pre-screening-requests.component.html'
})
export class ModalsPreScreeningRequestsComponent
  extends ModalDirective<IPreScreening>
  implements OnInit, OnDestroy
{
  public title = String('MODALS.PRE_SCREENING_REQUESTS.TITLE');
  public items: IQuestionSet[] = [];
  public entry: FormGroup;
  public employer: IEmployer;
  public errors: any = [];
  public isButtonsDisabled = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');

  public params: any = {
    type: 'candidate'
  };

  public types: { label: string; value: string }[] = [];

  private candidate: ICandidate;

  private readonly constructorName: string = String(this.constructor.name);

  private watcher: Subscription;

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _preScreenings: PreScreeningsService,
    private readonly _questionSets: QuestionSetsService,
    private readonly _toast: ToastService,
    private readonly _logger: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.openModal.subscribe((c: ICandidate) => {
      if (c) {
        this.candidate = c;
        this.entry.patchValue({ candidate: c.id });
      }

      this.getQuestionSets();
    });

    this.createForm();
  }

  ngOnDestroy(): void {
    this.watcher?.unsubscribe();
  }

  public onDismiss() {
    this.errors = [];

    this.entry.enable();
    this.resetForm();
  }

  public onSubmit({ valid, value }: { valid: boolean; value: any }): void {
    if (valid) {
      this.errors = [];
      this.isButtonsDisabled = true;

      const ps = new PreScreening({
        candidate: this.candidate,
        question_set: this.items.find(
          (qs: IQuestionSet) => qs.id === value.question_set
        ),
        notes: value.notes
      });

      const url = 'POST /pre_screenings';
      this._preScreenings.post(ps.apiData).subscribe(
        (res: IPreScreening) => {
          this._logger.info(this.constructorName, url, res);

          this._toast.success('Pre-screening requested');

          this.isButtonsDisabled = false;
          this.resetModal();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.errors = err;
          this.isButtonsDisabled = false;
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      type: ['PreScreening', Validators.required],
      candidate: ['', [Validators.required]],
      language: ['en', [Validators.required]],
      question_set: ['', [Validators.required]],
      notes: ['']
    });
  }

  private getQuestionSets() {
    this.items = [];

    const filters = {
      page_size: 50,
      filter: {
        key: 'qs',
        value: 'scope:pre_screening'
      }
    };

    const url = 'GET /question_sets';
    this._questionSets.get(filters).subscribe(
      (res: IQuestionSet[]) => {
        this._logger.info(this.constructorName, url, res);

        this.items = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
