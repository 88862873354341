import { Component, Input, OnInit } from '@angular/core';
import { IReference } from '@shared/interfaces/reference.interface';

@Component({
  selector: 'app-sidebar-reference-about',
  templateUrl: './sidebar.reference.about.component.html'
})
export class SidebarReferenceAboutComponent implements OnInit {
  @Input() public reference: IReference;

  constructor() {}

  ngOnInit(): void {}
}
