import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public scrollSidebarSource = new Subject();
  public closeSidebarSource = new Subject();
  public openSidebarSource = new Subject();
  public reloadDatasetSource = new Subject();

  public scrollSidebarCalled$ = this.scrollSidebarSource.asObservable();
  public closeSidebarCalled$ = this.closeSidebarSource.asObservable();
  public openSidebarCalled$ = this.openSidebarSource.asObservable();
  public reloadDatasetCalled$ = this.reloadDatasetSource.asObservable();

  constructor() {}

  public scrollSidebar(isScrolling: boolean) {
    this.scrollSidebarSource.next(isScrolling);
  }

  public closeSidebar() {
    this.closeSidebarSource.next();
  }

  public openSidebar(type?: string, id?: string) {
    this.openSidebarSource.next({
      id,
      type
    });
  }
}
