import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IReference } from '@shared/interfaces/reference.interface';
import { ITab } from '@shared/interfaces/tab.interface';
import { LoggerService } from '@shared/services/logger.service';
import { ReferencesService } from '@shared/services/references.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-sidebar-reference',
  templateUrl: './sidebar.reference.component.html'
})
export class SidebarReferenceComponent implements OnInit {
  @ViewChild(TabsetComponent, { static: true }) public tabset: TabsetComponent;
  @Input() private id: string;

  public reference: IReference;

  public isLoading = Boolean(true);

  public tabs: ITab[] = [
    {
      label: 'TABS.REFERENCE',
      icon: 'lnr-contacts',
      isActive: true
    },
    {
      label: 'TABS.ACTIVITIES',
      icon: 'lnr-papers'
    },
    {
      label: 'TABS.EMAIL_ACTIVITY',
      icon: 'lnr-envelope'
    },
    {
      label: 'TABS.TEXT_ACTIVITY',
      icon: 'lnr-smartphone'
    },
    {
      label: 'TABS.REMINDERS',
      icon: 'lnr-alarm'
    },
    {
      label: 'TABS.ABOUT',
      icon: 'lnr-question'
    }
  ];

  public activeTab = Number(0);

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _logger: LoggerService,
    private readonly _references: ReferencesService,
    private readonly _sidebar: SidebarService
  ) {}

  ngOnInit(): void {
    this.getReference();
  }

  public selectTab(index: number): void {
    this.tabs[index].isActive = true;
    this.activeTab = index;
  }

  public close() {
    this._sidebar.closeSidebar();
  }

  public trackByFn(i: ITab): string {
    return i.label;
  }

  private getReference() {
    const url = `GET /references/${this.id}`;
    this._references.find(this.id).subscribe(
      (res: IReference) => {
        this._logger.info(this.constructorName, url, res);

        this.reference = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoading = false;
      }
    );
  }
}
