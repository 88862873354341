import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAddress } from '@shared/interfaces/address.interface';
import { APIuuid, IAPIData } from '@shared/interfaces/api.interface';
import { Address } from '@shared/models/address.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressesService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/addresses`
  );

  constructor(private readonly _http: HttpClient) {}

  public find(id: APIuuid): Observable<IAddress> {
    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V3}/${id}`)
      .pipe(map((res: IAPIData) => Address.fromAPI(res)));
  }

  public post(a: Partial<IAddress>): Observable<IAddress> {
    const data = new Address(a).apiData;
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V3}`, data)
      .pipe(map((res: IAPIData) => Address.fromAPI(res)));
  }

  public patch(a: Partial<IAddress>): Observable<IAddress> {
    const data = new Address(a).apiData;
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V3}/${a.id}`, data)
      .pipe(map((res: IAPIData) => Address.fromAPI(res)));
  }
}
