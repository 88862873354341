import { Component, Input, OnInit } from '@angular/core';
import { TableColType } from '@shared/enums/table.enum';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmailActivity } from '@shared/interfaces/email-activity.interface';
import { ITableCol } from '@shared/interfaces/table.col.interface';
import { CandidatesService } from '@shared/services/candidates.service';
import { LoggerService } from '@shared/services/logger.service';

@Component({
  selector: 'app-sidebar-candidate-emails',
  templateUrl: './sidebar.candidate.emails.component.html'
})
export class SidebarCandidateEmailsComponent implements OnInit {
  @Input() public candidate: ICandidate;

  public isLoading = Boolean(true);

  public rows: IEmailActivity[] = [];
  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.STATUS',
      type: TableColType.Icon,
      cellClassName: 'text-capitalize',
      selector: 'label'
    },
    {
      headerText: 'TABLES.HEADERS.EMAIL',
      type: TableColType.Text,
      selector: 'email'
    },
    {
      headerText: 'TABLES.HEADERS.DESCRIPTION',
      cellClassName: 'text-wrap',
      type: TableColType.Text,
      selector: 'message',
      selectorFunction: (data: string) => data || '-'
    },
    {
      headerText: 'TABLES.HEADERS.DATE',
      type: TableColType.Ago,
      selector: 'created_at'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _candidates: CandidatesService,
    private readonly _logger: LoggerService
  ) {}

  ngOnInit(): void {
    this.getEmails();
  }

  public getEmails(): void {
    const id = this.candidate?.id;
    const url = `GET /candidates/${id}/email_activities`;
    this._candidates.getCandidateEmailActivities(id).subscribe(
      (res: IEmailActivity[]) => {
        this._logger.info(this.constructorName, url, res);

        this.rows = res;
        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.isLoading = false;
      }
    );
  }

  public trackByFn(i: IEmailActivity): string {
    return i.id;
  }
}
