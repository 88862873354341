import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private readonly _toastr: ToastrService) {}

  public error(msg: any, title: string = 'Error') {
    this._toastr.error(msg, title);
  }

  public success(msg: string, title: string = 'Success') {
    this._toastr.success(msg, title);
  }
}
