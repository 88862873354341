import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent {
  @Input() public isLoading: boolean = Boolean(true);
  @Input() public isFullSize: boolean = Boolean(true);
}
