import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { QuestionType } from '@shared/enums/question.enum';
import { ISetting } from '@shared/interfaces/setting.interface';
import { Options } from 'ng5-slider';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-modals-questions-preview',
  templateUrl: './modals.questions.preview.component.html',
  styleUrls: ['./modals.questions.preview.component.scss']
})
export class ModalsQuestionsPreviewComponent implements OnInit {
  @SessionStorage() private settings: ISetting;

  @Input() entry: FormGroup;

  public type: string;
  public value: string;
  public isCommentable = Boolean(false);
  public questionType = QuestionType;

  public rankingValues: string[] = [];
  public maxRating: number = 5;

  public ratingLabelLeft = 'Lowest';
  public ratingLabelRight = 'Highest';

  public sliderOptions: Options = {
    floor: 0,
    ceil: 100
  };

  public bsConfig: any = {
    containerClass: 'v-datepicker v-datepicker-range',
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    showWeekNumbers: false,
    adaptivePosition: true
  };

  get formArrayOptions(): FormArray {
    return this.entry.get('options') as FormArray;
  }

  get formArrayColumns(): FormArray {
    return this.entry.get('columns') as FormArray;
  }

  constructor() {}

  ngOnInit(): void {
    this.type = this.entry.get('type').value;
    this.value = this.entry.get('value').value;
    this.isCommentable = this.entry.get('commentable').value;
    this.maxRating = this.settings?.rating_scale;

    const options = this.entry.get('options').value;
    if (this.type === QuestionType.Rating && options.length) {
      const label = options[0] as { value: string };
      [this.ratingLabelLeft, this.ratingLabelRight] = label.value.split('/');
    }

    if (this.type === QuestionType.Ranking) {
      this.rankingValues = this.entry
        .get('options')
        .value.map((o: { value: string; warning: string }) => o.value);
    }
  }
}
