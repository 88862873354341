import { buildSubscription } from '@shared/builders/subscription.helper';
import {
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IPlan } from '@shared/interfaces/plan.interface';
import { ISubscription } from '@shared/interfaces/subscription.interface';
import moment from 'moment';

export class Subscription implements ISubscription {
  public static readonly TYPE: string = String('Subscription');

  public id: string;
  public plan: IPlan | IAPIRelationship;
  public status: string;

  public trial_start: number;
  public trial_end: number;
  public customer_id: string;
  public plan_id: string;

  public get period_trial_start(): string {
    return moment(this.trial_start * 1000).format();
  }

  public get period_trial_end(): string {
    return moment(this.trial_end * 1000).format();
  }

  constructor(partial: Partial<Subscription>) {
    Object.assign(this, partial);
  }

  static fromAPIArray(res: IAPIArrayData): ISubscription[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === Subscription.TYPE)) {
        throw new Error(
          `There was a problem parsing ${Subscription.TYPE} API data`
        );
      }

      const subscription = new Subscription({
        id: data.id,
        type: data.type,
        ...data.attributes
      });

      return buildSubscription(subscription, data.relationships);
    });
  }

  static fromAPI(res: IAPIData): ISubscription {
    const data = res.data;

    if (!(data && data.type === Subscription.TYPE)) {
      throw new Error(
        `There was a problem parsing ${Subscription.TYPE} API data`
      );
    }

    const subscription = new Subscription({
      id: data.id,
      type: data.type,
      ...data.attributes
    });

    return buildSubscription(subscription, data.relationships);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: Subscription.TYPE,
        id: this.id,
        attributes: {
          trial_start: this.trial_start,
          trial_end: this.trial_end,
          customer_id: this.customer_id,
          plan_id: this.plan_id
        }
      }
    } as IAPIData;
  }
}
