import { NgModule } from '@angular/core';
import { ModalsExportComponent } from '@references/shared/components/modals/export/modals.export.component';
import { ModalsImportComponent } from '@references/shared/components/modals/import/modals.import.component';
import { ModalsQuickRequestComponent } from '@references/shared/components/modals/quick-request/modals.quick-request.component';
import { ModalsReferenceNotificationComponent } from '@references/shared/components/modals/reference-notification/modals.reference-notification.component';
import { ModalsReferencesComponent } from '@references/shared/components/modals/references/modals.references.component';
import { ModalsRemindersComponent } from '@references/shared/components/modals/reminders/modals.reminders.component';
import { ModalsTrialsComponent } from '@references/shared/components/modals/trials/modals.trials.component';
import { ReferencesNavbarComponent } from '@references/shared/components/navbar/navbar.component';
import { SidebarCandidateAboutComponent } from '@references/shared/components/sidebar/candidate/about/sidebar.candidate.about.component';
import { SidebarCandidateActivitiesComponent } from '@references/shared/components/sidebar/candidate/activities/sidebar.candidate.activities.component';
import { SidebarCandidateEmailsComponent } from '@references/shared/components/sidebar/candidate/emails/sidebar.candidate.emails.component';
import { SidebarCandidateReferenceRequestsComponent } from '@references/shared/components/sidebar/candidate/reference-requests/sidebar.candidate.reference-requests.component';
import { SidebarCandidateRemindersComponent } from '@references/shared/components/sidebar/candidate/reminders/sidebar.candidate.reminders.component';
import { SidebarCandidateComponent } from '@references/shared/components/sidebar/candidate/sidebar.candidate.component';
import { SidebarCandidateTextsComponent } from '@references/shared/components/sidebar/candidate/texts/sidebar.candidate.texts.component';
import { SidebarFeedbackQuestionSetComponent } from '@references/shared/components/sidebar/feedback/question-set/sidebar.feedback.question-set.component';
import { SidebarFeedbackComponent } from '@references/shared/components/sidebar/feedback/sidebar.feedback.component';
import { SidebarReferenceAboutComponent } from '@references/shared/components/sidebar/reference/about/sidebar.reference.about.component';
import { SidebarReferenceActivitiesComponent } from '@references/shared/components/sidebar/reference/activities/sidebar.reference.activities.component';
import { SidebarReferenceEmailsComponent } from '@references/shared/components/sidebar/reference/emails/sidebar.reference.emails.component';
import { SidebarReferenceQuestionSetAlertsComponent } from '@references/shared/components/sidebar/reference/question-set/alerts/sidebar.reference.question-set.alerts.component';
import { SidebarReferenceQuestionSetComponent } from '@references/shared/components/sidebar/reference/question-set/sidebar.reference.question-set.component';
import { SidebarReferenceRemindersComponent } from '@references/shared/components/sidebar/reference/reminders/sidebar.reference.reminders.component';
import { SidebarReferenceComponent } from '@references/shared/components/sidebar/reference/sidebar.reference.component';
import { SidebarReferenceTextsComponent } from '@references/shared/components/sidebar/reference/texts/sidebar.reference.texts.component';
import { ReferencesTopbarComponent } from '@references/shared/components/topbar/topbar.component';
import { HighlightOnScrollDirective } from '@shared/directives/highlight-on-scroll.directive';
import { SidebarOnScrollDirective } from '@shared/directives/sidebar.on-scroll.directive';
import { SharedModule } from '@shared/shared.module';
import { Ng5SliderModule } from 'ng5-slider';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgXCableModule } from 'ngx-cable';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { QuillModule } from 'ngx-quill';

const components = [
  HighlightOnScrollDirective,
  SidebarOnScrollDirective,

  ReferencesNavbarComponent,
  ReferencesTopbarComponent,

  ModalsExportComponent,
  ModalsImportComponent,
  ModalsQuickRequestComponent,
  ModalsReferenceNotificationComponent,
  ModalsReferencesComponent,
  ModalsRemindersComponent,
  ModalsTrialsComponent,

  SidebarCandidateComponent,
  SidebarCandidateAboutComponent,
  SidebarCandidateActivitiesComponent,
  SidebarCandidateEmailsComponent,
  SidebarCandidateReferenceRequestsComponent,
  SidebarCandidateRemindersComponent,
  SidebarCandidateTextsComponent,

  SidebarReferenceComponent,
  SidebarReferenceAboutComponent,
  SidebarReferenceActivitiesComponent,
  SidebarReferenceEmailsComponent,
  SidebarReferenceQuestionSetComponent,
  SidebarReferenceQuestionSetAlertsComponent,
  SidebarReferenceRemindersComponent,
  SidebarReferenceEmailsComponent,
  SidebarReferenceTextsComponent,

  SidebarFeedbackComponent,
  SidebarFeedbackQuestionSetComponent
];

const modules = [
  BsDatepickerModule,
  CarouselModule,
  GooglePlaceModule,
  NgXCableModule,
  NgxFileDropModule,
  Ng5SliderModule,
  QuillModule
];

@NgModule({
  imports: [SharedModule, modules],
  exports: [SharedModule, components],
  declarations: [components]
})
export class ReferencesSharedModule {}
