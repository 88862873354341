import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { ReferenceContactEnum } from '@shared/enums/reference.enum';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { NavbarService } from '@shared/services/navbar.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { Subject, Subscription } from 'rxjs';

interface LinkItem {
  label: string;
  icon: string;
  url?: string;
  href?: string;
  step?: number;
  disabled: boolean;
  order: number;
  links?: LinkItem[];
  queryParams?: { contact_types: string };
  isCollapsed?: boolean;
}

interface Link {
  id: number;
  label: string;
  isAvailable: boolean;
  items: LinkItem[];
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class ReferencesNavbarComponent implements OnInit, OnDestroy {
  @LocalStorage() public isSubAccount: boolean;
  @LocalStorage() public canManageSubAccount: boolean;
  @SessionStorage() public user: IUser;
  @SessionStorage() public settings: ISetting;
  @SessionStorage() private hiringFirm: IHiringFirm;

  public links: Link[] = [];
  public isProduction = Boolean(environment.IS_PRODUCTION);

  public isCollapsed = Boolean(true);
  public openQuickRequestModal: Subject<any> = new Subject();
  public openQuickCandidateModal: Subject<any> = new Subject();
  public params: any = {
    environment: environment.ENVIRONMENT
  };

  public isRoot = Boolean(false);
  public isRecruiter = Boolean(false);
  public configurationUrl = this._commonEnvironments.APPS.find(
    (a: any) => a.label === 'configuration'
  ).url;

  public companies: IHiringFirm[] = [];
  public companySelectedId: string;

  private readonly constructorName: string = String(this.constructor.name);

  private settingsSubscription: Subscription;
  private reloadDataSetSubscription: Subscription;

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _navbar: NavbarService,
    private readonly _sidebar: SidebarService
  ) {
    this.settingsSubscription = this._navbar
      .getSettings()
      .subscribe((res: { label: string; value: string }) => {
        const label = res.label;

        switch (label) {
          // ATS
          case 'is_plugins_ats_enabled':
            if (res.value) {
              const isATSEnabled = this.links[0].items.find(
                (l: any) => l.label === 'NAVBAR.LINKS.APPLICATIONS'
              );

              if (!isATSEnabled) {
                this.setATSLink();
              }
            } else {
              this.links[0].items = this.links[0].items.filter((l: any) => {
                if (this.settings.is_plugins_job_board_enabled) {
                  return (
                    l.label !== 'NAVBAR.LINKS.APPLICATIONS' &&
                    l.label !== 'NAVBAR.LINKS.EMPLOYERS'
                  );
                } else {
                  return (
                    l.label !== 'NAVBAR.LINKS.APPLICATIONS' &&
                    l.label !== 'NAVBAR.LINKS.EMPLOYERS' &&
                    l.label !== 'NAVBAR.LINKS.JOB_BOARD'
                  );
                }
              });
            }
            break;

          // Candidate Feedback
          case 'is_plugins_candidate_feedback_enabled':
          case 'is_plugins_recruiter_feedback_enabled':
            if (res.value) {
              const isCandidateFeedbackEnabled = this.links[0].items.find(
                (l: any) => l.label === 'NAVBAR.LINKS.FEEDBACKS'
              );

              if (!isCandidateFeedbackEnabled) {
                this.setCandidateFeedbackLink();
              }
            } else if (
              !this.settings.is_plugins_candidate_feedback_enabled &&
              !this.settings.is_plugins_recruiter_feedback_enabled
            ) {
              this.links[0].items = this.links[0].items.filter(
                (l: any) => l.label !== 'NAVBAR.LINKS.FEEDBACKS'
              );
            }
            break;

          // Pre screening
          case 'is_plugins_pre_screening_enabled':
            if (res.value) {
              const isPreScreeningEnabled = this.links[0].items.find(
                (l: any) => l.label === 'NAVBAR.LINKS.PRE_SCREENING'
              );

              if (!isPreScreeningEnabled) {
                this.setPreScreeningLink();
              }
            } else if (!this.settings.is_plugins_pre_screening_enabled) {
              this.links[0].items = this.links[0].items.filter(
                (l: any) => l.label !== 'NAVBAR.LINKS.PRE_SCREENING'
              );
            }
            break;

          case 'is_demo_enabled':
            this.settings.is_demo_enabled = false;
            this.links.map((l: any) => {
              l.items = l.items.map((i: any) => ({
                ...i,
                url: i.url.replace(/billing/g, ''),
                disabled: this.settings.is_demo_enabled
              }));
            });

            this.hiringFirm = {
              ...this.hiringFirm,
              settings: this.settings
            };

            break;
        }

        this.orderLinks();
      });
  }

  ngOnInit(): void {
    const url = 'Reload sub account dataset';
    this.reloadDataSetSubscription =
      this._sidebar.reloadDatasetCalled$.subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, url, res);

          this.setLinks();
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );

    this.isRoot = this.user.isRootRecruiter;
    this.isRecruiter = this.user.isRecruiter;

    this.setLinks();
  }

  ngOnDestroy(): void {
    this.settingsSubscription?.unsubscribe();
    this.reloadDataSetSubscription?.unsubscribe();
  }

  public openModal() {
    this.openQuickRequestModal.next();
  }

  public openCandidateModal() {
    this.openQuickCandidateModal.next();
  }

  public trackByFn(i: any) {
    return i.order;
  }

  private setLinks() {
    this.links[0] = {
      id: 0,
      label: 'Menu',
      isAvailable: true,
      items: [
        {
          label: 'NAVBAR.LINKS.DASHBOARD',
          url: '/dashboard',
          icon: 'lnr-chart-bars',
          step: 2,
          disabled: false,
          order: 0
        },
        {
          label: 'NAVBAR.LINKS.QUESTION_SETS',
          url: '/question-sets',
          icon: 'lnr-bubble-text',
          step: 6,
          disabled: this.settings.is_demo_enabled,
          order: 8
        }
      ]
    };

    if (
      this.settings.is_plugins_candidate_feedback_enabled ||
      this.settings.is_plugins_recruiter_feedback_enabled
    ) {
      this.setCandidateFeedbackLink();
    }

    if (this.settings.is_plugins_pre_screening_enabled) {
      this.setPreScreeningLink();
    }

    if (this.settings.is_plugins_ats_enabled) {
      this.setATSLink();
    }

    if (this.isRecruiter) {
      this.links[0].items.push(
        {
          label: 'NAVBAR.LINKS.CANDIDATES',
          url: '/candidates',
          icon: 'lnr-clipboard-user',
          step: 3,
          disabled: false,
          order: 1
        },
        {
          label: 'NAVBAR.LINKS.LEADS',
          url: '/leads',
          icon: 'lnr-contacts',
          step: 4,
          disabled: false,
          order: 6
        }
      );
      
      if (this.settings.thank_you_cta && this.settings.lead_generation_type === 'hiring') {
        this.links[0].items.push(
          {
            label: 'Leads',
            icon: 'lnr-magnifier',
            step: 5,
            disabled: false,
            order: 7,
            isCollapsed: false,
            links: [
              {
                label: 'Clients',
                url: '/leads',
                queryParams: { contact_types: ReferenceContactEnum.HIRING },
                icon: 'lnr-contacts',
                disabled: false,
                order: 0
              },
              {
                label: 'Candidates',
                url: '/leads',
                queryParams: { contact_types: ReferenceContactEnum.CANDIDATE },
                icon: 'lnr-contacts',
                disabled: false,
                order: 1
              }
            ]
          }
        )
      }
    }

    if (this.isRoot || this.user.isSupervisor) {
      this.links[0].items.push(
        {
          label: 'NAVBAR.LINKS.ACTIVITIES',
          url: '/activities',
          icon: 'lnr-news',
          step: 7,
          disabled: false,
          order: 9
        }
      );
    }

    if (this.isRoot) {
      this.links[0].items.push(
        {
          label: 'NAVBAR.LINKS.CONFIGURATION',
          href: this.configurationUrl,
          icon: 'lnr-cog',
          step: 8,
          disabled: false,
          order: 10
        }
      );
    }

    this.orderLinks();
  }

  private setCandidateFeedbackLink() {
    this.links[0].items.push({
      label: 'NAVBAR.LINKS.FEEDBACKS',
      url: '/feedbacks',
      icon: 'lnr-thumbs-up',
      disabled: false,
      order: 4
    });
  }

  private setPreScreeningLink() {
    this.links[0].items.push({
      label: 'NAVBAR.LINKS.PRE_SCREENING',
      url: '/pre-screenings',
      icon: 'lnr-folder-user',
      disabled: false,
      order: 5
    });
  }

  private setATSLink() {
    this.links[0].items.push({
      label: 'NAVBAR.LINKS.EMPLOYERS',
      url: '/employers',
      icon: 'lnr-folder-user',
      disabled: false,
      order: 3
    });
  }

  private orderLinks() {
    this.links[0].items.sort((l, ll) => l.order - ll.order);
  }

  public toggleCollapse(l: any) {
    l.isCollapsed = !l.isCollapsed;
  }
}
