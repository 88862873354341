export const languages: {
  label: string;
  id: string;
  isSelected: boolean;
}[] = [
  {
    label: 'English',
    id: 'en',
    isSelected: false
  },
  {
    label: 'Español',
    id: 'es',
    isSelected: false
  },
  {
    label: 'Français',
    id: 'fr',
    isSelected: false
  }
];
