import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmployer } from '@shared/interfaces/employer.interface';
import { IFeedback } from '@shared/interfaces/feedback.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { DocumentsService } from '@shared/services/documents.service';
import { FeedbacksService } from '@shared/services/feedbacks.service';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { ToastService } from '@shared/services/toast.service';
import FileSaver from 'file-saver';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar-feedback-question-set',
  templateUrl: './sidebar.feedback.question-set.component.html'
})
export class SidebarFeedbackQuestionSetComponent implements OnInit, OnDestroy {
  @Input() public feedback: IFeedback;

  public recipient: ICandidate | IEmployer | IRecruiter;

  public isLoading = Boolean(true);
  public isDownloading = Boolean(false);
  public includeReportContact = Boolean(false);
  public isButtonsDisabled = Boolean(false);

  public title = String('FEEDBACKS.SHOW.TITLE');
  public answers: any[] = [];

  public openFeedbackModal: Subject<any> = new Subject();
  public openConfirmModal: Subject<boolean> = new Subject();
  public closeModal: Subject<any> = new Subject();

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _documents: DocumentsService,
    private readonly _logger: LoggerService,
    private readonly _feedbacks: FeedbacksService,
    private readonly _toast: ToastService,
    private readonly _sidebar: SidebarService
  ) {
    this.afterSubmit = this.afterSubmit.bind(this);
    this.delete = this.delete.bind(this);
  }

  ngOnInit(): void {
    this.getFeedback();
  }

  ngOnDestroy(): void {}

  public download(endpoint: string = 'pdf') {
    this.isDownloading = true;

    const params: any = {
      id: this.feedback.id,
      type: 'feedbacks',
      include_contact: this.includeReportContact,
      endpoint
    };

    const url = `GET /documents/${params.endpoint}?type=${params.type}`;
    this._documents.get(params).subscribe(
      (res: Blob) => {
        this._logger.info(this.constructorName, url, res);

        const title = this.feedback.question_set.title;
        const id = this.feedback.id.substring(0, 8);
        FileSaver.saveAs(
          res,
          `${id}_${title}_feedback_report.${params.endpoint}`
        );

        this.isDownloading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.isDownloading = false;
      }
    );
  }

  public getFeedback(isRefresh?: boolean) {
    const id = this.feedback?.id;
    const url = `GET /feedbacks/${id}`;
    this._feedbacks.find(id).subscribe(
      (res: IFeedback) => {
        this._logger.info(this.constructorName, url, res);

        this.feedback = res;
        this.answers = this.feedback?.answer_set?.answers || [];
        this.recipient = this.feedback.recipient || this.feedback.identifiable;

        if (isRefresh) {
          this._toast.success('Feedback refreshed');
        }

        this.isLoading = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this.isLoading = false;
      }
    );
  }

  public afterSubmit() {
    this.getFeedback();
  }

  public delete() {
    this.isButtonsDisabled = true;

    const id = this.feedback.id;
    const url = `DELETE /feedbacks/${id}`;
    this._feedbacks.delete(id).subscribe(
      (res: IFeedback) => {
        this._logger.info(this.constructorName, url, res);

        this._toast.success('Feedback deleted');
        this.isButtonsDisabled = false;

        this.closeModal.next();
        this._sidebar.closeSidebar();
        // TO DO
        // TO IMPLEMENT
        // this._feedbacks.refresh();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        this.isButtonsDisabled = false;
      }
    );
  }
}
