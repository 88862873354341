import { phoneFormat } from '@shared/builders/phone-formart.helper';
import {
  APIEmail,
  APIText,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IProfile } from '@shared/interfaces/profile.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Profile } from '@shared/models/profile.model';
import { PhoneNumber } from 'libphonenumber-js';

export class User implements IUser {
  public static readonly TYPE: string = String('User');

  id: APIuuid;
  email: APIEmail;
  text: APIText;
  first_name: string;
  last_name: string;
  phone: PhoneNumber;
  country_code: string;
  is_active: boolean;
  roles: string[];

  isRecruiter: boolean;
  isRootRecruiter: boolean;
  isSupervisor: boolean;
  isCandidate: boolean;
  isSubAccount: boolean;
  canManageSubAccount: boolean;
  is_admin?: boolean = Boolean(false);
  is_mfa_enabled: boolean;
  is_2fa_enabled: boolean;
  is_mfa_by_email: boolean;
  profile: IProfile;
  userType: string;
  password: string;
  picture_url: string;
  last_connected_at: string;
  qr_code: string;

  client_id?: string;
  token?: string;

  failed_attempts: number;
  is_locked: boolean;

  constructor(partial: Partial<User>) {
    Object.assign(this, partial);
  }

  public get full_name(): string {
    return this.first_name || this.last_name
      ? `${this.first_name} ${this.last_name}`
      : '';
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: User.TYPE,
        id: this.id,
        attributes: {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          phone: this.phone,
          country_code: this.country_code,
          picture_url: this.picture_url,
          last_connected_at: this.last_connected_at,
          failed_attempts: this.failed_attempts,
          is_locked: this.is_locked
        }
      }
    } as IAPIData;
  }

  public static fromAPI(res: IAPIData): IUser {
    const data = res.data;

    if (!data) {
      return;
    }

    if (!(data?.type === User.TYPE)) {
      throw new Error(`There was a problem parsing ${User.TYPE} API data`);
    }

    const user = new User({
      ...data.attributes,
      id: data.id
    });

    const token = res.token;
    if (!!token) {
      user.token = token;
    }

    user.isRecruiter = user.roles.includes('recruiter');
    user.isRootRecruiter = user.roles.includes('root_recruiter');
    user.isSupervisor = user.roles.includes('supervisor');
    user.isCandidate = user.roles.includes('candidate') || !!user?.profile?.id;
    user.is_admin = user.roles.includes('admin');

    if (user.isCandidate) {
      const included = res.included;
      const profile =
        included && included.find((i: IAPIDataObject) => i.type === 'Profile');

      user.profile = Profile.fromAPI(
        {
          data: profile
        },
        user.id
      );
    }

    // TO DO ADD ADMIN CHECK
    return user;
  }

  static fromAPIArray(res: IAPIArrayData): IUser[] {
    return res.data.map(
      (data: IAPIDataObject) =>
        new User({
          id: data.id,
          ...data.attributes
        })
    );
  }
}
