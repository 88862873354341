import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IReferenceRelationship } from '@shared/interfaces/reference-relationship.interface';

export class ReferenceRelationship implements IReferenceRelationship {
  public static readonly TYPE: string = String('ReferenceRelationship');

  public id: APIuuid;

  public hiring_firm: Partial<IHiringFirm>;

  public label: string;
  public language: string;
  public value: string;

  public is_active: boolean;

  constructor(partial: Partial<IReferenceRelationship>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    return {
      data: {
        id: this.id,
        type: ReferenceRelationship.TYPE,
        attributes: {
          label: this.label,
          value: this.value,
          language: this.language
        },
        relationships: {
          hiring_firm: {
            id: this.hiring_firm?.id
          } as IAPIRelationship
        }
      }
    } as IAPIData;
  }

  public static fromAPI(answerSetInfo: IAPIData): IReferenceRelationship {
    const data = answerSetInfo.data;
    if (!(data && data.type === ReferenceRelationship.TYPE)) {
      throw new Error(
        `There was a problem parsing ${ReferenceRelationship.TYPE} API Data`
      );
    }

    const rr = new ReferenceRelationship({
      id: data.id,
      ...data.attributes
    });

    return rr;
  }

  static fromAPIArray(res: IAPIArrayData): IReferenceRelationship[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === ReferenceRelationship.TYPE)) {
        throw new Error(
          `There was a problem parsing ${ReferenceRelationship.TYPE} API data`
        );
      }

      const rr = new ReferenceRelationship({
        id: data.id,
        ...data.attributes
      });

      return rr;
    });
  }
}
