import { IAPIRelationship } from '@shared/interfaces/api.interface';
import { IApplication } from '@shared/interfaces/application.interface';
import { Job } from '@shared/models/job.model';

export const buildApplication = (
  application: IApplication,
  relationships: any,
  included: any
): IApplication => {
  const job =
    included &&
    included.find(
      (i: IAPIRelationship) =>
        i.id === (relationships.job as IAPIRelationship).id
    );

  if (job) {
    application.job = Job.fromAPI({
      data: job,
      included
    });
  }

  return application;
};
