import {
  APIDatetime,
  APIuuid,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { ITextActivity } from '@shared/interfaces/text-activity.interface';

export class TextActivity implements ITextActivity {
  public static readonly TYPE: string = String('TextActivity');

  id: APIuuid;
  resource_sid: string;
  level: string;
  status: string;
  error_code: string;
  error_message: string;
  phone_number: string;
  body: string;
  from: string;
  created_at: APIDatetime;
  updated_at: APIDatetime;

  constructor(partial: Partial<ITextActivity>) {
    Object.assign(this, partial);
  }

  get label(): any {
    let className = '';

    switch (this.level) {
      case 'ERROR':
        className = 'lnr-warning v-text-danger';
        break;

      case 'WARNING':
        className = 'lnr-warning v-text-warning';
        break;

      default:
        break;
    }

    return {
      label: this.status,
      class: className
    };
  }

  public static fromAPIArray(res: any): ITextActivity[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === TextActivity.TYPE)) {
        throw new Error(
          `There was a problem parsing ${TextActivity.TYPE} API data`
        );
      }

      const textActivity = new TextActivity({
        id: data.id,
        ...data.attributes
      });

      return textActivity;
    });
  }
}
